@charset "UTF-8";

@use "setting";

/*align---------------------------------*/

.alL {
  text-align: left !important;
}

.alR {
  text-align: right !important;
}

.alC {
  text-align: center !important;
}

/*margin---------------------------------*/

.mb0 {
  margin-bottom: 0 !important;

  @include setting.mq {
    margin-bottom: 0 !important;
  }
}

.mb10 {
  margin-bottom: 10px !important;

  @include setting.mq {
    margin-bottom: 5px !important;
  }
}

.mb20 {
  margin-bottom: 20px !important;

  @include setting.mq {
    margin-bottom: 10px !important;
  }
}

.mb30 {
  margin-bottom: 30px !important;

  @include setting.mq {
    margin-bottom: 15px !important;
  }
}

.mb40 {
  margin-bottom: 40px !important;

  @include setting.mq {
    margin-bottom: 20px !important;
  }
}

.mb50 {
  margin-bottom: 50px !important;

  @include setting.mq {
    margin-bottom: 25px !important;
  }
}

.mb60 {
  margin-bottom: 60px !important;

  @include setting.mq {
    margin-bottom: 30px !important;
  }
}

.mb80 {
  margin-bottom: 80px !important;

  @include setting.mq {
    margin-bottom: 40px !important;
  }
}

.mb100 {
  margin-bottom: 100px !important;

  @include setting.mq {
    margin-bottom: 50px !important;
  }
}

.mt0 {
  margin-top: 0 !important;

  @include setting.mq {
    margin-top: 0 !important;
  }
}

.mt10 {
  margin-top: 10px !important;

  @include setting.mq {
    margin-top: 5px !important;
  }
}

.mt20 {
  margin-top: 20px !important;

  @include setting.mq {
    margin-top: 10px !important;
  }
}

.mt30 {
  margin-top: 30px !important;

  @include setting.mq {
    margin-top: 15px !important;
  }
}

.mt40 {
  margin-top: 40px !important;

  @include setting.mq {
    margin-top: 20px !important;
  }
}

.mt50 {
  margin-top: 50px !important;

  @include setting.mq {
    margin-top: 25px !important;
  }
}

.mt60 {
  margin-top: 60px !important;

  @include setting.mq {
    margin-top: 30px !important;
  }
}

.mt80 {
  margin-top: 80px !important;

  @include setting.mq {
    margin-top: 40px !important;
  }
}

.mt100 {
  margin-top: 100px !important;

  @include setting.mq {
    margin-top: 50px !important;
  }
}

/*padding---------------------------------*/

.pb0 {
  padding-bottom: 0 !important;

  @include setting.mq {
    padding-bottom: 0 !important;
  }
}

.pb10 {
  padding-bottom: 10px !important;

  @include setting.mq {
    padding-bottom: 5px !important;
  }
}

.pb20 {
  padding-bottom: 20px !important;

  @include setting.mq {
    padding-bottom: 10px !important;
  }
}

.pb30 {
  padding-bottom: 30px !important;

  @include setting.mq {
    padding-bottom: 15px !important;
  }
}

.pb40 {
  padding-bottom: 40px !important;

  @include setting.mq {
    padding-bottom: 20px !important;
  }
}

.pb50 {
  padding-bottom: 40px !important;

  @include setting.mq {
    padding-bottom: 25px !important;
  }
}

.pb60 {
  padding-bottom: 60px !important;

  @include setting.mq {
    padding-bottom: 30px !important;
  }
}

.pb80 {
  padding-bottom: 80px !important;

  @include setting.mq {
    padding-bottom: 40px !important;
  }
}

.pb100 {
  padding-bottom: 100px !important;

  @include setting.mq {
    padding-bottom: 50px !important;
  }
}

.pb120 {
  padding-bottom: 120px !important;

  @include setting.mq {
    padding-bottom: 60px !important;
  }
}

.pt0 {
  padding-top: 0 !important;

  @include setting.mq {
    padding-top: 0 !important;
  }
}

.pt10 {
  padding-top: 10px !important;

  @include setting.mq {
    padding-top: 5px !important;
  }
}

.pt20 {
  padding-top: 20px !important;

  @include setting.mq {
    padding-top: 10px !important;
  }
}

.pt30 {
  padding-top: 30px !important;

  @include setting.mq {
    padding-top: 15px !important;
  }
}

.pt40 {
  padding-top: 40px !important;

  @include setting.mq {
    padding-top: 20px !important;
  }
}

.pt50 {
  padding-top: 50px !important;

  @include setting.mq {
    padding-top: 25px !important;
  }
}

.pt60 {
  padding-top: 60px !important;

  @include setting.mq {
    padding-top: 30px !important;
  }
}

.pt80 {
  padding-top: 80px !important;

  @include setting.mq {
    padding-top: 40px !important;
  }
}

.pt100 {
  padding-top: 100px !important;

  @include setting.mq {
    padding-top: 50px !important;
  }
}

.pt120 {
  padding-top: 120px !important;

  @include setting.mq {
    padding-top: 60px !important;
  }
}

.pl20 {
  padding-left: 20px !important;
}
.pl30 {
  padding-left: 30px !important;
}
.pl40 {
  padding-left: 40px !important;
}
.pl50 {
  padding-left: 50px !important;
}
.pl60 {
  padding-left: 60px !important;
}

/*only---------------------------------*/
.pc_only {
  display: block !important;

  @include setting.mq {
    display: none !important;
  }
}

.sp_only {
  display: none !important;

  @include setting.mq {
    display: block !important;
  }
}

/*color--------------------------------------*/

$base_color: #212121;
$ac_color: #00a5e3;
$bg_blue: #008ce3;
$bg_color: #fafafa;
$btn_color: #004898;
$blue: #02336a;
$li-blue: #ecf6f8;
$li-blue02: #ecf4f7;
$li-blue03: #c0e6f4;
$li-gray: #e1e5e6;
$gray: #909090;
$gray02: #ededed;
$gray03: #e6e6e6;
$gray04: #c9c9c9;
$gray05: #f4f4f4;
$gray06: #707070;
$red: #ef4949;
$form_red: #f21717;
$dk_red: #c13636;
$yellow: #ffe867;

/*weight--------------------------------------*/

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.fw-900 {
  font-weight: 900;
}

/*size--------------------------------------*/
.fs-12 {
  font-size: 12px;

  @include setting.mq {
    font-size: 10px;
  }
}

.fs-13 {
  font-size: 13px;

  @include setting.mq {
    font-size: 11px;
  }
}

.fs-15 {
  font-size: 15px;

  @include setting.mq {
    font-size: 13px;
  }
}

.fs-16 {
  font-size: 16px;

  @include setting.mq {
    font-size: 14px;
  }
}

.fs-18 {
  font-size: 18px;

  @include setting.mq {
    font-size: 16px;
  }
}

.fs-20 {
  font-size: 20px;

  @include setting.mq {
    font-size: 18px;
  }
}

.fs-22 {
  font-size: 22px;

  @include setting.mq {
    font-size: 18px;
  }
}

.fs-24 {
  font-size: 24px;

  @include setting.mq {
    font-size: 20px;
  }
}

.fs-30 {
  font-size: 30px;

  @include setting.mq {
    font-size: 24px;
  }
}

.fs-32 {
  font-size: 32px;

  @include setting.mq {
    font-size: 24px;
  }
}

.fs-35 {
  font-size: 35px;

  @include setting.mq {
    font-size: 26px;
  }
}

/*style*/
.flex_box {
  @include setting.flexbox;
  @include setting.flex-flow(row wrap);
  @include setting.flex-justyfy-content(space-between);
}

.inline_box {
  display: inline-block;
}

/*btn--------------------------------------*/
.red_btn {
  display: inline-block;
  font-size: 24px;
  color: #fff;
  background-color: $red;
  border-radius: 75px;
  padding: 20px 40px 20px 85px;
  position: relative;
  box-shadow: 0 3px 6px rgba($color: #212121, $alpha: 0.2);

  @include setting.mq {
    font-size: 18px;
    padding: 20px 24px 20px 52px;
  }

  &::before {
    position: absolute;
    top: 50%;
    left: 40px;
    content: "";
    display: inline-block;
    background: url(../img/common/icon_contact.png) center center no-repeat;
    background-size: cover;
    width: 30px;
    height: 30px;
    margin-right: 15px;
    transform: translateY(-50%);

    @include setting.mq {
      top: 48%;
      width: 23px;
      height: 23px;
      margin-right: 5px;
      left: 20px;
    }
  }

  &::after {
    content: "\f061";
    font-family: "font awesome 6 free";
    font-weight: 900;
    padding-left: 1em;
    font-size: 20px;

    @include setting.mq {
      font-size: 15px;
    }
  }

  &:hover {
    background-color: $dk_red;
  }
}

.bk_btn,
.btn-wh,
.bk_btn_s,
.bk_btn_prev {
  display: inline-block;
  font-size: 15px;
  text-align: center;
  color: #fff;
  background-color: $base_color;
  border-radius: 60px;
  padding: 18px 80px 18px 60px;
  position: relative;
  transition: setting.$link_time;

  @include setting.mq(sm) {
    padding: 18px 50px 18px 30px;
  }

  @include setting.mq(sm-s) {
    padding: 18px 30px 18px 40px;
  }

  &::after {
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translateY(-50%);
    content: "\f061";
    font-family: "font awesome 6 free";
    font-weight: 900;
    padding-left: 0.8em;
    font-size: 18px;

    @include setting.mq(sm) {
      right: 20px;
    }
  }

  &:hover {
    background-color: $btn_color;
  }
}

.btn-wh {
  color: $base_color;
  background-color: #fff;
  border: $base_color 1px solid;
  &:hover {
    background-color: $btn_color;
  }
}

.bk_btn_s {
  padding: 15px 60px 15px 45px;

  &::after {
    right: 20px;
  }
}

.bk_btn_prev {
  padding: 18px 60px 18px 80px;

  @include setting.mq(sm) {
    padding: 18px 30px 18px 50px;
  }

  &::after {
    display: none;
  }

  &::before {
    position: absolute;
    left: 40px;
    top: 50%;
    transform: translateY(-50%);
    content: "\f060";
    font-family: "font awesome 6 free";
    font-weight: 900;
    padding-right: 0.8em;
    font-size: 18px;

    @include setting.mq(sm) {
      left: 20px;
    }
  }
}

.bk_btn_out {
  display: inline-block;
  font-size: 15px;
  text-align: center;
  color: #fff;
  background-color: $base_color;
  border-radius: 60px;
  padding: 15px 60px 15px 45px;
  position: relative;
  transition: setting.$link_time;

  @include setting.mq(sm) {
    padding: 18px 50px 18px 30px;
  }

  @include setting.mq(sm-s) {
    padding: 18px 30px 18px 40px;
  }

  &::after {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    content: "\f08e";
    font-family: "font awesome 6 free";
    font-weight: 900;
    padding-left: 0.8em;
    font-size: 18px;

    @include setting.mq(sm) {
      right: 20px;
    }
  }
  &:hover {
    background-color: $btn_color;
  }
}

.bk_btn_send {
  padding: 32.5px 130px;
  font-size: 30px;
  @include setting.mq(sm) {
    padding: 20px 80px;
    font-size: 16px;
  }
  &::before {
    content: "";
    position: absolute;
    left: 40px;
    top: 50%;
    transform: translateY(-50%);
    padding-left: 0.8em;
    width: 30px;
    height: 30px;
    background-image: url(../img/infostaff/icon-send-1.png);
    background-repeat: no-repeat;
    background-size: contain;
    @include setting.mq(sm) {
      left: 35px;
      width: 20px;
      height: 20px;
    }
  }
  &::after {
    content: "\f063";
    font-size: 30px;
    @include setting.mq(sm) {
      font-size: 17px;
    }
  }
}

.wh_btn {
  padding: 16px 58px 16px 78px;
  color: $base_color;
  background: #fff;
  border: solid 2px $base_color;

  @include setting.mq(sm) {
    padding: 16px 28px 16px 48px;
  }

  &:hover {
    border: solid 2px $btn_color;
    background: $btn_color;
    color: #fff;
  }
}

.arrow_btn {
  display: block;
  position: relative;

  &::before {
    position: absolute;
    display: block;
    content: "\f061";
    font-family: "font awesome 6 free";
    font-weight: 700;
    font-size: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: $base_color;
    color: #fff;
    text-align: center;
    line-height: 50px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    @include setting.mq {
      width: 40px;
      height: 40px;
      line-height: 40px;
      top: 30px;
      transform: translateY(0);
    }
  }

  &:hover {
    &::before {
      background-color: $btn_color;
    }
  }
}
