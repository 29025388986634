@charset "utf-8";

@use "global" as g;

.info_staff_page {
  .sp-con {
    display: none;
    @include g.mq {
      display: block;
    }
  }
  .pc-con {
    @include g.mq {
      display: none;
    }
  }

  .info_staff_btn {
    text-align: center;
    margin: 40px 0;
  }
  .info_staff_content {
    h2 {
      text-align: center;
      font-size: 48px;
      margin: 55px 0 80px;
      @include g.mq {
        font-size: 32px;
        margin: 50px 0 35px;
      }
    }
    h3 {
      text-align: center;
      font-size: 32px;
      margin-bottom: 40px;
      @include g.mq {
        font-size: 24px;
        margin-bottom: 20px;
      }
    }
    .info_staff_data {
      margin-bottom: 80px;
      .tb {
        width: 720px;
        margin: 0 auto;
        @include g.mq(lg) {
          width: 100%;
        }
        th {
          width: 33%;
          text-align: center;
          font-size: 20px;
          color: #fff;
          background-color: #00a5e3;
          border-right: 1px solid #fff;
          border-left: 1px solid #00a5e3;
          padding: 5px 0;
          @include g.mq {
            font-size: 16px;
          }
          &:nth-child(1) {
            width: 22%;
          }
          &:nth-child(3) {
            width: 33%;
            border-right: 1px solid #00a5e3;
          }
        }
        .age {
          width: 22%;
          color: #212121;
          background-color: #efefef;
          border-bottom: #909090 1px solid;
          border-left: #909090 1px solid;
          border-right: #909090 1px solid;
          padding: 11px 0;
          @include g.mq {
            padding: 6px 0;
          }
        }
        td {
          width: 33%;
          text-align: center;
          font-size: 20px;
          font-weight: bold;
          border-bottom: #909090 1px solid;
          border-right: #909090 1px solid;
          padding: 11px 0;
          @include g.mq {
            font-size: 16px;
            padding: 6px 0;
          }
          &:nth-child(3) {
            width: 33%;
          }
        }
      }
    }
    .info_staff_feature {
      margin-bottom: 80px;
      ul {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        li {
          width: calc(880 / 1200 * 100%);
          border: #004898 3px solid;
          border-radius: 50px 0 50px 0;
          padding: 60px 25px 30px;
          position: relative;
          @include g.mq {
            width: 100%;
          }
          &::before {
            content: "";
            position: absolute;
            top: -32px;
            right: 50%;
            -webkit-transform: translate(50%, 0);
            -moz-transform: translate(50%, 0);
            -ms-transform: translate(50%, 0);
            -o-transform: translate(50%, 0);
            transform: translate(50%, 0);
            background: url(../img/infostaff/icon-check-1.png) no-repeat;
            background-size: contain;
            width: 64px;
            height: 64px;
          }
          h3 {
            text-align: center;
            font-size: 24px;
            font-weight: bold;
            .red-txt {
              color: #f21717;
            }
            .fg {
              font-size: 32px;
            }
          }
          p {
            font-size: 18px;
            .yl-line {
              background: linear-gradient(transparent 70%, #ffe867 30%);
            }
          }
        }
        .half {
          width: calc(480 / 1200 * 100%);
          @include g.mq {
            width: 100%;
            margin-bottom: 54px;
          }
        }
      }
    }
    .info_staff_box {
      background-color: #ecf4f7;
      padding: 80px 0 100px;
      @include g.mq {
        padding: 70px 0 60px;
      }
      .pickup-block {
        .head-txtbox {
          text-align: center;
          margin-bottom: 20px;
          .head-txt {
            display: inline-block;
            padding: 7px 20px;
            font-size: 20px;
            font-weight: bold;
            color: #fff;
            background-color: #008ce3;
            border-radius: 31px;
            position: relative;
            @include g.mq {
              padding: 8.5px 32px;
              font-size: 16px;
            }
            &::before {
              content: "";
              position: absolute;
              bottom: -10px;
              right: 50%;
              transform: translate(50%, 0);
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 10px 6px 0 6px;
              border-color: #008ce3 transparent transparent transparent;
            }
          }
        }
        .ttl {
          font-size: 48px;
          @include g.mq {
            margin-bottom: 20px;
            font-size: 32px;
          }
        }
        .list {
          width: 90%;
          display: flex;
          justify-content: space-between;
          margin-bottom: 80px;
          flex-wrap: wrap;
          @include g.mq {
            width: 100%;
            margin-bottom: 20px;
          }
          .item {
            width: calc(520 / 1080 * 100%);
            background-color: #fff;
            padding: 40px 40px 20px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            @include g.mq(lg) {
              width: 100%;
              margin-bottom: 40px;
            }
            @include g.mq {
              padding: 20px 20px 1px;
              margin-bottom: 20px;
            }
            &:nth-child(2n) {
              background-color: #fff;
            }
            .img {
              width: calc(120 / 440 * 100%);
              @include g.mq {
                width: calc(120 / 295 * 100%);
                margin: 0 auto 10px;
              }
            }
            .txtbox {
              width: calc(280 / 440 * 100%);
              @include g.mq {
                width: 100%;
              }
              .name {
                margin-bottom: 15px;
                font-size: 20px;
                color: #008ce3;
                border-bottom: #008ce3 2px solid;
              }
              dl {
                margin-bottom: 20px;
                width: 100%;
                padding: 0;
                @include g.mq {
                  font-size: 16px;
                }
                dt {
                  color: #00a5e3;
                }
                dd {
                  .red-txt {
                    color: #f21717;
                  }
                }
                &:nth-child(2) {
                  dd {
                    text-indent: -1em;
                    padding-left: 1em;
                  }
                }
              }
            }
          }
        }
      }
      .list {
        width: 78%;
        margin: 0 auto;
        @include g.mq(lg) {
          width: 100%;
        }
        .item {
          display: flex;
          flex-wrap: wrap;
          background-color: #fff;
          position: relative;
          padding: 30px;
          min-height: 129px;
          @include g.mq {
            margin-bottom: 20px;
            padding: 30px 20px 10px;
          }
          &:nth-child(2n) {
            background-color: #f8f5f5;
            @include g.mq {
              background-color: #fff;
            }
          }
          .no {
            position: absolute;
            top: 10px;
            left: 10px;
            font-size: 32px;
            color: #e9e9e9;
            font-weight: bold;
            line-height: 1;
            @include g.mq {
              top: -15px;
              left: 0;
            }
          }
          .personal {
            width: 20%;
            margin: 35px 0 0;
            font-size: 20px;
            font-weight: bold;
            color: #212121;
            @include g.mq {
              width: 100%;
              margin: 0 0 20px;
              font-size: 18px;
            }
          }
          dl {
            width: 40%;
            padding: 0 10px;
            @include g.mq {
              width: 100%;
              margin-bottom: 20px;
              padding: 0;
            }
            dt {
              color: #00a5e3;
              margin-bottom: 7px;
            }
            dd {
              .red-txt {
                color: #f21717;
              }
            }
            .wrap {
              display: flex;
              .head-ttl {
                width: 86px;
                @include g.mq(lg-x) {
                  width: 80px;
                }
                @include g.mq(sm) {
                  width: 30%;
                }
              }
              .head-cont {
                width: calc(100% - 90px);
                @include g.mq(lg-x) {
                  width: calc(100% - 83px);
                }
                @include g.mq(sm) {
                  width: 70%;
                }
              }
            }
          }
        }
      }
    }
    .lead-txt {
      text-align: center;
      font-size: 18px;
      margin-bottom: 40px;
    }
    .tb-wrap {
      overflow: scroll;
    }
    .tb {
      width: calc(1000 / 1200 * 100%);
      margin: 0 auto;
      @include g.mq(lg-x) {
        width: 100%;
      }
      @include g.mq {
        width: 740px;
      }
      tr {
        display: flex;
        th,
        td {
          width: calc(120 / 1000 * 100%);
          font-size: 14px;
          color: #fff;
          font-weight: bold;
          background-color: #004898;
          min-height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-right: #d9d9d9 1px solid;
          text-align: center;
          padding: 20px;
          @include g.mq {
            padding: 10px;
          }
          &:nth-child(3) {
            width: calc(140 / 1000 * 100%);
          }
          &:nth-child(4) {
            width: calc(240 / 1000 * 100%);
          }
          &:nth-child(5) {
            width: calc(380 / 1000 * 100%);
            border-right: none;
          }
        }
        td {
          color: #212121;
          background-color: #fff;
          font-weight: 500;
          &:nth-child(4) {
            text-align: left;
          }
          &:nth-child(5) {
            text-align: left;
            display: block;
          }
          .bl-txt {
            color: #00a5e3;
          }
          .red-txt {
            color: #f21717;
          }
        }
        &:nth-child(2n + 1) {
          td {
            background-color: #f8f5f5;
          }
        }
      }
      &-sm {
        width: calc(940 / 1200 * 100%);
        margin: 0 auto;
        @include g.mq(lg-x) {
          width: 100%;
        }
        @include g.mq {
          width: 740px;
        }
        tr {
          th,
          td {
            width: calc(200 / 940 * 100%);
            &:nth-child(3) {
              width: calc(200 / 940 * 100%);
            }
            &:nth-child(4) {
              width: calc(340 / 940 * 100%);
              text-align: center;
            }
          }
        }
      }
      &-thd {
        width: calc(1080 / 1200 * 100%);
        margin: 0 auto;
        @include g.mq(lg-x) {
          width: 100%;
        }
        // @include g.mq {
        //   width: 740px;
        // }
        tr {
          th,
          td {
            width: calc(200 / 1080 * 100%);
            @include g.mq {
              font-size: 13px;
            }
            &:nth-child(3) {
              width: calc(680 / 1080 * 100%);
            }
          }
          th {
            background-color: #008ce3;
          }
          td {
            &:nth-child(3) {
              text-align: left;
              justify-content: flex-start;
            }
          }
        }
      }
      &-six {
        width: calc(840 / 1200 * 100%);
        margin: 0 auto;
        @include g.mq(lg-x) {
          width: 600px;
        }
        tr {
          th,
          td {
            width: calc(80 / 840 * 100%);
            @include g.mq {
              font-size: 13px;
            }
            &:nth-child(1) {
              width: calc(40 / 840 * 100%);
            }
            &:nth-child(4),
            &:nth-child(5) {
              width: calc(120 / 840 * 100%);
              text-align: center;
              display: flex;
              padding: 20px 3px;
            }
            &:nth-child(6) {
              width: calc(400 / 840 * 100%);
              border-left: #d9d9d9 1px solid;
              border-right: none;
            }
          }
          th {
            background-color: #008ce3;
          }
          td {
            &:nth-child(6) {
              text-align: left;
              justify-content: flex-start;
            }
          }
        }
      }
    }
  }
  .info_staff_content-pt2 {
    h2 {
      text-align: center;
      font-size: 48px;
      margin: 55px 0 80px;
      @include g.mq {
        font-size: 32px;
        margin: 50px 0 35px;
      }
    }
    .mv-img {
      margin-bottom: 40px;
      img {
        width: 100%;
      }
    }
    .page_width-sm {
      width: calc(1080 / 1366 * 100%);
      @include g.mq(lg) {
        width: calc(335 / 375 * 100%);
      }
      @include g.mq(sm) {
        width: 100%;
      }
    }
    .info_staff_box {
      padding: 80px 0;
      background-color: #ecf4f7;
    }
    .head-txtbox {
      text-align: center;
      margin-bottom: 20px;
      .head-txt {
        display: inline-block;
        padding: 7px 20px;
        font-size: 20px;
        font-weight: bold;
        color: #fff;
        background-color: #008ce3;
        border-radius: 31px;
        @include g.mq(sm) {
          padding: 8.5px 32px;
          font-size: 16px;
        }
        position: relative;
        &::before {
          content: "";
          position: absolute;
          bottom: -10px;
          right: 50%;
          -webkit-transform: translate(50%, 0);
          -moz-transform: translate(50%, 0);
          -ms-transform: translate(50%, 0);
          -o-transform: translate(50%, 0);
          transform: translate(50%, 0);
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10px 6px 0 6px;
          border-color: #008ce3 transparent transparent transparent;
        }
      }
    }
    .ttl {
      text-align: center;
      margin-bottom: 60px;
      font-size: 48px;
      font-weight: bold;
      @include g.mq(sm) {
        font-size: 32px;
        margin: 25px 0 20px;
      }
      &-bl {
        color: #008ce3;
        span {
          background: linear-gradient(transparent 70%, #ffe867 70%, #ffe867 90%, transparent 90%);
        }
      }
    }
    .txt {
      text-align: center;
      margin: 80px 0 60px;
      font-size: 18px;
      font-weight: bold;
      @include g.mq(sm) {
        margin: 40px 0 20px;
        font-size: 16px;
      }
      .bl-txt {
        color: #008ce3;
      }
      .sp-con {
        display: none;
        @include g.mq(sm) {
          display: block;
        }
      }
      .lead {
        display: block;
        margin-bottom: 20px;
        text-align: center;
        font-size: 32px;
        font-weight: bold;
        color: #f21717;
        @include g.mq(sm) {
          margin-bottom: 10px;
          font-size: 24px;
        }
      }
      &-nm {
        margin: 0 0 30px;
        @include g.mq(sm) {
          margin: 0 0 20px;
        }
      }
    }
    .txt-sp-left {
      @include g.mq(sm) {
        text-align: left;
      }
      .pc-con {
        @include g.mq(sm) {
          display: none;
        }
      }
    }
    .tb-wrap {
      overflow: scroll;
    }
    .tb {
      width: calc(1080 / 1200 * 100%);
      margin: 0 auto;
      @include g.mq(lg-x) {
        width: 100%;
      }
      @include g.mq {
        width: 740px;
      }
      tr {
        display: flex;
        th,
        td {
          width: calc(80 / 1080 * 100%);
          font-size: 14px;
          color: #fff;
          font-weight: bold;
          background-color: #008ce3;
          min-height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-right: #d9d9d9 1px solid;
          text-align: center;
          padding: 20px;
          @include g.mq {
            padding: 10px;
          }
          &:nth-child(1) {
            width: calc(40 / 1080 * 100%);
          }
          &:nth-child(2) {
            width: calc(160 / 1080 * 100%);
          }
          &:nth-child(5) {
            @include g.mq(sm) {
              width: calc(60 / 550 * 100%);
            }
          }
          &:nth-child(6) {
            width: calc(640 / 1080 * 100%);
          }
        }
        td {
          color: #212121;
          background-color: #fff;
          font-weight: 500;
          &:nth-child(6) {
            text-align: left;
            justify-content: flex-start;
          }
          .bl-txt {
            color: #00a5e3;
          }
          .red-txt {
            color: #f21717;
          }
        }
        &:nth-child(2n + 1) {
          td {
            background-color: #f8f5f5;
          }
        }
      }
    }
    .form_btn {
      text-align: center;
      margin-bottom: 90px;
    }
    .ct-list {
      .ct-item {
        border: #212121 1px solid;
        padding: 50px 40px;
        background-color: #ffffff;
        position: relative;
        @include g.mq(sm) {
          padding: 40px 20px;
        }
        &::before {
          content: "";
          position: absolute;
          top: 30px;
          left: 30px;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: #ffe867;
          border: #212121 1px solid;
        }
        &::after {
          content: "";
          position: absolute;
          top: 30px;
          right: 30px;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: #ffe867;
          border: #212121 1px solid;
        }
        .info {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          &-img {
            width: 25%;
            text-align: center;
            font-size: 16px;
            font-weight: bold;
            @include g.mq(lg) {
              width: 47%;
              margin: 0 auto 30px;
            }
            .info-name {
              margin-top: 15px;
              @include g.mq(sm) {
                font-size: 20px;
              }
            }
          }
          &-txtbox {
            width: 65%;
            @include g.mq(lg) {
              width: calc(255 / 295 * 100%);
              margin: 0 auto;
            }
            .number {
              margin-bottom: 20px;
              padding: 0 10px 10px;
              font-size: 20px;
              color: #008ce3;
              position: relative;
              @include g.mq(sm) {
                font-size: 20px;
                padding: 0 5px 5px;
              }
              &::before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 5px;
                background-color: #008ce3;
                border-radius: 3px;
              }
            }
            .data {
              display: flex;
              flex-wrap: wrap;
              margin-bottom: 50px;
              @include g.mq(sm) {
                margin-bottom: 40px;
              }
              &-item {
                padding-left: 47px;
                font-size: 20px;
                font-weight: bold;
                position: relative;
                margin-right: 130px;
                @include g.mq(lg) {
                  width: 100%;
                  margin-bottom: 16px;
                  margin-right: 0;
                }
                @include g.mq(sm) {
                  font-size: 16px;
                }
                &::before {
                  content: "";
                  position: absolute;
                  top: -10px;
                  left: 0;
                  width: 40px;
                  height: 45px;
                  background: url(../img/infostaff/icon-good-1.png) no-repeat;
                  background-size: contain;
                }
              }
            }
            .achivement {
              position: relative;
              margin-bottom: 70px;
              background-color: #ecf4f7;
              padding: 35px 40px 20px;
              @include g.mq(sm) {
                margin-bottom: 40px;
                padding: 35px 20px 20px;
              }
              &-ttl {
                position: absolute;
                top: -16px;
                left: 40px;
                font-size: 16px;
                font-weight: bold;
                color: #fff;
                background-color: #008ce3;
                border-radius: 22px;
                padding: 4.5px 22px;
                @include g.mq(sm) {
                  font-size: 16px;
                  left: 13px;
                }
              }
              &-list {
                display: flex;
                flex-wrap: wrap;
              }
              &-item {
                width: 27%;
                @include g.mq(lg) {
                  width: 100%;
                  margin-bottom: 10px;
                }
                .txt {
                  text-align: left;
                  padding-left: 18px;
                  font-size: 20px;
                  position: relative;
                  margin-bottom: 0;
                  @include g.mq(sm) {
                    font-size: 16px;
                  }
                  &::before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 0;
                    -webkit-transform: translate(0, -50%);
                    -moz-transform: translate(0, -50%);
                    -ms-transform: translate(0, -50%);
                    -o-transform: translate(0, -50%);
                    transform: translate(0, -50%);
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background-color: #212121;
                  }
                }
                .cou-txt {
                  margin-top: 5px;
                  font-size: 14px;
                  padding-left: 18px;
                }
              }
            }
          }
        }
      }
      .sub-ttlbox {
        text-align: center;
      }
      .sub-ttl {
        display: inline-block;
        margin-bottom: 20px;
        font-size: 24px;
        font-weight: bold;
        position: relative;
        @include g.mq(lg) {
          margin-bottom: 88px;
        }
        @include g.mq(sm) {
          font-size: 18px;
        }
        &::before {
          content: "";
          position: absolute;
          bottom: 5px;
          left: -30px;
          width: 20px;
          height: 20px;
          background: url(../img/infostaff/ico-line-1.png) no-repeat;
          background-size: contain;
          @include g.mq(lg) {
            bottom: 0;
            left: -45px;
          }
        }
        &::after {
          content: "";
          position: absolute;
          bottom: 5;
          right: -30px;
          width: 20px;
          height: 20px;
          background: url(../img/infostaff/ico-line-2.png) no-repeat;
          background-size: contain;
          @include g.mq(lg) {
            bottom: 0;
            right: -45px;
          }
        }
        .line {
          color: #008ce3;
          background: linear-gradient(transparent 60%, #ffe867 60%);
        }
        .sp-con {
          display: none;
          @include g.mq(lg) {
            display: block;
          }
        }
      }
      .info-box {
        padding: 30px;
        background-color: #ecf4f7;
        position: relative;
        @include g.mq(sm) {
          padding: 20px;
        }
        &::before {
          content: "";
          position: absolute;
          top: -80px;
          right: 110px;
          width: 90px;
          height: 80px;
          background: url(../img/infostaff/img-infostaff-nagoya-1.png) no-repeat;
          background-size: contain;
          @include g.mq(lg) {
            right: 50%;
            -webkit-transform: translate(50%, 0);
            -moz-transform: translate(50%, 0);
            -ms-transform: translate(50%, 0);
            -o-transform: translate(50%, 0);
            transform: translate(50%, 0);
          }
        }
        &__ttl {
          text-align: center;
          margin-bottom: 30px;
          font-size: 20px;
          font-weight: bold;
          @include g.mq(sm) {
            margin-bottom: 10px;
            font-size: 16px;
          }
          .bl {
            color: #008ce3;
          }
          .sp-con {
            display: none;
            @include g.mq(lg) {
              display: block;
            }
          }
        }
        &__list {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
        }
        &__item {
          width: calc(280 / 980 * 100%);
          text-align: center;
          margin: 0 20px;
          padding: 29px 10px;
          font-size: 18px;
          font-weight: bold;
          color: #fff;
          background-color: #008ce3;
          @include g.mq(lg) {
            width: 100%;
            margin: 0 0 10px 0;
          }
          @include g.mq(sm) {
            font-size: 16px;
          }
          .yl {
            color: #ffe867;
            display: block;
          }
        }
      }
    }
    .fl {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .ct-item {
        width: calc(520 / 1080 * 100%);
        @include g.mq(lg) {
          width: 100%;
          margin-bottom: 15px;
        }
      }
    }
    .right-txt {
      text-align: right;
      margin-bottom: 40px;
    }
    .voice-box {
      margin-bottom: 100px;
      background-color: #b4e2ff;
      border-radius: 50px 0 50px 0;
      padding: 60px 80px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @include g.mq(lg) {
        margin-bottom: 80px;
        padding: 40px 20px 0;
      }
      .dlist {
        width: calc(660 / 920 * 100%);
        @include g.mq(lg) {
          width: 100%;
        }
        .dttl {
          margin-bottom: 30px;
          font-size: 24px;
          font-weight: bold;
          @include g.mq(lg) {
            margin-bottom: 25px;
            text-align: center;
          }
        }
        .data {
          margin-bottom: 20px;
          padding: 20px 30px;
          font-size: 15px;
          font-weight: bold;
          background-color: #fff;
          border-radius: 42px;
          position: relative;
          @include g.mq(lg) {
            padding: 10px 30px;
            border-radius: 52px;
          }
          &::before {
            content: "";
            position: absolute;
            bottom: -13px;
            right: 53px;
            background-image: url(../img/infostaff/icon-hukidashi-1.png);
            background-repeat: no-repeat;
            background-size: contain;
            width: 13px;
            height: 13px;
          }
        }
      }
      .img {
        width: calc(220 / 920 * 100%);
        @include g.mq(lg) {
          width: calc(160 / 290 * 100%);
          margin: 20px auto 0;
        }
      }
    }
    .desc-box {
      text-align: center;
      font-weight: bold;
      .head-txt {
        font-size: 16px;
      }
      .lead-txt {
        margin-bottom: 20px;
        font-size: 32px;
        color: #008ce3;
        @include g.mq(sm) {
          font-size: 24px;
        }
        .sp-con {
          display: none;
          @include g.mq(lg) {
            display: block;
          }
        }
      }
    }
    .info_staff_rec {
      padding: 80px 0;
      .lead-txt {
        margin-bottom: 60px;
        font-size: 32px;
        text-align: center;
        font-weight: bold;
        @include g.mq(lg) {
          margin-bottom: 40px;
        }
        @include g.mq(sm) {
          font-size: 24px;
        }
        .bl-txt {
          color: #008ce3;
        }
        &-nm {
          margin-bottom: 30px;
          @include g.mq(sm) {
            margin-bottom: 20px;
            font-size: 32px;
          }
        }
      }
      .point-box {
        .list {
          margin-bottom: 80px;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          .item {
            margin: 0 20px;
            width: 200px;
            height: 200px;
            border: #008ce3 3px solid;
            text-align: center;
            padding: 80px 0 0;
            font-size: 18px;
            font-weight: bold;
            border-radius: 50%;
            line-height: 1.2;
            position: relative;
            @include g.mq(lg) {
              margin: 0;
            }
            @include g.mq(sm) {
              width: 160px;
              height: 160px;
              padding: 60px 0 0;
            }
            &::before {
              content: "";
              position: absolute;
              top: 25px;
              right: 50%;
              -webkit-transform: translate(50%, 0);
              -moz-transform: translate(50%, 0);
              -ms-transform: translate(50%, 0);
              -o-transform: translate(50%, 0);
              transform: translate(50%, 0);
              width: 25px;
              height: 18px;
              background: url(../img/infostaff/icon-check-2.png) no-repeat;
              background-size: contain;
            }
            &:nth-child(1) {
              padding: 70px 0 0;
              @include g.mq(lg) {
                padding: 50px 0 0;
                margin: 0 20px;
              }
            }
            &:nth-child(2) {
              @include g.mq(lg) {
                margin-right: calc(100% - 400px);
              }
              @include g.mq(sm) {
                margin-right: calc(100% - 320px);
              }
            }
            .sm {
              display: block;
              font-size: 15px;
            }
            .fg {
              font-size: 32px;
              @include g.mq(sm) {
                font-size: 29px;
              }
            }
            .cou-txt {
              display: block;
              font-size: 10px;
            }
          }
        }
      }
      .info-box {
        &__ttl {
          margin-bottom: 20px;
          text-align: center;
          font-size: 20px;
          font-weight: bold;
          position: relative;
          @include g.mq(lg) {
            margin-bottom: 90px;
          }
          @include g.mq(sm) {
            font-size: 16px;
          }

          &::before {
            @include g.mq(lg) {
              content: "";
              position: absolute;
              bottom: 0;
              left: 25%;
              width: 13px;
              height: 13px;
              background: url(../img/infostaff/ico-line-1.png) no-repeat;
              background-size: contain;
            }
            @include g.mq {
              left: 12%;
            }
          }
          &::after {
            @include g.mq(lg) {
              content: "";
              position: absolute;
              bottom: 0;
              right: 25%;
              width: 13px;
              height: 13px;
              background: url(../img/infostaff/ico-line-2.png) no-repeat;
              background-size: contain;
              @include g.mq {
                right: 12%;
              }
            }
          }
          .bl {
            color: #008ce3;
            background: linear-gradient(transparent 60%, #ffe867 60%);
          }
          .sp-con {
            display: none;
            @include g.mq(lg) {
              display: block;
            }
          }
          .icon {
            display: inline-block;
            width: 13px;
            margin: 0 15px;
            @include g.mq(lg) {
              display: none;
            }
          }
        }
        &__list {
          padding: 60px 0;
          background-color: #ecf4f7;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          position: relative;
          @include g.mq(lg) {
            padding: 30px 20px;
            margin: 0 20px;
          }
          &::before {
            content: "";
            position: absolute;
            top: -80px;
            right: 22%;
            width: 90px;
            height: 80px;
            background: url(../img/infostaff/img-infostaff-nagoya-1.png) no-repeat;
            background-size: contain;
            @include g.mq(lg) {
              right: 50%;
              -webkit-transform: translate(50%, 0);
              -moz-transform: translate(50%, 0);
              -ms-transform: translate(50%, 0);
              -o-transform: translate(50%, 0);
              transform: translate(50%, 0);
            }
          }
        }
        &__item {
          width: calc(280 / 980 * 100%);
          text-align: center;
          margin: 0 20px;
          padding: 29px 10px;
          font-size: 18px;
          font-weight: bold;
          color: #fff;
          background-color: #008ce3;
          border-radius: 25px 0 25px 0;
          @include g.mq(lg) {
            width: 100%;
            margin: 0 0 10px 0;
          }
          @include g.mq(sm) {
            font-size: 16px;
          }
          .yl {
            color: #ffe867;
            display: block;
          }
        }
      }
    }
    // 静岡　詳細ページ
    .pt-box {
      &-ttl {
        margin-bottom: 40px;
        text-align: center;
        font-size: 32px;
        font-weight: bold;
        color: #008ce3;
        @include g.mq(sm) {
          margin-bottom: 35px;
          font-size: 24px;
        }
        .bg {
          font-size: 64px;
          font-family: "Montserrat";
          @include g.mq(sm) {
            font-size: 48px;
          }
        }
      }
      .pt-list {
        width: calc(880 / 1200 * 100%);
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @include g.mq(lg) {
          width: 100%;
        }
        @include g.mq(sm) {
          width: calc(240 / 335 * 100%);
        }
        .pt-item {
          width: calc(240 / 880 * 100%);
          margin-bottom: 60px;
          @include g.mq(sm) {
            width: 100%;
            margin-bottom: 40px;
          }
          &-img {
            width: calc(220 / 240 * 100%);
            margin: 0 auto 25px;
            @include g.mq(sm) {
              width: calc(180 / 240 * 100%);
              margin-bottom: 10px;
            }
          }
          &-ttl {
            margin-bottom: 10px;
            text-align: center;
            font-size: 20px;
            font-weight: bold;
            color: #004898;
            min-height: 3em;
            @include g.mq(sm) {
              min-height: auto;
              min-height: initial;
            }
          }
          &-txt {
            @include g.mq(sm) {
              font-size: 16px;
            }
          }
        }
      }
    }
    .if-box {
      margin-bottom: 80px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      &-item {
        width: calc(580 / 1200 * 100%);
        background-color: #b4e2ff;
        border-radius: 50px 0 50px 0;
        padding: 40px;
        @include g.mq(lg) {
          width: 100%;
          margin-bottom: 40px;
        }
        @include g.mq(sm) {
          padding: 40px 20px;
        }
      }
      .vc-box {
        position: relative;
        @include g.mq(sm) {
          padding-bottom: 230px;
        }
        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          right: -23px;
          background-image: url(../img/infostaff/img-voice-1.png);
          background-size: contain;
          width: 120px;
          height: 143px;
          @include g.mq(sm) {
            width: 160px;
            height: 190px;
            right: 50%;
            -webkit-transform: translate(50%, 0);
            -moz-transform: translate(50%, 0);
            -ms-transform: translate(50%, 0);
            -o-transform: translate(50%, 0);
            transform: translate(50%, 0);
          }
        }
        &-item {
          display: inline-block;
          background-color: #fff;
          padding: 20px 30px;
          margin: 20px 20px 0;
          font-size: 15px;
          border-radius: 52px;
          position: relative;
          @include g.mq(sm) {
            margin: 0 0 20px;
            display: block;
            padding: 15px 25px;
          }
          &::before {
            content: "";
            position: absolute;
            bottom: -13px;
            right: 10%;
            background-image: url(../img/infostaff/icon-hukidashi-1.png);
            background-size: contain;
            width: 13px;
            height: 13px;
            @include g.mq(sm) {
              right: 50px;
            }
          }
          .bl-txt {
            color: #008ce3;
          }
        }
      }
      .base-box {
        &-wrap {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .icon {
            width: 120px;
            @include g.mq(sm) {
              margin: 0 auto 20px;
            }
          }
          .list {
            width: calc(100% - 130px);
            @include g.mq(sm) {
              width: 100%;
            }
            .item {
              font-size: 18px;
              padding: 0 0 0 20px;
              margin-bottom: 5px;
              position: relative;
              &::before {
                content: "";
                position: absolute;
                top: 10px;
                left: 0;
                width: 8px;
                height: 8px;
                background-color: #008ce3;
                border-radius: 50%;
              }
            }
          }
        }
      }
      &-ttl {
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 28px;
      }
    }

    // 外国人人材を紹介
    .si-anxiety {
      padding: 100px 0 0;
      @include g.mq {
        padding: 0;
      }
      &__ttl {
        text-align: center;
        font-weight: bold;
        font-size: 48px;
        margin: 0 0 30px;
        @include g.mq {
          margin: 0 0 13px;
          font-size: 32px;
        }
        .headtxt {
          display: block;
          font-size: 24px;
          @include g.mq {
            font-size: 14px;
          }
        }
        .bl {
          color: #00a5e3;
        }
      }
      &__list {
        display: flex;
        justify-content: space-between;
        @include g.mq(xl) {
          flex-wrap: wrap;
        }
      }
      &__item {
        margin-top: 37px;
        text-align: center;
        font-size: 25px;
        font-weight: bold;
        padding: 100px 60px 190px;
        background-image: url(../img/infostaff/icon-hukidashi-2.png);
        background-repeat: no-repeat;
        background-size: contain;

        @include g.mq(xl) {
          width: 448px;
          padding: 130px 0;
          background-image: url(../img/infostaff/sp-icon-hukidashi-2.png);
          // background-size: cover;
        }
        @include g.mq {
          margin-top: 0;
          width: 224px;
          padding: 65px 0;
          font-size: 16px;
        }
        &:nth-child(2) {
          margin-top: 0;
          padding: 100px 70px 130px;
          background-image: url(../img/infostaff/icon-hukidashi-3.png);
          @include g.mq(xl) {
            margin: 180px 0 0 auto;
            padding: 130px 0;
            background-image: url(../img/infostaff/sp-icon-hukidashi-2.png);
          }
          @include g.mq(lg) {
            margin: -120px 0 0 auto;
          }
          @include g.mq {
            margin: -60px 0 0 auto;
            padding: 65px 0;
          }
        }
        &:nth-child(3) {
          margin-top: 66px;
          padding: 90px 70px 180px;
          background-image: url(../img/infostaff/icon-hukidashi-4.png);
          @include g.mq(xl) {
            margin: -120px 0 0 0;
            padding: 130px 0;
            background-image: url(../img/infostaff/sp-icon-hukidashi-2.png);
          }
          @include g.mq {
            margin: -60px 0 0 0;
            padding: 65px 0;
          }
        }
      }
      &__img {
        width: calc(848 / 1200 * 100%);
        margin: -80px auto 0;
        img {
          width: 100%;
        }
        @include g.mq(xl) {
          width: 100%;
          margin: 30px auto 0;
        }
        @include g.mq {
          margin-top: 15px;
        }
      }
      &__box {
        padding: 50px 0 30px;
        text-align: center;
        background-color: #ecf4f7;
        @include g.mq {
          padding: 30px 0;
        }
        &__bltxt {
          display: inline-block;
          margin-bottom: 24px;
          padding: 11px 35px;
          line-height: 1;
          font-size: 30px;
          font-weight: 700;
          color: #fff;
          background-color: #00a5e3;
          border-radius: 35px 0;
          @include g.mq {
            margin-bottom: 10px;
            padding: 7px 18px;
            font-size: 12px;
          }
        }
        &__ttl {
          font-size: 40px;
          font-weight: bold;
          @include g.mq {
            font-size: 22px;
          }
        }
      }
      &__content {
        padding: 90px 0 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        @include g.mq {
          padding: 40px 0 0;
        }
        &__img {
          width: calc(580 / 1200 * 100%);
          @include g.mq(lg) {
            width: 100%;
            margin-bottom: 40px;
          }
        }
        &__txtbox {
          width: calc(590 / 1200 * 100%);
          @include g.mq(lg) {
            width: 100%;
          }
        }
        &__ttl {
          margin-bottom: 20px;
          font-size: 32px;
          font-weight: bold;
          color: #00a5e3;
          @include g.mq {
            margin-bottom: 10px;
            font-size: 24px;
          }
        }
        &__txt {
          font-weight: 400;
          @include g.mq {
            font-size: 16px;
          }
        }
      }
    }

    .video-section {
      .ttl {
        text-align: left;
      }
      .wrap {
        padding-bottom: 100px;
        border-radius: 100px 0 100px 0;
        @include g.mq {
          padding-bottom: 50px;
          border-radius: 50px 0 50px 0;
        }
      }
    }

    .si-solution {
      padding: 90px 0 0;
      @include g.mq {
        padding: 50px 0 0;
      }
      .form_btn {
        @include g.mq {
          margin-bottom: 50px;
        }
      }
      &__box {
        width: calc(1200 / 1440 * 100%);
        margin: 0 auto;
        padding: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        border-radius: 100px 0 100px 0;
        background-color: #ecf4f7;
        @include g.mq {
          width: 100%;
          padding: 40px 20px;
        }
        &__img {
          width: calc(350 / 1008 * 100%);
          @include g.mq(lg) {
            width: calc(280 / 335 * 100%);
            margin: 0 auto 40px;
          }
        }
        &__txtbox {
          width: calc(618 / 1008 * 100%);
          text-align: center;
          font-size: 32px;
          font-weight: bold;
          @include g.mq(lg) {
            width: 100%;
          }
          @include g.mq {
            font-size: 20px;
          }
        }
      }
      &__plb {
        padding: 70px 0;
        &__ttl {
          text-align: center;
          font-size: 32px;
          font-weight: bold;
          @include g.mq(xl) {
            margin-bottom: 30px;
          }
          .bl {
            color: #00a5e3;
          }
        }
        &__wrap {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
        }
        &__list {
          width: calc(487 / 1200 * 100%);
          margin-right: 85px;
          @include g.mq(xl) {
            width: 100%;
            margin-right: 0;
          }
        }
        &__item {
          margin-bottom: 15px;
          display: inline-block;
          padding: 25px 80px;
          background-color: #ededed;
          border-radius: 50px;
          font-size: 24px;
          font-weight: bold;
          position: relative;
          @include g.mq(xl) {
            display: block;
          }
          @include g.mq {
            padding: 22px 0 33px 65px;
            font-size: 16px;
            background-image: url(../img/infostaff/icon-hukidashi-5.png);
            background-repeat: no-repeat;
            background-size: contain;
            background-color: transparent;
          }
          &::before {
            content: "";
            position: absolute;
            left: 35px;
            top: 50%;
            transform: translateY(-50%);
            width: 30px;
            height: 30px;
            background-image: url(../img/infostaff/icon-check-3.png);
            background-repeat: no-repeat;
            background-size: contain;
            @include g.mq {
              top: 43%;
              left: 30px;
              width: 24px;
              height: 24px;
            }
          }
        }
        &__img {
          width: calc(280 / 1200 * 100%);
          @include g.mq(xl) {
            width: calc(280 / 334 * 100%);
          }
        }
      }
      &__cont {
        width: calc(1200 / 1440 * 100%);
        margin: 0 auto 120px;
        padding: 100px 95px 60px;
        border-radius: 100px 0 100px 0;
        background-color: #00a5e3;
        @include g.mq(xl) {
          width: 100%;
          border-radius: 0;
          padding: 80px 0 60px;
        }
        @include g.mq {
          padding: 40px 0;
          margin-bottom: 80px;
        }
        &__ttl {
          margin-bottom: 50px;
          text-align: center;
          font-size: 48px;
          color: #fff;
          @include g.mq {
            margin-bottom: 30px;
            font-size: 24px;
          }
          .headtxt {
            display: block;
            font-size: 32px;
            color: #212121;
            @include g.mq {
              font-size: 16px;
            }
          }
        }
        &__list {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
        }
        &__item {
          margin-bottom: 40px;
          width: calc(480 / 1004 * 100%);
          border-radius: 50px 0 50px 0;
          background-color: #fff;
          @include g.mq {
            margin-bottom: 20px;
            width: 100%;
          }
          .txt {
            margin: 25px 40px 40px;
            @include g.mq {
              font-size: 20px;
              margin: 20px;
            }
          }
          &:nth-child(3) {
            .img {
              margin: 40px 53px 0;
              @include g.mq {
                margin: 20px 20px 0;
              }
            }
          }
          &:nth-child(4) {
            background-color: #00a5e3;
            padding-top: 35px;
            @include g.mq {
              padding-top: 20px;
            }
            .txtbox {
              text-align: center;
              color: #fff;
              .head-txt {
                font-size: 24px;
                @include g.mq {
                  font-size: 20px;
                }
              }
              .text {
                font-size: 34px;
                @include g.mq {
                  font-size: 30px;
                }
              }
            }
          }
        }
      }
      .si-bnr {
        margin-bottom: 125px;
        @include g.mq {
          margin-bottom: 80px;
        }
        a {
          display: block;
          margin-bottom: 40px;
          @include g.mq {
            margin-bottom: 25px;
          }
        }
      }
    }
  }
}
.info_staff_sec {
  margin: 0;
  background-color: #ecf4f7;
  &-wh {
    background-color: #fff;
    padding-top: 100px;
    @include g.mq(sm) {
      padding-top: 60px;
    }
  }
  .page_list {
    margin-top: 0;
  }
  h3 {
    text-align: center;
    font-size: 24px;
    margin-bottom: 40px;
  }
  .list {
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 35px;
    .box {
      width: calc(380 / 1220 * 100%);
      margin: 0;
      @include g.mq {
        width: 100%;
        margin-bottom: 20px;
      }
      .ttl {
        font-size: 18px;
      }
      &:nth-child(2) {
        margin: 0 15px;
        @include g.mq {
          margin: 0;
        }
      }
      .area-tag {
        display: inline-block;
        margin: 10px 0 5px;
        color: #fff;
        background-color: #004898;
        font-size: 12px;
        font-weight: 700;
        padding: 3px 10px;
        border-radius: 10px 0 10px 0;
      }
      h4 {
        font-size: 18px;
      }
    }
  }
  .info_staff_btn {
    text-align: center;
  }
}

.info_staff_pass {
  padding: 0 0 60px;
  .text-center {
    text-align: center;
    a {
      display: inline-block;
      color: #004898;
      text-decoration: underline;
    }
  }
  input[type="password"] {
    border: #909090 1px solid;
    border-radius: 5px;
    background-color: #fff;
    padding: 14px 10px;
    display: block;
    width: 340px;
    margin: 20px auto;
  }
  .btn {
    position: relative;
    width: 280px;
    margin: 20px auto;
    input[type="submit"] {
      display: block;
      width: 100%;
      background-color: #212121;
      color: #fff;
      font-size: 15px;
      font-weight: bold;
      text-align: center;
      padding: 19px 10px;
      box-sizing: border-box;
      border-radius: 30px;
    }
    &::after {
      position: absolute;
      right: 0px;
      top: 18px;
      // left: calc(50% + 70px);
      transform: translateX(-50%);
      content: "\f061";
      font-family: "font awesome 6 free";
      font-weight: 900;
      padding-left: 0.8em;
      font-size: 18px;
      color: #fff;
    }
  }
}
.info_staff_contact {
  background-color: #ecf4f7;
  padding: 80px 0 95px;
  .page_width-ssm {
    width: calc(596 / 1366 * 100%);
    min-width: 596px;
    @include g.mq {
      width: calc(335 / 375 * 100%);
      min-width: initial;
      min-width: auto;
    }
  }
  .fl {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    .box {
      @include g.mq {
        width: 100%;
        margin-bottom: 80px;
      }
      h3 {
        margin-bottom: 80px;
        text-align: center;
        font-size: 32px;
        font-weight: bold;
        position: relative;
        @include g.mq {
          margin-bottom: 40px;
          font-size: 24px;
        }
        &::before {
          content: "";
          position: absolute;
          bottom: -20px;
          right: 50%;
          -webkit-transform: translate(50%, 0);
          -moz-transform: translate(50%, 0);
          -ms-transform: translate(50%, 0);
          -o-transform: translate(50%, 0);
          transform: translate(50%, 0);
          width: 32px;
          height: 4px;
          background-color: #212121;
        }
      }
    }
  }
  input[type="password"] {
    border: #909090 1px solid;
    border-radius: 5px;
    background-color: #fff;
    padding: 14px 10px;
    display: block;
    width: 340px;
    margin: 20px auto;
  }
  .btn {
    position: relative;
    width: 280px;
    margin: 20px auto;
    input[type="submit"] {
      display: block;
      width: 100%;
      background-color: #212121;
      color: #fff;
      font-size: 15px;
      font-weight: bold;
      text-align: center;
      padding: 19px 10px;
      box-sizing: border-box;
      border-radius: 30px;
    }
    &::after {
      position: absolute;
      right: 0px;
      top: 18px;
      transform: translateX(-50%);
      content: "\f061";
      font-family: "font awesome 6 free";
      font-weight: 900;
      padding-left: 0.8em;
      font-size: 18px;
      color: #fff;
    }
  }
  .head-txt {
    text-align: center;
    margin-bottom: 20px;
  }
  .cou_txt_wrap {
    margin: 0 auto 35px;
    text-align: center;
    background-color: #e1e5e6;
    color: #004898;
    font-size: 14px;
    padding: 5px;
    @include g.mq {
      width: 100%;
    }
  }
  .sec_ttl {
    text-align: center;
    .tag {
      font-size: 16px;
    }
    h3 {
      font-size: 27px;
    }
  }
  .form-box {
    background-color: #fff;
    border-radius: 25px;
    padding: 30px 20px;
  }
  .ttl {
    text-align: center;
    margin-bottom: 20px;
    .ico {
      display: inline-block;
      background-color: #00a5e3;
      color: #fff;
      font-size: 13px;
      border-radius: 20px 0 20px 0;
      padding: 0 19px;
    }
    .txt {
      display: block;
      font-size: 24px;
    }
  }
  .wpcf7-form {
    margin: 0 auto;
    @include g.mq {
      width: 100%;
    }
  }
  .form_list {
    > li {
      font-size: 16px;

      &:nth-child(n + 2) {
        margin-top: 10px;
      }
    }
  }
  label {
    font-weight: 700;
    margin-top: 15px;
    display: block;

    &.required {
      position: relative;
      @include g.flexbox;
      @include g.flex-align-items(center);

      &::after {
        margin-left: 5px;
        display: inline-block;
        content: "必須";
        background-color: g.$form_red;
        color: #fff;
        font-size: 10px;
        font-weight: 700;
        padding: 1px 10px;
        border-radius: 3px;
      }
    }
  }
  .form_select {
    position: relative;
    width: 60%;
    @include g.mq {
      width: 100%;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      border-top: 10px solid g.$base_color;
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
      width: 12px;
      height: 10px;
      right: 40px;
      top: 50%;
      transform: translateY(-50%);

      @include g.mq {
        right: 18px;
      }
    }
  }
  input[type="text"],
  input[type="number"],
  input[type="tel"],
  input[type="email"],
  textarea,
  select {
    margin-top: 5px;
    width: 100%;
    border: solid 1px g.$gray;
    background-color: g.$li-blue02;
    border-radius: 5px;
    padding: 15px 10px;
    background-color: #fff;

    &::placeholder {
      color: g.$gray04;
    }
  }
  select {
    position: relative;

    &::after {
      content: "";
      display: block;
      position: absolute;
      border-top: 10px solid g.$base_color;
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
      width: 12px;
      height: 10px;
      right: 40px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .form_checkbox {
    .wpcf7-list-item {
      margin: 0 0 0 1em;
    }
  }
  .wpcf7-list-item {
    margin: 0;
  }
  .note {
    margin-top: 20px;
    font-size: 16px;
  }

  .policy {
    font-weight: 700;

    a {
      color: g.$btn_color;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
  .policy_agree {
    margin-top: 10px;

    label {
      padding-left: 5px;
      font-weight: 500;
    }
  }

  .btn {
    text-align: center;
    margin-top: 40px;
    position: relative;

    &::after {
      position: absolute;
      right: 0px;
      top: 18px;
      // left: calc(50% + 70px);
      transform: translateX(-50%);
      content: "\f061";
      font-family: "font awesome 6 free";
      font-weight: 900;
      padding-left: 0.8em;
      font-size: 18px;
      color: #fff;
    }
  }
  .wpcf7-spinner {
    display: none;
  }

  button {
    &::after {
      display: none;
    }

    picture {
      margin-left: 20px;
    }
  }

  .message {
    margin-top: 40px;
    text-align: center;
    color: g.$ac_color;
    font-size: 18px;
  }
}
