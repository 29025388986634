@charset "utf-8";

@use "global" as g;

/*------------------------共通----------------------*/
html {
  width: 100%;
  background-color: g.$bg_color;
}

body {
  width: 100%;
  font-family: g.$base_font;
  font-weight: 500;
  color: g.$base_color;
  font-size: 16px;
  line-height: 1.5;
  animation: fadeIn 2s ease 0s 1 normal;

  @include g.mq {
    font-size: 14px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.wrapper {
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 700;
}

p {
}

dt {
  font-weight: 500;
}

img {
  max-width: 100%;
  height: auto;

  &.lazyload {
    width: 100%;
  }
}

a {
  transition: g.$link_time;

  &::before,
  &::after {
    transition: g.$link_time;
  }

  img {
    transition: g.$link_time;
  }

  &:hover {
    img {
      opacity: 0.7;
    }
  }

  &.u_line {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -5px;
      width: 0%;
      height: 1px;
      background-color: g.$ac_color;
      transition: g.$link_time;
    }

    &:hover {
      opacity: 1;

      &::after {
        width: 100%;
      }
    }
  }

  &.c_blue {
    &:hover {
      opacity: 1;
      color: g.$ac_color;
    }
  }

  &.bg_zoom {
    overflow: hidden;

    &:hover {
      &::after {
        transform: scale(1.1);
      }
    }
  }

  &.photo_zoom {
    &:hover {
      .photo {
        overflow: hidden;

        source,
        img {
          transform: scale(1.1);
        }
      }
    }
  }

  &.another {
    &::after {
      content: "\f08e";
      font-family: "font awesome 6 free";
      font-weight: 900;
      font-size: 0.9em;
      padding-left: 0.2em;
    }
  }
}

.page_width {
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  padding: 0 50px;

  @include g.mq {
    padding: 0 20px;
  }
}

.cont_width {
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  padding: 0 50px;

  @include g.mq {
    padding: 0 20px;
  }
}

// .detail_width {
//   max-width: 900px;
//   width: 100%;
//   margin: 0 auto;
//   padding: 0 50px;

//   @include g.mq {
//     padding: 0 20px;
//   }
// }

.sec_ttl {
  .tag {
    display: inline-block;
    color: #fff;
    background-color: g.$ac_color;
    font-size: 20px;
    font-weight: 700;
    padding: 2px 30px;
    border-radius: 20px 0px 20px 0px;
    margin-bottom: 20px;
    text-transform: capitalize;

    @include g.mq {
      font-size: 13px;
      padding: 2px 20px;
      margin-bottom: 15px;
    }

    &.faq {
      text-transform: uppercase;
    }
  }

  h2 {
    font-size: 48px;
    font-weight: 700;

    @include g.mq {
      font-size: 32px;
    }

    span {
      color: g.$ac_color;
    }

    &.en_ttl {
      font-size: 64px;
      font-weight: 700;

      @include g.mq {
        font-size: 40px;
      }
    }
  }

  .ja_ttl {
    margin-top: 5px;
    color: g.$ac_color;
    font-weight: 700;
    font-size: 16px;
  }

  &.kaso_ttl {
    text-align-last: left;
    margin-bottom: 80px;

    @include g.mq {
      margin-bottom: 40px;
    }

    h1,
    h2 {
      font-size: 70px;
      font-weight: 700;

      @include g.mq {
        font-size: 32px;
        font-weight: 900;
      }
    }

    .desc {
      margin-top: 20px;
      font-size: 18px;
    }
  }

  &.page_ttl {
    margin-bottom: 120px;

    @include g.mq {
      margin-bottom: 45px;
    }

    h1 {
      font-size: 48px;

      @include g.mq {
        font-size: 24px;
        font-weight: 900;
      }
    }
  }
}

/*------------------------ヘッダー----------------------*/

$h_height: 70px; //ヘッダーの高さ
$h_height_sp: 60px; //ヘッダーの高さスマホ

header {
  height: $h_height;

  @include g.mq {
    height: $h_height_sp;
  }
}

.header_block {
  position: fixed;
  top: 0;
  width: 100%;
  height: $h_height;
  z-index: 100;
  background-color: g.$bg_color;

  @include g.mq {
    height: $h_height_sp;
  }
}

.h_top {
  @include g.flexbox;
  @include g.flex-align-items(center);
  @include g.flex-justyfy-content(space-between);
  width: 100%;
  position: relative;
  z-index: 1000;
  margin: 0 auto;
  transition: g.$link_time;

  &.active {
    box-shadow: 0 3px 6px rgba($color: #909090, $alpha: 0.1);
  }

  .h_logo {
    @include g.flexbox;
    @include g.flex-align-content(center);
    align-items: center;
    margin-right: auto;

    @include g.mq {
      height: 100%;
    }

    .photo {
      margin-left: 30px;

      @include g.mq(lg) {
        // @include g.flexbox;
      }

      @include g.mq {
        margin-left: 20px;
        max-width: 110px;
      }

      a {
        display: block;

        @include g.mq(lg) {
          @include g.flexbox;
          @include g.flex-justyfy-content(center);
        }

        img {
          @include g.mq {
            width: auto;
            height: 100%;
          }
        }
      }
    }
  }

  .h_menu {
    padding: 0 20px;

    @include g.mq(xml) {
      display: none;
    }

    > ul {
      @include g.flexbox;
      @include g.flex-justyfy-content(center);
      @include g.flex-align-items(center);
    }

    li {
      &:not(:last-child) {
        margin-right: 30px;

        @include g.mq(xxl) {
          margin-right: 15px;
        }
      }
    }

    a {
      display: block;
      font-weight: 700;
    }

    .h_btn {
      border: solid 1px g.$base_color;
      border-radius: 34px;
      line-height: 34px;
      padding: 0 15px;
      @include g.flexbox;
      @include g.flex-align-items(center);
      @include g.flex-justyfy-content(center);

      &::before {
        content: "";
        display: block;
        margin-right: 5px;
        width: 16px;
        height: 18px;
        background: url(../img/common/icon_foreigners.png) center center no-repeat;
      }

      &:hover {
        background-color: g.$ac_color;
        color: #fff;
      }
    }
  }

  .h_recruit {
    display: none;
    @include g.mq(xml) {
      display: block;
      margin-right: 20px;
      a {
        @include g.flexbox;
        @include g.flex-justyfy-content(center);
        @include g.flex-align-items(center);
        border: #212121 1px solid;
        border-radius: 18px;
        font-size: 14px;
        font-weight: bold;
        padding: 4px 15px;
        .icon {
          margin-left: 4px;
          picture {
            vertical-align: super;
          }
        }
      }
    }
    @include g.mq(lg) {
      display: none;
    }
  }

  .h_tel {
    a {
      @include g.flexbox;
      @include g.flex-justyfy-content(center);
      @include g.flex-align-items(center);
      background-color: #004898;
      color: #fff;
      padding: 8.5px 25px;
      height: 70px;

      @include g.mq(xml) {
        padding: 8.5px 10px;
      }

      @include g.mq {
        line-height: $h_height_sp;
      }

      @include g.mq(md) {
        display: none;
      }
      .sm {
        display: block;
        font-size: 10px;
      }

      &::before {
        content: "";
        display: block;
        margin-right: 5px;
        width: 20px;
        height: 20px;
        background: url(../img/common/icon-tel-1.png) center center no-repeat;
        background-size: cover;
      }

      &:hover {
        opacity: 0.7;
      }
    }
    &.request {
      a {
        &::before {
          content: "\f15b";
          font-family: "font awesome 6 free";
          font-weight: 400;
          line-height: 1;
          background: none;
        }
      }
    }
  }

  .h_contact {
    a {
      @include g.flexbox;
      @include g.flex-justyfy-content(center);
      @include g.flex-align-items(center);
      background-color: g.$ac_color;
      color: #fff;
      line-height: $h_height;
      padding: 0 25px;

      @include g.mq(xml) {
        padding: 0 10px;
      }

      @include g.mq {
        line-height: $h_height_sp;
      }

      @include g.mq(md) {
        display: none;
      }

      &::before {
        content: "";
        display: block;
        margin-right: 5px;
        width: 20px;
        height: 20px;
        background: url(../img/common/icon_contact.png) center center no-repeat;
        background-size: cover;
      }

      &:hover {
        opacity: 0.7;
      }
    }

    &.request {
      a {
        &::before {
          content: "\f15b";
          font-family: "font awesome 6 free";
          font-weight: 400;
          line-height: 1;
          background: none;
        }
      }
    }
  }

  .sp_h_job {
    display: none;
    margin-right: 8px;
    @include g.mq {
      font-size: 3.4vw;
      display: block;
      color: #004898;
      border: #004898 1px solid;
      border-radius: 18px;
      background-color: #fff;
      padding: 6px 4vw;
    }
    @include g.mq(sm-s) {
      font-size: 10px;
    }
  }
}

.hamburger {
  display: block;
  width: $h_height;
  height: $h_height;
  z-index: 999;
  background-color: g.$base_color;

  @include g.mq {
    width: $h_height_sp;
    height: $h_height_sp;
  }

  span {
    margin: 0 auto;
    width: 20px;
    height: 2px;
    background-color: #fff;
    position: relative;
    transition: g.$link_time;
    display: block;

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2) {
      margin: 6px auto;
      background: linear-gradient(to right, #fff 0, #fff 75%, transparent 75%, transparent 100%);
    }

    &:nth-child(3) {
      top: 0;
    }
  }

  &.active {
    span {
      &:nth-child(1) {
        top: 5px;
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        opacity: 0;
        margin: 0 auto;
        padding: 4px 0;
      }

      &:nth-child(3) {
        top: -5px;
        transform: rotate(-45deg);
      }
    }
  }
}

.h_drawer {
  visibility: hidden;
  opacity: 0;
  transition: g.$link_time;

  &.active {
    visibility: visible;
    opacity: 1;
  }
}

.drawer_box {
  background-color: g.$li-gray;
  padding: 80px 0;
  position: relative;
  max-height: calc(100vh - $h_height);
  max-height: calc(100dvh - $h_height);
  overflow-y: scroll;

  @include g.mq {
    padding: 40px 0;
    max-height: calc(100vh - $h_height_sp);
    max-height: calc(100dvh - $h_height_sp);
  }

  .drawer_top {
    .list {
      @include g.flexbox;
      @include g.flex-flow(wrap);
    }

    .box {
      @include g.mq {
        width: 100%;
      }

      &:not(:last-child) {
        margin-right: 120px;

        @include g.mq {
          margin-right: 0;
        }
      }
    }

    h2 {
      font-size: 12px;
      line-height: 1.3;
      margin-bottom: 30px;

      @include g.mq {
        margin-bottom: 20px;
      }

      span {
        font-size: 32px;
        color: g.$ac_color;
      }
    }

    ul {
      margin-bottom: 30px;
    }

    li {
      &:nth-child(n + 2) {
        margin-top: 10px;
      }
    }

    dt {
      position: relative;

      &::before,
      &::after {
        @include g.mq {
          content: "";
          display: block;
          background-color: g.$base_color;
          width: 15px;
          height: 2px;
          position: absolute;
          top: 50%;
          right: 20px;
          transform: translateY(-50%);
        }
      }

      &:after {
        transform: translateY(-50%) rotate(90deg);
      }

      &.active {
        &::after {
          transform: translateY(-50%);
        }
      }

      a {
        display: inline-block;
      }
    }

    dd {
      display: block;

      @include g.mq {
        display: none;
      }
    }
  }

  .drawer_bnr {
    display: none;
    @include g.mq {
      display: block;
      margin-bottom: 30px;
    }
    a {
      @include g.mq {
        display: block;
      }
      img {
        width: 100%;
      }
    }
  }

  .drawer_contact {
    display: none;
    @include g.mq {
      display: block;
      margin-bottom: 40px;
    }
    h2 {
      text-align: center;
      font-size: 12px;
      line-height: 1.3;
      margin-bottom: 30px;

      @include g.mq {
        margin-bottom: 20px;
      }

      span {
        display: block;
        font-size: 32px;
        color: g.$ac_color;
      }
    }
    .txt {
      margin-bottom: 10px;
      text-align: center;
    }
    .blk-btn {
      width: calc(280 / 335 * 100%);
      margin: 0 auto 20px;
      a {
        background-color: #212121;
        @include g.flexbox;
        @include g.flex-justyfy-content(center);
        @include g.flex-align-items(center);
        color: #fff;
        border-radius: 34px;
        font-size: 16px;
        padding: 11px 25px;
      }
      &-tel {
        a {
          .sm {
            display: block;
            font-size: 10px;
          }

          &::before {
            content: "";
            display: block;
            margin-right: 5px;
            width: 20px;
            height: 20px;
            background: url(../img/common/icon-tel-1.png) center center no-repeat;
            background-size: cover;
          }

          &:hover {
            opacity: 0.7;
          }
        }
      }
      &-contact {
        a {
          padding: 18.5px 25px;
          &::before {
            content: "";
            display: block;
            margin-right: 5px;
            width: 20px;
            height: 20px;
            background: url(../img/common/icon_contact.png) center center no-repeat;
            background-size: cover;
          }

          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }

  .drawer_bot {
    margin-top: 100px;
    @include g.flexbox;
    @include g.flex-justyfy-content(space-between);
    @include g.flex-align-items(flex-end);

    @include g.mq {
      margin-top: -15px;
    }
  }

  .drawer_sns {
    ul {
      @include g.flexbox;
    }

    li {
      &:nth-child(n + 2) {
        margin-left: 10px;
      }
    }
  }

  .drawer_navi {
    ul {
      @include g.flexbox;
      @include g.flex-align-items(flex-end);
    }

    li {
      font-size: 14px;

      &:not(:last-child) {
        margin-right: 20px;

        @include g.mq {
          display: none;
        }
      }
    }
  }

  .copy {
    margin-top: 10px;
    padding-right: 10px;
    text-align: right;
    color: g.$gray;
    font-size: 10px;
    font-weight: 700;

    @include g.mq {
      display: none;
    }
  }
}

/*------------------------フッター----------------------*/
footer {
  .cont_width {
    @include g.mq {
      padding: 0 30px;
    }
  }
}

.f_link {
  ul {
    @include g.flexbox;
    @include g.flex-flow(wrap);
  }

  li {
    width: 50%;
    // width: 100%;

    @include g.mq {
      width: 100%;
    }

    & > div {
      padding: 40px 60px;
      display: block;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: block;
        background: center center no-repeat;
        background-size: cover;
      }

      @include g.mq(lg) {
        padding: 20px 30px;
      }

      @include g.mq() {
        padding: 10px 30px;
      }
    }

    &:nth-child(1) > div::after {
      background-image: url(../img/common/f_dwonload.jpg);
    }

    &:nth-child(2) > div::after {
      background-image: url(../img/common/f_contact.jpg);
    }
  }

  .inner {
    background-color: rgba($color: #fff, $alpha: 0.8);
    border-radius: 50px 0 50px 0;
    padding: 40px;
    text-align: center;
    min-height: 320px;
    position: relative;
    z-index: 1;

    @include g.mq(lg) {
      padding: 40px 20px;
    }

    @include g.mq {
      padding: 20px;
      min-height: 220px;
    }
  }

  .icon {
    img {
      max-width: 80px;
      max-height: 80px;

      @include g.mq {
        max-height: 45px;
        height: 100%;
        width: auto;
      }
    }
  }

  h2 {
    margin-top: 15px;
    font-size: 28px;

    @include g.mq {
      font-size: 24px;
    }
  }

  p {
    margin-top: 5px;
    font-size: 15px;

    @include g.mq {
      font-size: 10px;
    }
  }

  .link {
    margin-top: 5px;
  }
}

.f_top {
  background-color: g.$li-gray;
  padding-top: 70px;

  @include g.mq {
    background: none;
    padding-top: 0px;
  }

  .page_width {
    @include g.mq {
      padding: 0;
    }
  }

  .box {
    @include g.flexbox;
    @include g.flex-justyfy-content(space-between);
    @include g.flex-align-items(flex-end);
    position: relative;

    @include g.mq(lg) {
      @include g.flex-flow(column-reverse wrap);
    }
  }

  .left,
  .right {
    @include g.mq(lg) {
      width: 100%;
    }

    @include g.mq {
      padding: 0 20px;
    }
  }

  .left {
    @include g.mq(lg) {
      padding-top: 20px;
    }
  }

  .right {
    @include g.mq {
      background-color: g.$li-gray;
      padding-top: 40px;
    }
  }

  .f_logo {
    max-width: 175px;
  }

  .f_guide {
    margin-top: 40px;

    @include g.mq {
      margin-top: 30px;
      @include g.flexbox;
    }

    .guide_list {
      @include g.mq {
        width: 170px;
      }

      li {
        &:nth-child(n + 2) {
          margin-top: 10px;
        }

        a {
          display: inline-block;
          // background-color: g.$base_color;
          color: #fff;
          padding: 10px 15px;
          border-radius: 35px;

          @include g.mq {
            font-size: 14px;
          }
        }

        .bk_btn {
          &::after {
            display: none;
          }
        }

        .guide_inner {
          @include g.flexbox;
        }

        .photo {
          margin-right: 10px;
          width: 18px;

          picture {
            @include g.flexbox;
            @include g.flex-align-items(center);
            @include g.flex-justyfy-content(center);
          }
        }

        p {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    .f_navi {
      @include g.mq {
        width: calc(100% - 170px);
        padding-left: 20px;
        font-size: 14px;
      }

      li {
        &:nth-child(n + 2) {
          margin-top: 10px;
        }
      }
    }
  }

  .f_sns {
    margin-top: 30px;

    ul {
      @include g.flexbox;
    }

    li {
      &:nth-child(n + 2) {
        margin-left: 10px;
      }
    }
  }

  .f_menu {
    padding-bottom: 20px;
    // padding-right: 80px;
    @include g.flexbox;
    @include g.flex-flow(wrap);

    @include g.mq(lg) {
      padding-right: 0;
    }

    .col {
      padding-right: 60px;

      @include g.mq {
        padding-right: 0;
        width: 100%;
      }
    }

    dt {
      position: relative;

      &::before,
      &::after {
        @include g.mq {
          content: "";
          display: block;
          width: 10px;
          height: 2px;
          background-color: g.$base_color;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          transition: g.$link_time;
        }
      }

      &::after {
        transform: translateY(-50%) rotate(90deg);
      }

      &.active {
        &::after {
          transform: translateY(-50%) rotate(0deg);
        }
      }
    }

    dd {
      @include g.mq {
        display: none;
        padding-bottom: 20px;
      }
    }

    .main {
      margin-bottom: 15px;
      font-size: 16px;
      font-weight: 700;

      @include g.mq {
        margin-bottom: 20px;
        font-size: 14px;
      }
    }

    .sub {
      padding-top: 5px;
      font-size: 14px;

      @include g.mq {
        padding-top: 10px;
        font-size: 12px;
      }

      &:nth-child(1) {
        @include g.mq {
          padding-top: 0;
        }
      }
    }

    a {
      display: block;

      @include g.mq {
        width: calc(100% - 50px);
      }
    }
  }

  .privacy_mark {
    position: absolute;
    right: 0;
    bottom: 0;

    @include g.mq {
      right: 20px;
    }
  }
}

.f_bot {
  background-color: g.$li-gray;
  padding: 40px 0 10px;

  @include g.mq {
    background-color: #fff;
    padding-top: 20px;
  }

  .box {
    @include g.flexbox;
    @include g.flex-justyfy-content(space-between);
    @include g.flex-align-items(center);
  }

  .f_copy {
    font-size: 10px;
    font-weight: 700;
    color: g.$gray;
  }

  .f_navi {
    font-size: 14px;

    ul {
      @include g.flexbox;
    }

    li {
      &:nth-child(n + 2) {
        margin-left: 20px;
      }
    }
  }
}

/*------------------------404
----------------------*/
.page404_content {
  padding: 0 0 150px;
  @include g.mq {
    padding: 0 0 80px;
  }
  .ttl {
    h1 {
      font-size: 48px;
      font-weight: 700;
      @include g.mq {
        font-size: 32px;
      }
    }
  }
  .txt {
    margin-bottom: 60px;
    @include g.mq {
      margin-bottom: 40px;
    }
  }
  .link {
    text-align: center;
  }
}
