@charset "utf-8";

@use "global" as g;

.archive_sec {
  .accepting_list {
    margin-bottom: 60px;
    padding: 1px 50px 60px;
    background-color: #ecf4f7;
    border-radius: 100px 0 100px 0;
    @include g.mq {
      margin-bottom: 30px;
      padding: 1px 20px 20px;
      border-radius: 50px 0 50px 0;
    }
    .list {
      flex-wrap: wrap;
    }
    .box {
      width: 347px;
      margin-top: 20px;
      @include g.mq(lg) {
        width: 30%;
      }
      @include g.mq {
        width: 100%;
        margin-top: 10px;
      }
    }
    .date {
      color: #000;
      &:hover {
        color: #00a5e3;
      }
    }
  }
  .pickup_box {
    padding: 1px 50px 60px;
    background-color: #ecf4f7;
    border-radius: 100px 0 100px 0;
    @include g.mq {
      padding: 1px 25px 30px;
      border-radius: 50px 0 50px 0;
    }
    .list {
      .box {
        margin-top: 45px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @include g.mq {
          margin-top: 20px;
        }
        .photo {
          width: calc(480 / 1000 * 100%);
          @include g.mq {
            width: 100%;
            margin-bottom: 15px;
          }
        }
        .txtbox {
          width: calc(480 / 1000 * 100%);
          @include g.mq {
            width: 100%;
          }
          .ttl {
            margin-bottom: 20px;
            padding-bottom: 15px;
            font-size: 18px;
            line-height: 1.67;
            border-bottom: #909090 1px solid;
            position: relative;
            @include g.mq {
              margin-bottom: 15px;
              padding-bottom: 10px;
            }
            &::before {
              content: "";
              position: absolute;
              bottom: -1px;
              left: 0;
              width: calc(168 / 480 * 100%);
              height: 1px;
              background-color: #00a5e3;
            }
          }
          .desc {
            margin-bottom: 30px;
          }
          .bk_btn {
            @include g.mq {
              width: 100%;
            }
          }
        }
      }
    }
  }
  .text-center {
    text-align: center;
  }
  .text-cou {
    margin-top: 30px;
    width: 100%;
  }
  .pager {
    .nav-links {
      display: flex;
      justify-content: center;
      align-items: center;
      .page-numbers {
        margin: 0 15px;
        font-size: 24px;
        font-weight: 700;
        color: #909090;
      }
      .current {
        color: #00a5e3;
      }
    }
    &-right {
      ul,
      li {
        margin: 0 !important;
      }
      .page-numbers {
        margin: 0 10px;
      }
    }
  }
}

.seminar_page {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  @include g.mq {
    padding-bottom: 60px;
  }

  .seminar_info {
    width: 47%;
    @include g.mq {
      width: 100%;
    }
  }
  .seminar_btn {
    text-align: center;
    margin: 40px 0;
  }
  .seminar_prg {
    background-color: #ecf4f7;
    border-radius: 50px 0 50px 0;
    margin: 40px 0;
    padding: 30px 40px;
    .ttl {
      font-size: 24px;
      margin-bottom: 10px;
    }
    .content {
      line-height: 1.68;
      .icon-check {
        display: inline-block;
        padding-left: 24px;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: 7px;
          left: 0;
          background: url(../img/common/Icon-check-1.png) no-repeat;
          background-size: contain;
          width: 16px;
          height: 12px;
        }
      }
      .ind-box {
        display: inline-block;
        padding-left: 30px;
      }
    }
  }
  .seminar_content {
    margin-bottom: 60px;
    h2 {
      border-bottom: 1px solid #909090;
      font-size: 32px;
      margin-bottom: 15px;
      padding-bottom: 15px;
      position: relative;
      @include g.mq {
        font-size: 24px;
      }
      &:after {
        background-color: #00a5e3;
        bottom: -1px;
        content: "";
        display: block;
        height: 1px;
        left: 0;
        position: absolute;
        width: 168px;
      }
    }
    .tb {
      width: 100%;
      border: #909090 1px solid;
      border-bottom: none;
      th {
        width: 25%;
        background-color: #ecf4f7;
        border-bottom: #909090 1px solid;
        padding: 14.5px 20px;
        @include g.mq {
          width: 100%;
          display: block;
          border-bottom: none;
          padding: 11.5px 20px;
        }
      }
      td {
        width: 75%;
        border-bottom: #909090 1px solid;
        padding: 14.5px 20px;
        @include g.mq {
          width: 100%;
          display: block;
          padding: 11.5px 20px;
        }
      }
    }
    .fl {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .img {
        width: 23%;
        @include g.mq {
          width: 60%;
          margin: 0 auto 10px;
        }
      }
      .txtbox {
        width: 72%;
        font-size: 16px;
        @include g.mq {
          width: 100%;
        }
        .post {
          display: block;
          font-size: 14px;
          @include g.mq {
            text-align: center;
          }
        }
        .name {
          display: block;
          font-size: 20px;
          margin: 10px 0;
          @include g.mq {
            text-align: center;
          }
        }
        a {
          color: #00a5e3;
        }
      }
    }
  }

  .seminar_contact {
    width: 47%;
    margin-top: 40px;
    background-color: #ecf4f7;
    border-radius: 25px;
    padding: 30px 20px;
    @include g.mq {
      width: 100%;
    }
    .ttl {
      text-align: center;
      margin-bottom: 20px;
      .ico {
        display: inline-block;
        background-color: #00a5e3;
        color: #fff;
        font-size: 13px;
        border-radius: 20px 0 20px 0;
        padding: 0 19px;
      }
      .txt {
        display: block;
        font-size: 24px;
      }
    }
    .form_list {
      > li {
        font-size: 16px;

        &:nth-child(n + 2) {
          margin-top: 10px;
        }
      }
    }
    label {
      font-weight: 700;

      &.required {
        position: relative;
        @include g.flexbox;
        @include g.flex-align-items(center);

        &::after {
          margin-left: 5px;
          display: inline-block;
          content: "必須";
          background-color: g.$form_red;
          color: #fff;
          font-size: 10px;
          font-weight: 700;
          padding: 1px 10px;
          border-radius: 3px;
        }
      }
      .cou-txt {
        font-size: 14px;
      }
    }
    .form_select {
      position: relative;
      width: 60%;
      @include g.mq {
        width: 100%;
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        border-top: 10px solid g.$base_color;
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;
        width: 12px;
        height: 10px;
        right: 40px;
        top: 50%;
        transform: translateY(-50%);

        @include g.mq {
          right: 18px;
        }
      }
    }
    input[type="text"],
    input[type="number"],
    input[type="tel"],
    input[type="email"],
    select,
    textarea {
      margin-top: 5px;
      width: 100%;
      border: solid 1px g.$gray;
      background-color: g.$li-blue02;
      border-radius: 5px;
      padding: 15px 10px;
      background-color: #fff;

      &::placeholder {
        color: g.$gray04;
      }
    }
    textarea {
      height: 200px;
    }
    select {
      position: relative;

      &::after {
        content: "";
        display: block;
        position: absolute;
        border-top: 10px solid g.$base_color;
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;
        width: 12px;
        height: 10px;
        right: 40px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .wpcf7-list-item {
      margin: 0;
    }
    .note {
      margin-top: 20px;
      font-size: 16px;
    }

    .privacy_agreement__cont {
      border: solid 1px #909090;
      border-radius: 5px;
      height: 200px;
      overflow: scroll;
      padding: 20px 20px 1px;
      margin-bottom: 20px;
      background-color: #fff;
      dt {
        font-weight: bold;
        margin-bottom: 10px;
      }
      dd {
        font-size: 14px;
        margin-bottom: 20px;
      }
    }

    .policy {
      font-weight: 700;

      a {
        color: g.$btn_color;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
    .policy_agree {
      margin-top: 10px;

      label {
        padding-left: 5px;
        font-weight: 500;
      }
    }

    .btn {
      text-align: center;
      margin-top: 40px;
      position: relative;
      .wpcf7-submit.bk_btn {
        @include g.mq {
          width: 90%;
        }
      }

      &::after {
        position: absolute;
        right: 40px;
        top: 18px;
        left: calc(50% + 70px);
        transform: translateX(-50%);
        content: "\f061";
        font-family: "font awesome 6 free";
        font-weight: 900;
        padding-left: 0.8em;
        font-size: 18px;
        color: #fff;
      }
    }
    .wpcf7-spinner {
      display: none;
    }

    button {
      &::after {
        display: none;
      }

      picture {
        margin-left: 20px;
      }
    }

    .message {
      margin-top: 40px;
      text-align: center;
      color: g.$ac_color;
      font-size: 18px;
    }
  }
}
.seminar_related {
  padding-bottom: 100px;
  .seminar_related_ttl {
    text-align: center;
    font-size: 24px;
    margin-bottom: 40px;
  }
  .list {
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 35px;
    .box {
      width: calc(380 / 1220 * 100%);
      @include g.mq {
        width: 100%;
        margin-bottom: 20px;
      }
      .ttl {
        font-size: 18px;
      }
      &:nth-child(2) {
        margin: 0 15px;
        @include g.mq {
          margin: 0;
        }
      }
    }
  }
  .seminar_btn {
    text-align: center;
  }
}
