@charset "utf-8";

@use "global" as g;

.faq_sec {
  margin-bottom: 150px;

  @include g.mq {
    margin-bottom: 50px;
  }

  .faq_intro {
    margin-top: 50px;
    text-align: center;
    font-weight: 700;
  }

  .tab {
    margin-top: 10px;
    ul {
      @include g.flexbox;
      @include g.flex-justyfy-content(center);
      @include g.flex-flow(wrap);
      column-gap: 10px;

      li {
        margin-top: 10px;
        a {
          display: block;
          min-width: 160px;
          color: #fff;
          background-color: g.$ac_color;
          text-align: center;
          font-size: 15px;
          font-weight: 700;
          padding: 10px;
          border-radius: 50px;
          border: 1px solid g.$ac_color;

          @include g.mq(sm) {
            width: calc(50% - 5px);
          }

          &:hover {
            background-color: #fff;
            color: g.$ac_color;
          }
        }
      }
    }
  }

  h2 {
    max-width: 1000px;
    margin: 0 auto;
    padding: 60px 0 30px;
    font-size: 32px;
    color: g.$gray;

    @include g.mq {
      font-size: 26px;
      padding: 50px 0 15px;
    }
  }

  dl {
    max-width: 1000px;
    margin: 0 auto;
  }

  dt,
  dd {
    font-size: 18px;
    padding: 20px 80px 20px 60px;
    border-radius: 100px;
    margin-bottom: 15px;

    @include g.mq {
      font-size: 15px;
      padding: 15px 40px 15px 50px;
    }
  }

  dt {
    background: g.$li-blue02;
    position: relative;
    cursor: pointer;

    &:before,
    &:after {
      content: "";
      display: block;
      width: 20px;
      height: 2px;
      background-color: g.$base_color;
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      transition: g.$link_time;

      @include g.mq {
        width: 10px;
        right: 20px;
      }
    }

    &:after {
      transform: translateY(-50%) rotate(90deg);
    }

    &.active {
      &:after {
        transform: translateY(-50%) rotate(0deg);
      }
    }

    span {
      background: g.$ac_color;
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      line-height: 31px;
      text-align: center;
      width: 31px;
      height: 31px;
      border-radius: 31px;
      position: absolute;
      top: 20px;
      left: 20px;

      @include g.mq {
        font-size: 10px;
        line-height: 21px;
        width: 21px;
        height: 21px;
        top: 15px;
        left: 20px;
      }
    }
  }

  dd {
    display: none;
    padding-top: 10px;
    padding-bottom: 15px;
    border-radius: 0;
    position: relative;

    &:before {
      display: block;
      content: "A";
      font-size: 16px;
      font-weight: bold;
      line-height: 28px;
      text-align: center;
      color: g.$bg_blue;
      width: 31px;
      height: 31px;
      border: solid 2px g.$bg_blue;
      border-radius: 31px;
      position: absolute;
      left: 20px;
      top: 10px;

      @include g.mq {
        font-size: 10px;
        line-height: 18px;
        width: 21px;
        height: 21px;
        left: 20px;
        top: 10px;
      }
    }
  }
}
