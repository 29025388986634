@charset "utf-8";

@use "global" as g;

//info
.info_group {
  margin: 60px 0 150px;

  @include g.mq {
    margin: 40px 0 80px;
  }

  .list {
    @include g.flexbox;
    @include g.flex-justyfy-content(space-between);
    @include g.flex-flow(wrap);

    a {
      &:hover {
        .ttl {
          color: g.$ac_color;
        }
      }
    }

    > li {
      &.col2 {
        width: calc((100% - 40px) / 2);

        @include g.mq {
          width: 100%;
        }
      }

      &.col3 {
        width: calc((100% - 30px * 2) / 3);

        @include g.mq {
          width: 100%;
        }
      }

      &:nth-child(n + 3) {
        margin-top: 60px;
      }

      &:nth-child(n + 2) {
        @include g.mq {
          margin-top: 20px;
        }
      }
    }
  }

  .photo {
    border-radius: 50px 0 50px 0;
    overflow: hidden;
    position: relative;
    z-index: 1; //iphone角丸とれる崩れ防止用

    &.philosophy {
      border: solid 1px g.$gray;
    }
  }

  .ttl {
    margin-top: 10px;
    font-size: 32px;
    transition: g.$link_time;

    @include g.mq {
      font-size: 24px;
    }
  }
}

//info下層
.info_vision {
  margin-top: 90px;

  @include g.mq {
    margin-top: 20px;
  }

  .vision_list {
    max-width: 985px;
    width: 100%;
    margin: 0 auto;

    .box {
      margin-top: 60px;
      border-bottom: solid 1px g.$gray;
      position: relative;

      &::after {
        position: absolute;
        content: "";
        display: block;
        background-color: g.$ac_color;
        width: 100px;
        height: 1px;
        left: 0;
        bottom: -1px;
      }

      @include g.mq {
        margin-top: 40px;
      }

      &:nth-child(n + 2) {
        margin-top: 50px;

        @include g.mq {
          margin-top: 25px;
        }
      }
    }

    .num {
      color: g.$ac_color;
      font-size: 14px;
      font-weight: 700;
    }

    .text {
      padding: 15px 0;
      font-size: 20px;

      @include g.mq {
        font-size: 18px;
      }
    }
  }
}

.info_action {
  margin-top: 150px;
  padding: 35px 0 150px;
  background-color: g.$li-blue02;

  @include g.mq {
    margin-top: 80px;
    padding: 20px 0 80px;
  }

  .action_list {
    margin-top: 60px;
    padding: 100px;
    background-color: #fff;
    border-radius: 100px 0 100px 0;

    @include g.mq {
      margin-top: 40px;
      padding: 20px 20px 30px;
      border-radius: 50px 0 50px 0;
    }
  }

  .box {
    padding: 30px 0;
    border-bottom: solid g.$gray 1px;
    position: relative;

    @include g.mq {
      padding: 15px 0;
    }

    &::after {
      position: absolute;
      content: "";
      display: block;
      background-color: g.$ac_color;
      width: 168px;
      height: 1px;
      left: 0;
      bottom: -1px;

      @include g.mq {
        width: 100px;
      }
    }
  }

  .num {
    font-size: 80px;
    line-height: 1.2;
    opacity: 0.1;
    position: absolute;
    top: 0;
    left: 0;

    @include g.mq {
      font-size: 50px;
    }
  }

  .ttl {
    font-size: 24px;

    @include g.mq {
      font-size: 20px;
    }
  }

  .text {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 400;
  }

  .item {
    margin-top: 1.5em;

    h4 {
      @include g.mq {
        font-weight: 400;
      }
    }
  }
}

.info_compliance {
  margin-top: 90px;

  @include g.mq {
    margin-top: 20px;
  }

  .law_list {
    max-width: 1000px;
    margin: 60px auto 0;

    @include g.mq {
      margin: 20px auto 0;
    }

    .list {
      @include g.flexbox;
      @include g.flex-justyfy-content(flex-start);
      @include g.flex-align-items(stretch);
      @include g.flex-flow(wrap);
    }

    .box {
      width: calc((100% - 13px * 3) / 4);
      @include g.flexbox;
      @include g.flex-justyfy-content(center);
      @include g.flex-align-items(center);
      padding: 12px;
      border: solid 1px g.$ac_color;
      font-size: 20px;
      font-weight: 700;

      @include g.mq {
        width: calc((100% - 15px) / 2);
        padding: 10px 5px;
        font-size: 16px;
      }

      &:not(:nth-child(4n)) {
        margin-right: 13px;

        @include g.mq {
          margin-right: 0px;
        }
      }

      &:not(:nth-child(2n)) {
        @include g.mq {
          margin-right: 15px;
        }
      }

      &:nth-child(n + 5) {
        margin-top: 13px;
      }

      &:nth-child(n + 3) {
        @include g.mq {
          margin-top: 15px;
        }
      }
    }
  }

  .compliance_point {
    background-color: g.$li-blue02;
    border-radius: 100px 0 100px 0;
    margin: 100px 0 150px;
    padding: 100px;

    @include g.mq {
      margin: 60px 0 80px;
      padding: 30px 20px;
      border-radius: 50px 0 50px 0;
    }

    .box {
      &:nth-child(n + 2) {
        margin-top: 60px;

        @include g.mq {
          margin-top: 30px;
        }
      }
    }

    .ttl {
      font-size: 32px;
      color: g.$ac_color;

      @include g.mq {
        font-size: 24px;
      }
    }

    .desc {
      margin-top: 20px;
      font-size: 18px;

      @include g.mq {
        margin-top: 10px;
      }
    }
  }
}

.info_compliance_strong {
  .bg_wrap {
    @include g.mq(lg) {
      background-color: g.$ac_color;
    }
  }
  .bg {
    background-color: g.$ac_color;
    border-radius: 100px 0 100px 0;
    margin: 40px 0 100px;
    padding: 100px;

    @include g.mq {
      margin: 40px 0 80px;
      padding: 40px 0;
      border-radius: 0;
    }
  }

  .sec_ttl {
    .tag {
      background-color: #fff;
      color: #00a5e3;
    }
  }

  .ttl {
    text-align: center;

    h2,
    h3 {
      font-size: 32px;
    }

    .large {
      font-size: 48px;

      @include g.mq {
        font-size: 32px;
      }
    }

    .white {
      color: #fff;
    }
  }

  .intro {
    font-size: 18px;
    max-width: 800px;
    margin: 40px auto 0;

    @include g.mq {
      font-size: 16px;
      margin: 10px auto 0;
    }
  }

  .strong_list {
    margin-top: 55px;

    @include g.mq {
      margin-top: 20px;
    }

    .box {
      max-width: 1000px;
      width: 100%;
      margin: 0 auto;
      padding: 60px;
      background-color: #fff;
      border-radius: 50px 0 50px 0;
      @include g.flexbox;
      @include g.flex-flow(wrap);
      @include g.flex-align-items(center);

      @include g.mq {
        padding: 30px 20px;
        @include g.flex-flow(column-reverse);
      }

      &:nth-child(n + 2) {
        margin-top: 40px;

        @include g.mq {
          margin-top: 20px;
        }
      }
    }

    .text {
      width: 55%;

      @include g.mq {
        width: 100%;
        margin-top: 20px;
      }
    }

    .photo {
      width: 45%;
      padding-left: 40px;

      @include g.mq {
        width: 100%;
        padding-left: 0px;
      }
    }

    .num {
      color: g.$ac_color;
      font-size: 20px;
      font-weight: 700;

      @include g.mq {
        text-align: center;
      }
    }

    h3 {
      margin-top: 10px;
      font-size: 32px;

      @include g.mq {
        font-size: 24px;
        text-align: center;
      }
    }

    p {
      margin-top: 10px;
      font-weight: 400;
      font-size: 16px;
    }

    .point_box {
      margin-top: 10px;
      background-color: g.$gray03;
      padding: 15px;

      h5 {
        color: g.$btn_color;
        font-size: 20px;
      }

      ul {
        margin-top: 10px;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .num_list {
      counter-reset: number 0;

      li {
        font-size: 20px;
        font-weight: 700;
        padding-left: 60px;
        position: relative;

        @include g.mq {
          font-size: 18px;
          padding-left: 0px;
          padding-top: 40px;
        }

        &::before {
          position: absolute;
          counter-increment: number 1;
          content: counter(number, decimal-leading-zero) ".";
          font-size: 32px;
          font-weight: 700;
          color: g.$ac_color;
          top: 50%;
          left: 0;
          transform: translateY(-50%);

          @include g.mq {
            font-size: 28px;
            top: 0px;
            transform: translateY(0);
          }
        }

        &:nth-child(n + 2) {
          margin-top: 30px;

          @include g.mq {
            margin-top: 20px;
          }
        }
      }
    }

    .link {
      text-align: right;
      color: g.$btn_color;

      a {
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &.col2 {
      > ul {
        @include g.flexbox;
        @include g.flex-justyfy-content(space-between);
        @include g.flex-flow(wrap);
      }

      .box {
        width: calc((100% - 40px) / 2);
        margin: 0;
        padding: 40px;
        @include g.flex-flow(column-reverse);
        @include g.flex-justyfy-content(flex-end);
        position: relative;

        @include g.mq(lg) {
          width: 100%;
          padding: 30px 20px;
        }

        &:nth-child(n + 3) {
          margin-top: 40px;
        }

        &:nth-child(n + 2) {
          @include g.mq(lg) {
            margin-top: 20px;
          }
        }
      }

      .text {
        width: 100%;
        margin-top: 20px;
      }

      .photo {
        width: 100%;
        text-align: center;
        padding-left: 0;
      }

      .num {
        position: absolute;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        top: 40px;
        left: 40px;

        @include g.mq {
          top: 30px;
          left: 20px;
        }
      }

      h3 {
        text-align: center;
        font-size: 24px;
        margin-bottom: 10px;
      }

      .text-list {
        li {
          font-weight: 400;
          padding-left: 11px;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            top: 8px;
            left: 0;
            width: 5px;
            height: 5px;
            background-color: #004898;
            border-radius: 50%;
          }
        }
      }
    }

    &.col3 {
      .box {
        width: 100%;
        @include g.flexbox;
        @include g.flex-justyfy-content(space-between);
        @include g.flex-flow(wrap);

        .textbox {
          @include g.flexbox;
          @include g.flex-flow(column-reverse);
          @include g.flex-justyfy-content(flex-end);
          width: calc((100% - 40px) / 2);
          @include g.mq(lg) {
            width: 100%;
          }
        }
        .photo-box {
          @include g.flexbox;
          @include g.flex-justyfy-content(space-between);
          @include g.flex-flow(wrap);
          width: 50%;
          @include g.mq(lg) {
            width: 100%;
            margin-top: 20px;
          }
          > ul {
            @include g.flexbox;
            @include g.flex-justyfy-content(space-between);
            @include g.flex-flow(wrap);
            .item {
              width: calc((100% - 28px) / 2);
              background-color: #ecf4f7;
              border-radius: 20px 0 20px 0;
              margin-bottom: 15px;
              padding: 20px 10px 15px;
              position: relative;
              @include g.mq(lg) {
                width: 100%;
                margin: 10px 0 0;
                padding: 7px 15px;
                @include g.flexbox;
                @include g.flex-justyfy-content(space-between);
                align-items: center;
              }
              .no {
                position: absolute;
                top: 10px;
                left: 20px;
                font-size: 18px;
                font-weight: bold;
                color: #008ce3;
                @include g.mq(lg) {
                  top: -13px;
                  left: 5px;
                }
              }
              .img {
                text-align: center;
                margin-bottom: 10px;
                @include g.mq(lg) {
                  width: 21%;
                }
              }
              h4 {
                text-align: center;
                font-size: 16px;
                @include g.mq(lg) {
                  width: 77%;
                  text-align: left;
                }
              }
              &:nth-child(5) {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  &_support {
    @include g.mq {
      margin-bottom: 40px;
    }
    .bg {
      border-radius: 50px 0 50px 0;
      padding: 50px;

      @include g.mq(lg) {
        border-radius: 30px 0 30px 0;
        padding: 30px;
      }
      @include g.mq {
        padding: 40px 0 20px;
      }
    }
  }

  .support_list {
    ul {
      width: 100%;
      @include g.flexbox;
      @include g.flex-justyfy-content(space-between);
      @include g.flex-flow(wrap);
      .box {
        width: calc((100% - 80px) / 3);
        background-color: #fff;
        border-radius: 50px 0 50px 0;
        padding: 20px 10px;
        text-align: center;

        @include g.mq(lg) {
          width: calc((100% - 50px) / 3);
          border-radius: 30px 0 30px 0;
        }
        @include g.mq {
          width: 100%;
          margin-bottom: 20px;
        }
        .text {
          font-weight: bold;
          h3 {
            font-size: 27px;

            @include g.mq(lg) {
              font-size: 26px;
              min-height: 3em;
            }
            @include g.mq {
              min-height: initial;
              min-height: auto;
            }
          }
        }
        .photo {
          width: 53%;
          margin: 10px auto 30px;
          img {
            width: 100%;
            height: auto;
          }
        }
        .btn {
          .bk_btn {
            text-align: center;
            width: 222px;
            padding: 12px 0;

            @include g.mq(lg) {
              width: 100%;
            }
            @include g.mq {
              width: 222px;
              font-size: 16px;
            }

            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
}

.info_compliance_achievement {
  background-color: #ebf4f7;
  padding: 80px 0 100px;
  @include g.mq {
    padding: 40px 0 30px;
    margin-bottom: 50px;
  }
  .sec_ttl {
    text-align: center;
  }
  ul {
    li {
      margin-top: 80px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      @include g.mq {
        margin-top: 30px;
      }
      .img-box {
        width: calc(580 / 1200 * 100%);
        @include g.mq(lg) {
          width: 100%;
          margin-bottom: 20px;
        }
        @include g.mq {
          margin-left: -6%;
          width: 106%;
          margin-bottom: 15px;
        }
        img {
          border-radius: 100px 0 100px 0;
          @include g.mq(lg) {
            border-radius: 0 100px 0 0;
            width: 100%;
          }
          @include g.mq {
            border-radius: 0 50px 0 0;
          }
        }
      }
      .text-box {
        width: calc(590 / 1200 * 100%);
        @include g.mq(lg) {
          width: 100%;
        }
        h3 {
          font-size: 32px;
          margin-bottom: 10px;
          @include g.mq {
            font-size: 24px;
          }
        }
        p {
          margin-bottom: 1em;
          font-weight: 400;
        }
      }
      &:last-of-type {
        flex-direction: row-reverse;
      }
    }
  }
}
.bg_ttl_cmp {
  text-align: center;
  margin-top: -133px;
  @include g.mq {
    margin-top: -110px;
  }
}

.info_company {
  margin: 90px 0 150px;

  @include g.mq {
    margin: 20px 0 80px;
  }
  .company_box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .kaso_fv {
      width: 42%;
      @include g.mq {
        width: 100%;
      }
    }
    .info_list {
      width: 53%;
      @include g.mq {
        width: 100%;
      }
    }
  }

  .info_list {
    margin-top: 60px;

    dl {
      //   @include g.flexbox;
      //   @include g.flex-flow(wrap);
      border-bottom: solid 1px g.$gray;
      position: relative;
      padding: 20px 0 20px 40px;
      font-size: 18px;

      @include g.mq {
        padding: 15px 0 15px 10px;
      }

      &::after {
        position: absolute;
        content: "";
        display: block;
        background-color: g.$ac_color;
        width: 120px;
        height: 1px;
        bottom: -1px;
        left: 0;
      }

      &:first-child {
        border-top: solid 1px g.$gray;

        &::before {
          position: absolute;
          content: "";
          display: block;
          background-color: g.$ac_color;
          width: 120px;
          height: 1px;
          top: -1px;
          left: 0;
        }
      }
    }

    dt {
      //   width: 200px;
      font-weight: 700;
      margin-bottom: 5px;

      @include g.mq {
        width: 100%;
      }
    }

    dd {
      //   width: calc(100% - 200px);

      @include g.mq {
        width: 100%;
        margin-top: 5px;
      }
    }

    a {
      color: g.$btn_color;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    .flex_box {
      @include g.flex-justyfy-content(flex-start);
      @include g.flex-align-items(center);

      p {
        @include g.mq {
          margin-top: 5px;
        }
      }
    }
  }
}

.info_message {
  margin: 90px 0 150px;

  @include g.mq {
    margin: 20px 0 80px;
  }

  .president {
    margin-top: 50px;

    @include g.mq {
      margin-top: 20px;
    }
  }

  .president_top {
    @include g.flexbox;
    @include g.flex-align-items(center);
    @include g.flex-flow(wrap);
    @include g.flex-justyfy-content(space-between);

    @include g.mq {
      @include g.flex-flow(column-reverse);
    }

    .text {
      width: 50%;

      @include g.mq {
        width: 100%;
        margin-top: 20px;
      }
    }

    .photo {
      width: calc(50% - 80px);
      margin-left: 80px;
      border-radius: 100px 0 100px 0;
      overflow: hidden;

      @include g.mq {
        width: 100%;
        margin-left: 0px;
        text-align: center;
      }
    }

    .desc {
      width: 100%;
    }

    .ttl {
      font-size: 32px;
      color: g.$ac_color;

      @include g.mq {
        font-size: 24px;
      }
    }

    .name {
      @include g.flexbox;
      @include g.flex-justyfy-content(flex-end);
      margin-top: 20px;
      font-size: 16px;
      font-weight: 700;

      @include g.mq {
        @include g.flex-justyfy-content(flex-start);
        margin-top: 10px;
      }
    }
  }

  .president_bot {
    .desc {
      margin-top: 40px;
      font-size: 18px;
      font-weight: 400;

      @include g.mq {
        margin-top: 20px;
        font-size: 16px;
      }
    }
  }
}

.info_access {
  margin: 90px 0 150px;

  @include g.mq {
    margin: 20px 0 80px;
  }

  .tab_list {
    margin: 100px auto 0;
    max-width: 1080px;

    @include g.mq {
      margin-top: 20px;
    }

    .list {
      @include g.flexbox;
      @include g.flex-justyfy-content(space-between);
      @include g.flex-flow(wrap);
    }

    .box {
      width: calc((100% - 20px * 4) / 5);

      @include g.mq {
        width: calc((100% - 15px) / 2);
      }

      &:nth-child(n + 3) {
        @include g.mq {
          margin-top: 15px;
        }
      }

      a {
        display: block;
        background-color: g.$base_color;
        color: #fff;
        text-align: center;
        font-size: 15px;
        font-weight: 700;
        padding: 15px;
        border-radius: 50px;
        position: relative;

        @include g.mq {
          padding: 10px;
        }

        &:hover {
          background-color: g.$btn_color;
        }

        &::after {
          position: absolute;
          content: "\f107";
          display: block;
          font-family: "font awesome 6 free";
          font-weight: 900;
          font-size: 12px;
          top: 50%;
          right: 20px;
          transform: translateY(-50%);
        }
      }
    }
  }

  .office_list {
    margin: 100px auto 0;
    max-width: 1000px;
    width: 100%;

    @include g.mq {
      margin-top: 40px;
    }

    .box {
      @include g.flexbox;
      @include g.flex-justyfy-content(space-between);
      padding: 60px 0;
      border-bottom: solid 1px g.$gray;
      position: relative;

      @include g.mq {
        @include g.flex-flow(wrap);
        padding: 30px 0 30px 10px;
      }

      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -1px;
        background-color: g.$ac_color;
        width: 120px;
        height: 1px;

        @include g.mq {
          width: 100px;
        }
      }

      &:first-child {
        background-color: g.$li-blue02;
        border-radius: 100px 0 100px 0;
        padding: 60px;
        border: none;

        @include g.mq {
          border-radius: 50px 0 50px 0;
          padding: 30px 20px;
        }

        &::after {
          display: none;
        }

        .left {
          width: 410px;
          padding-right: 30px;
          padding-left: 0;

          @include g.mq {
            width: 100%;
            padding-right: 0px;
          }
        }

        .right {
          width: calc(100% - 410px);

          @include g.mq {
            width: 100%;
          }

          p {
            margin-top: 60px;

            @include g.mq {
              margin-top: 0px;
            }
          }
        }
      }
    }

    .left {
      width: 320px;
      padding-left: 40px;

      @include g.mq {
        width: 100%;
        padding-left: 0px;
      }

      .area {
        font-size: 32px;
        color: g.$ac_color;

        @include g.mq {
          font-size: 24px;
        }
      }

      .photo {
        margin-top: 15px;
      }
    }

    .right {
      width: calc(100% - 320px);

      @include g.mq {
        width: 100%;
        margin-top: 15px;
      }

      .branch {
        &:nth-child(n + 2) {
          margin-top: 40px;
        }
      }

      .name {
        font-size: 24px;
      }

      p {
        margin-top: 10px;
        font-size: 18px;
      }

      .link {
        margin-top: 10px;

        a {
          display: inline-block;
          background-color: #fff;
          border: solid 2px g.$ac_color;
          padding: 8px 30px;
          border-radius: 40px;
          font-size: 15px;
          font-weight: 700;

          &::before {
            content: "\f3c5";
            font-family: "font awesome 6 free";
            font-weight: 900;
            color: g.$ac_color;
            padding-right: 0.2em;
          }

          &:hover {
            background-color: g.$ac_color;

            &::before {
              color: #fff;
            }
          }
        }
      }
    }
  }
}

.info_haken {
  @include g.mq {
    margin: 20px 0 60px;
  }

  .structure,
  .office {
    max-width: 860px;
    margin: 0 auto 120px;

    @include g.mq {
      margin: 0 auto 60px;
    }
  }

  h3 {
    font-size: 40px;
    text-align: center;
    font-weight: bold;
    color: g.$ac_color;
    margin-bottom: 20px;

    @include g.mq {
      font-size: 24px;
    }

    & + p {
      padding: 0 30px;
      width: fit-content;
      margin: 0 auto;

      @include g.mq {
        padding: 0;
      }
    }
  }

  h4 {
    font-size: 18px;
    font-weight: normal;
    padding: 15px 0;
    margin-bottom: 15px;
    border-bottom: 1px solid g.$gray;
    border-image: linear-gradient(to right, g.$ac_color 100px, g.$gray 100px);
    border-image-slice: 1;

    @include g.mq {
      font-size: 16px;
    }
  }

  p {
    font-size: 18px;

    @include g.mq {
      font-size: 16px;
    }
  }

  img {
    display: block;
    margin: 20px auto;
  }

  a {
    color: g.$btn_color;
    text-decoration: underline;

    &:hover {
      opacity: 0.8;
    }
  }

  .num_formula {
    text-align: center;
    margin: 20px 0 60px;

    @include g.mq {
      margin: 20px 0 40px;
    }

    p {
      font-weight: bold;
    }
  }

  dl {
    margin-bottom: 60px;

    dt {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
      padding-left: 20px;
      position: relative;

      @include g.mq {
        margin-bottom: 10px;
      }

      &:before {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        background: g.$ac_color;
        border-radius: 10px;
        position: absolute;
        left: 0;
        top: 6px;
      }
    }

    dd {
      margin-bottom: 30px;
      padding-left: 20px;

      @include g.mq {
        font-size: 16px;
      }
    }
  }

  .office {
    margin-top: 150px;

    @include g.mq {
      margin-top: 80px;
    }

    ul {
      @include g.flexbox;
      @include g.flex-flow(wrap);
      margin-top: 40px;

      @include g.mq {
        @include g.flex-flow(column);
      }

      li {
        width: 25%;
        padding-right: 20px;
        margin-bottom: 30px;
        font-size: 18px;

        @include g.mq {
          width: 100%;
          margin-bottom: 20px;
        }

        &:before {
          display: inline-block;
          content: "\f1c1";
          font-family: "font awesome 5 free";
          font-weight: 900;
          font-size: 24px;
          color: g.$btn_color;
          margin-right: 10px;
        }
      }
    }
  }
}

.info_publicnotice {
  margin: 60px 0 100px;

  @include g.mq {
    margin: 20px 0 60px;
  }

  .page_list {
    max-width: 860px;
    margin: 0 auto 80px;

    @include g.mq {
      margin: 0 auto 60px;
    }
  }

  h3 {
    font-size: 40px;
    text-align: center;
    font-weight: bold;
    color: g.$ac_color;
    margin-bottom: 20px;

    @include g.mq {
      font-size: 24px;
    }

    & + p {
      padding: 0 30px;
      width: fit-content;
      margin: 0 auto;

      @include g.mq {
        padding: 0;
      }
    }
  }

  .notice {
    max-width: 1000px;
    margin: -20px auto 0;

    > ul {
      a {
        @include g.flexbox;
        @include g.flex-align-items(stretch);
        @include g.flex-justyfy-content(flex-start);
        border-bottom: 1px solid g.$gray;
        border-image: linear-gradient(to right, g.$ac_color 100px, g.$gray 100px);
        border-image-slice: 1;

        &:hover {
          opacity: 0.8;
        }
      }

      .u_line {
        &:after {
          bottom: -1px;
        }
      }

      .date,
      .ttl {
        padding: 15px 0;
        font-size: 16px;
        margin-top: 0;
      }

      .date {
        width: 150px;
        font-weight: bold;
        position: relative;
        padding-left: 25px;

        &:before {
          position: absolute;
          left: 0;
          top: 12px;
          content: "\f1c1";
          font-family: "font awesome 5 free";
          font-weight: 900;
          font-size: 24px;
          color: g.$btn_color;
        }
      }

      .ttl {
        width: calc(100% - 150px);
      }
    }
  }
}
