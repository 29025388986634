@charset "utf-8";

@use "global" as g;

/*一覧*/
.recruit_main {
  width: calc(100vw - calc(100vw - 100%));
}
.recruit_wrap {
  margin-bottom: 150px;

  @include g.mq(lg) {
    margin-bottom: 80px;
  }

  .type {
    @include g.flexbox;
    @include g.flex-align-items(flex-start);
    @include g.flex-justyfy-content(space-between);
    margin-bottom: 60px;

    @include g.mq(lg) {
      @include g.flex-flow(column-reverse);
      margin-bottom: 20px;
    }

    .photo {
      width: 100%;
      margin-right: 20px;

      @include g.mq(lg) {
        margin-right: 0;
      }

      img {
        width: 100%;
        border-radius: 100px 0 100px 0;

        @include g.mq {
          border-radius: 50px 0 50px 0;
        }
      }
    }

    .text {
      width: 100%;

      @include g.mq(lg) {
        margin-bottom: 20px;
      }

      .tag {
        display: inline-block;
        color: #fff;
        background-color: g.$ac_color;
        font-size: 20px;
        font-weight: 700;
        line-height: 1;
        padding: 2px 30px;
        border-radius: 20px 0px 20px 0px;
        margin-bottom: 15px;

        @include g.mq {
          font-size: 13px;
          padding: 2px 20px;
        }
      }

      h3 {
        font-size: 48px;
        font-weight: bold;
        margin-bottom: 15px;

        @include g.mq {
          font-size: 32px;
        }
      }

      .catch {
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 20px;

        @include g.mq {
          font-size: 24px;
        }
      }

      p {
        @include g.mq {
          font-size: 16px;
        }
      }
    }
  }

  &:nth-of-type(even) {
    .type {
      @include g.flex-flow(row-reverse);

      @include g.mq(lg) {
        @include g.flex-flow(column-reverse);
      }

      .photo {
        margin-right: 0;
        margin-left: 20px;

        @include g.mq(lg) {
          margin-left: 0;
        }
      }
    }
  }

  .movie {
    margin-bottom: 60px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    .txtbox {
      width: 49%;
      text-align: center;
      font-size: 28px;
      font-weight: bold;
      @include g.mq(lg) {
        width: 100%;
        margin-bottom: 20px;
      }
      @include g.mq(md) {
        font-size: 20px;
      }
    }
    .video {
      width: 49%;
      margin: 0 auto;
      text-align: center;
      position: relative;
      padding-top: 28%;
      position: relative;
      @include g.mq(lg) {
        width: 100%;
        padding-top: 56%;
      }
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
      }
    }
    &-order {
      .txtbox {
        order: 2;
        @include g.mq(lg) {
          order: 1;
        }
      }
      .video {
        order: 1;
        @include g.mq(lg) {
          order: 2;
        }
      }
    }
  }

  .detail {
    background: g.$li-blue02;
    text-align: center;
    padding: 60px 40px;

    @include g.mq {
      padding: 30px 20px;
    }

    .ttl {
      font-size: 20px;
      font-weight: bold;
    }

    p {
      margin-bottom: 10px;

      @include g.mq {
        font-size: 16px;
      }
    }

    > a {
      display: block;
      margin-top: 30px;

      @include g.mq {
        margin-top: 20px;
      }

      &:hover {
        opacity: 0.8;
      }

      img {
        width: 100%;
        max-width: 280px;

        @include g.mq(sm) {
          max-width: 100%;
        }
      }
    }

    ul {
      @include g.flexbox;
      @include g.flex-align-items(flex-start);
      @include g.flex-justyfy-content(flex-start);
      @include g.flex-flow(wrap);
      margin-bottom: -10px;

      @include g.mq(lg) {
        @include g.flex-justyfy-content(space-between);
      }

      li {
        width: calc((100% - 80px) / 3);
        margin-top: 40px;
        margin-right: 40px;

        @include g.mq(xl) {
          width: calc((100% - 40px) / 3);
          margin-right: 20px;
        }

        @include g.mq(lg) {
          width: 48%;
          margin-top: 20px;
          margin-right: 0;
        }

        @include g.mq {
          width: 100%;
          margin-top: 10px;
          margin-right: 0;
        }

        &:nth-child(3n) {
          margin-right: 0;
        }
      }

      a {
        display: block;
        padding: 20px 50px 20px 20px;
        position: relative;

        @include g.mq {
          padding: 15px 50px 15px 20px;
        }

        &:after {
          position: absolute;
          right: 10%;
          top: 50%;
          transform: translateY(-50%);
        }

        &.h_min {
          padding: 10px 50px 10px 20px;
        }
      }
    }
  }

  // system
  .system {
    margin: 100px 0 0;
    padding: 80px 9% 60px;
    background-color: #51c1eb;
    @include g.mq(md) {
      padding: 30px 20px 10px;
    }
    .ttl {
      margin-bottom: 40px;
      text-align: center;
      font-size: 32px;
      font-weight: bold;
      @include g.mq(md) {
        font-size: 20px;
      }
      .sp-con {
        display: none;
        @include g.mq(lg) {
          display: block;
        }
      }
    }
    &_wrap {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 20px;
      .box {
        width: 49%;
        padding: 30px 20px 15px 30px;
        background-color: #fff;
        display: flex;
        flex-wrap: wrap;
        @include g.mq(lg-x) {
          width: 100%;
        }
        @include g.mq(md) {
          padding: 20px 10px 20px 20px;
        }
        .txtbox {
          width: 63%;
          > dl {
            > dt {
              font-size: 32px;
              font-weight: bold;
              color: #00a5e3;
              @include g.mq(md) {
                font-size: 20px;
              }
            }
            > dd {
              display: inline-block;
              font-weight: bold;
              color: #00a5e3;
              line-height: 0.9;
              background: linear-gradient(transparent 90%, #ffe867 90%);
              .head {
                display: block;
                font-size: 24px;
                @include g.mq(md) {
                  font-size: 14px;
                }
              }
              .size-l {
                font-size: 108px;
                font-family: Helvetica;
                @include g.mq(xxl) {
                  font-size: 8vw;
                }
                @include g.mq(md) {
                  font-size: 66px;
                }
              }
              .size-m {
                font-size: 72px;
                font-family: Helvetica;
                @include g.mq(xxl) {
                  font-size: 5.3vw;
                }
                @include g.mq(md) {
                  font-size: 43px;
                }
              }
              .size-s {
                font-size: 32px;
                @include g.mq(xxl) {
                  font-size: 2.3vw;
                }
                @include g.mq(md) {
                  font-size: 19px;
                }
              }
            }
          }
        }
        .img {
          width: 36%;
          display: flex;
        }
        .cou-txt {
          width: 100%;
          margin-top: 13px;
          text-align: right;
          @include g.mq(md) {
            font-size: 10px;
          }
        }
      }
      .box01 {
        @include g.mq(lg-x) {
          margin-bottom: 20px;
        }
        .txtbox {
          > dl {
            > dt {
              margin-bottom: 50px;
              @include g.mq(md) {
                margin-bottom: 40px;
              }
            }
          }
        }
        .img {
          align-items: center;
          @include g.mq(md) {
            align-items: flex-end;
          }
        }
      }
      .box02 {
        @include g.mq(md) {
          padding: 20px 10px 10px 20px;
        }
        .txtbox {
          > dl {
            > dt {
              margin-bottom: 20px;
            }
          }
        }
        .img {
          align-items: flex-end;
        }
      }
      &_all {
        .box {
          margin-bottom: 20px;
          padding: 30px 20px 20px;
          background-color: #fff;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          align-items: center;
          @include g.mq(md) {
            padding: 20px 13px;
          }
          .txtbox {
            width: 80%;
            @include g.mq(lg-x) {
              width: 100%;
            }
            dl {
              dt {
                margin-bottom: 20px;
                font-size: 32px;
                font-weight: bold;
                color: #00a5e3;
                @include g.mq(md) {
                  margin-bottom: 10px;
                  font-size: 20px;
                }
              }
              dd {
                font-size: 20px;
                font-weight: bold;
                @include g.mq(md) {
                  font-size: 16px;
                }
              }
            }
          }
          .img {
            width: 15%;
          }
        }
        .box03 {
          .txtbox {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            flex-wrap: wrap;
            dl {
              width: 31%;
              &:nth-child(1) {
                width: 40%;
                @include g.mq(lg-x) {
                  width: 100%;
                  text-align: center;
                  margin-bottom: 20px;
                }
              }
              &:nth-child(2) {
                @include g.mq(lg-x) {
                  width: 100%;
                  text-align: center;
                  margin: 0 auto 10px;
                }
              }
              &:nth-child(3) {
                width: 27%;
                @include g.mq(lg-x) {
                  width: 45%;
                }
              }
              &:nth-child(4) {
                @include g.mq(lg-x) {
                  width: 55%;
                }
              }
              &:nth-child(3),
              &:nth-child(4) {
                text-align: center;

                dt {
                  width: 72.6%;
                  margin: 0 auto 10px;
                  font-size: 20px;
                  color: #fff;
                  background-color: #00a5e3;
                  border-radius: 18px;
                  @include g.mq(md) {
                    font-size: 15px;
                  }
                }
                dd {
                  display: inline-block;
                  font-weight: bold;
                  color: #00a5e3;
                  line-height: 0.9;
                  background: linear-gradient(transparent 90%, #ffe867 90%);
                  .size-l {
                    font-size: 90px;
                    font-family: Helvetica;
                    letter-spacing: -0.05em;
                    @include g.mq(xxl) {
                      font-size: 6.5vw;
                    }
                    @include g.mq(md) {
                      font-size: 60px;
                    }
                  }
                  .size-s {
                    font-size: 32px;
                    @include g.mq(xxl) {
                      font-size: 2.3vw;
                    }
                    @include g.mq(md) {
                      font-size: 20px;
                    }
                  }
                }
              }
            }
            .sp-img {
              display: none;
              @include g.mq(lg-x) {
                display: block;
              }
              dd {
                @include g.mq(md) {
                  width: 37%;
                  margin: 0 auto;
                }
              }
            }
          }
          .img {
            @include g.mq(lg-x) {
              display: none;
            }
          }
        }
        .box04 {
          .txtbox {
            dl {
              @include g.mq(lg-x) {
                text-align: center;
                margin-bottom: 20px;
              }
            }
          }
          .img {
            width: 18%;
            @include g.mq(lg-x) {
              width: 100%;
              text-align: center;
              margin: 0 auto;
            }
            @include g.mq(md) {
              width: 40%;
            }
          }
        }
      }
    }
  }
}

/*職種詳細*/
.recruit_job {
  .big_ttl {
    .text {
      @include g.mq {
        font-size: 16px;
      }
    }
  }

  .about {
    @include g.flexbox;
    @include g.flex-align-items(flex-start);
    @include g.flex-justyfy-content(space-between);
    margin-top: 60px;
    margin-bottom: 150px;

    @include g.mq {
      @include g.flex-flow(column);
      margin-top: 30px;
      margin-bottom: 80px;
    }

    .photo {
      width: 100%;

      img {
        width: 100%;
        border-radius: 100px 0 100px 0;

        @include g.mq(lg) {
          border-radius: 50px 0 50px 0;
        }
      }
    }

    .text {
      width: 100%;
      margin-left: 30px;

      @include g.mq {
        margin-left: 0;
        margin-top: 20px;
      }

      p {
        @include g.mq {
          font-size: 16px;
        }
      }
    }

    h3 {
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 20px;

      @include g.mq {
        font-size: 24px;
      }
    }

    .skill {
      margin-top: 60px;
      padding: 40px;
      background: g.$li-blue02;

      @include g.mq {
        margin-top: 20px;
        padding: 30px 20px;
      }

      h4 {
        font-size: 20px;
        font-weight: bold;
        text-align: center;

        @include g.mq {
          font-size: 18px;
        }
      }

      ul {
        @include g.flexbox;
        @include g.flex-justyfy-content(center);
        margin: 20px auto;

        @include g.mq {
          @include g.flex-justyfy-content(space-evenly);
        }

        li {
          color: g.$ac_color;
          font-weight: bold;
          width: 120px;
          padding: 0 20px;
          word-break: break-all;
          text-align: center;

          @include g.mq {
            width: 30%;
            padding: 0;
          }

          div {
            @include g.flexbox;
            @include g.flex-align-items(center);
            @include g.flex-justyfy-content(center);
            width: 80px;
            height: 77px;
            margin: 0 auto 10px;
            background: g.$ac_color;
            border-radius: 100px;
          }
        }
      }
    }
  }

  .job {
    margin-bottom: 150px;

    @include g.mq {
      margin-bottom: 80px;
    }

    h3 {
      text-align: center;
      font-size: 48px;
      font-weight: bold;
      margin-bottom: 60px;

      @include g.mq {
        font-size: 32px;
        margin-bottom: 20px;
      }
    }

    .text {
      @include g.flexbox;
      @include g.flex-justyfy-content(space-between);
      margin-bottom: 60px;

      @include g.mq {
        @include g.flex-flow(column);
        margin-bottom: 20px;
      }

      h4 {
        font-size: 32px;
        font-weight: bold;
        width: 34%;

        @include g.mq {
          text-align: center;
          font-size: 24px;
          width: 100%;
          margin-bottom: 20px;
        }
      }

      p {
        width: 64%;
        font-size: 18px;

        @include g.mq {
          width: 100%;
          font-size: 16px;
        }
      }
    }

    ul {
      @include g.flexbox;
      @include g.flex-justyfy-content(center);

      @include g.mq(lg) {
        @include g.flex-flow(wrap);
      }

      @include g.mq {
        @include g.flex-flow(column);
      }

      li {
        width: calc((100% - 75px) / 4);
        background: g.$li-blue02;
        border-radius: 50px 0 50px 0;
        text-align: center;
        padding: 30px 20px;
        margin-right: 25px;

        @include g.mq(lg) {
          width: calc((100% - 40px) / 2);
          margin-bottom: 20px;
        }

        @include g.mq {
          @include g.flexbox;
          @include g.flex-align-items(center);
          @include g.flex-justyfy-content(space-between);
          width: 100%;
          margin-right: 0;
          margin-bottom: 10px;
          padding: 20px;
        }

        &:nth-child(2n) {
          @include g.mq(lg) {
            margin-right: 0;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }

      p {
        font-size: 20px;
        font-weight: bold;
        margin-top: 20px;
        color: g.$ac_color;

        @include g.mq {
          font-size: 16px;
          margin-top: 0;
          width: calc(100% - 110px);
        }
      }

      span {
        font-size: 16px;

        @include g.mq {
          font-size: 14px;
        }
      }

      img {
        @include g.mq {
          width: 100px;
        }
      }
    }
  }

  .yell {
    margin-bottom: 150px;
    background: g.$li-blue02;
    border-radius: 100px 0 100px 0;
    padding: 100px 100px 60px;

    @include g.mq(lg) {
      padding: 80px 80px 50px;
    }

    @include g.mq {
      margin-bottom: 80px;
      border-radius: 50px 0 50px 0;
      padding: 30px 20px;
    }

    h3 {
      text-align: center;
      font-size: 48px;
      font-weight: bold;
      margin-bottom: 60px;

      @include g.mq {
        font-size: 32px;
        margin-bottom: 20px;
      }
    }

    h4 {
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 20px;

      @include g.mq {
        font-size: 20px;
      }
    }

    p {
      @include g.mq {
        font-size: 16px;
      }
    }

    .photo {
      width: 100%;
      height: 400px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center top;
      border-radius: 100px 0 100px 0;
      margin-bottom: 20px;

      @include g.mq(lg) {
        height: 350px;
      }

      @include g.mq {
        height: 300px;
        border-radius: 50px 0 50px 0;
      }

      @include g.mq(sm) {
        height: 200px;
      }

      &.sales {
        background-image: url("../img/recruit/sales/sales02.jpg");

        @include g.mq {
          background-image: url("../img/recruit/sales/sales02_sp.jpg");
        }
      }

      &.manager {
        background-image: url("../img/recruit/manager/manager02.jpg");

        @include g.mq {
          background-image: url("../img/recruit/manager/manager02_sp.jpg");
        }
      }

      &.coordinator {
        background-image: url("../img/recruit/coordinator/coordinator02.jpg");

        @include g.mq {
          background-image: url("../img/recruit/coordinator/coordinator02_sp.jpg");
        }
      }

      &.assistant {
        background-image: url("../img/recruit/assistant/assistant02.jpg");

        @include g.mq {
          background-image: url("../img/recruit/assistant/assistant02_sp.jpg");
        }
      }

      &.hrga {
        background-image: url("../img/recruit/hrga/hrga02.jpg");

        @include g.mq {
          background-image: url("../img/recruit/hrga/hrga02_sp.jpg");
        }
      }
    }
  }

  .description {
    margin-bottom: 80px;

    @include g.mq {
      margin-bottom: 60px;
    }

    h3 {
      text-align: center;
      font-size: 48px;
      font-weight: bold;
      margin-bottom: 60px;

      @include g.mq {
        font-size: 32px;
        margin-bottom: 20px;
      }
    }

    > p {
      width: 80%;
      max-width: 800px;
      margin: 0 auto 60px;
      font-size: 18px;

      @include g.mq {
        width: 100%;
        font-size: 16px;
        margin-bottom: 30px;
      }
    }

    table {
      width: 100%;
      max-width: 1000px;
      margin: 0 auto;

      th,
      td {
        padding: 30px 40px;

        @include g.mq {
          display: block;
          width: 100%;
          font-size: 18px;
          padding: 0 5px;
        }
      }

      th {
        font-weight: bold;
        border-bottom: solid 1px g.$ac_color;

        @include g.mq {
          border-bottom: none;
        }
      }

      td {
        border-bottom: solid 1px g.$gray;
        margin-bottom: 20px;
        position: relative;

        @include g.mq {
          padding: 5px 5px 20px;
        }

        &:before {
          @include g.mq {
            content: "";
            display: block;
            width: 100px;
            height: 1px;
            background: g.$ac_color;
            position: absolute;
            left: 0;
            bottom: -1px;
          }
        }
      }

      tr {
        &:first-of-type {
          th {
            border-top: solid 1px g.$ac_color;

            @include g.mq {
              border-top: none;
            }
          }

          td {
            border-top: solid 1px g.$gray;

            @include g.mq {
              border-top: none;
            }
          }
        }
      }
    }
  }

  .entry {
    display: block;
    background: url("../img/recruit/entry_bg.jpg") no-repeat center center /
        cover,
      g.$bg_blue;
    position: relative;
    margin-bottom: 80px;

    @include g.mq {
      background: url("../img/recruit/entry_bg_sp.jpg") no-repeat center center /
          cover,
        g.$bg_blue;
    }

    > div {
      text-align: center;
      padding: 80px 40px;
      width: 100%;
      height: 100%;
      background: rgba(g.$bg_blue, 0.9);
      transition: g.$link_time;

      @include g.mq {
        padding: 100px 40px;
      }

      p {
        font-size: unquote("clamp(60px, 18.0vw, 120px)");
        color: #fff;
        font-weight: bold;
        line-height: 1.4;
      }

      .btn {
        display: inline-block;
        font-size: 20px;
        color: g.$bg_blue;
        background-color: #fff;
        border-radius: 60px;
        padding: 18px 80px 18px 90px;
        position: relative;
        transition: g.$link_time;

        @include g.mq(sm) {
          padding: 18px 55px 18px 65px;
        }

        &:after {
          content: "\f061";
          font-family: "font awesome 6 free";
          font-weight: 900;
          padding-left: 0.8em;
          font-size: 18px;
        }
      }

      &:hover {
        background: rgba(g.$bg_blue, 0.7);

        .btn {
          color: #fff;
          background-color: g.$base_color;
        }
      }
    }
  }
}
