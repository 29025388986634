@charset "utf-8";

@use "global" as g;

.blog_page {
  max-width: 960px;
  margin: 0 auto;
}

.blog_prof {
  margin-top: 40px;

  .prof_top {
    @include g.flexbox;
    @include g.flex-align-items(center);
  }

  .cate {
    color: #fff;
    background-color: g.$btn_color;
    font-size: 12px;
    font-weight: 700;
    padding: 3px 10px;
    border-radius: 10px 0 10px 0;
  }

  .date {
    margin-left: 10px;
    color: g.$gray;
    font-size: 20px;

    @include g.mq {
      font-size: 16px;
    }
  }

  .photo {
    margin-top: 10px;
  }

  .outline {
    margin-top: 40px;
    background-color: g.$li-blue02;
    padding: 30px 40px;
    border-radius: 50px 0 50px 0;

    @include g.mq {
      margin-top: 20px;
      padding: 30px 20px;
    }

    .ttl {
      font-size: 24px;

      @include g.mq {
        font-size: 18px;
      }
    }

    .list {
      margin-top: 15px;
      counter-reset: number 0;

      > li {
        font-size: 16px;

        &::before {
          counter-increment: number 1;
          content: counter(number) ".";
          padding-right: 1em;
        }

        &:nth-child(n + 2) {
          margin-top: 5px;
        }
      }
    }
  }
}

.blog_content {
  margin: 40px 0 100px;

  @include g.mq {
    margin-bottom: 60px;
  }

  #toc_container {
    margin-top: 40px;
    margin-bottom: 40px;
    background-color: g.$li-blue02;
    padding: 30px 40px;
    border-radius: 50px 0 50px 0;

    @include g.mq {
      margin-top: 20px;
      padding: 30px 20px;
    }

    .toc_title {
      font-size: 24px;

      @include g.mq {
        font-size: 18px;
      }
    }

    .toc_list {
      margin-top: 15px;
      counter-reset: number 0;
      padding: 0;
      line-height: 2;

      ul {
        margin: 0;
        padding: 0;
      }

      li {
        font-size: 16px;
        padding: 0;
        font-weight: 500;

        &::before {
          display: none;
        }

        &:nth-child(n + 2) {
          margin-top: 5px;
        }

        span {
          padding-right: 1em;

          &::after {
            content: ".";
          }
        }

        ul {
          padding-left: 1em;
        }
      }
    }
  }

  h2 {
    margin-bottom: 15px;
    padding-bottom: 15px;
    font-size: 32px;
    border-bottom: solid 1px g.$gray;
    position: relative;

    @include g.mq {
      font-size: 24px;
    }

    &::after {
      content: "";
      display: block;
      width: 168px;
      height: 1px;
      background-color: g.$ac_color;
      position: absolute;
      left: 0;
      bottom: -1px;

      @include g.mq {
        width: 100px;
      }
    }

    &:nth-child(n + 3) {
      margin-top: 60px;
    }
  }

  h3 {
    margin-top: 40px;
    font-size: 24px;
    font-weight: 700;
    color: g.$ac_color;

    @include g.mq {
      font-size: 18px;
    }
  }

  h4 {
    margin-top: 20px;
    padding-bottom: 10px;
    color: g.$ac_color; /*文字色*/
    border-bottom: dashed 2px g.$ac_color;
    font-size: 20px;

    @include g.mq {
      font-size: 16px;
    }
  }

  p {
    margin-top: 10px;
    line-height: 2.2;
  }
  strong {
    color: g.$ac_color;
  }

  blockquote {
    margin-top: 40px;
    padding: 70px 80px 70px;
    background-color: g.$gray05;
    border-radius: 50px 0 50px 0;
    color: g.$gray;
    position: relative;
    font-size: 16px;

    @include g.mq {
      padding: 80px 30px 80px;
    }

    &::before,
    &::after {
      position: absolute;
      font-size: 80px;
      font-family: "Noto Sans JP", "游明朝", "Yu Mincho", YuMincho, "Shippori Mincho", serif, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Noto Serif JP", sans-serif;
      font-weight: 700;
    }

    &::before {
      content: "\0201C";
      top: 0px;
      left: 30px;
    }

    &::after {
      content: "\0201D";
      bottom: -20px;
      right: 30px;
    }

    p:last-child {
      position: relative;
      z-index: 1;
      color: g.$gray;

      &::before {
        content: "出典：";
      }

      a {
        color: g.$btn_color;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  ul {
    margin-top: 40px;
    background-color: g.$li-blue02;
    padding: 40px;
    border-radius: 50px 0 50px 0;

    @include g.mq {
      padding: 30px 20px;
    }

    li {
      padding-left: 20px;
      position: relative;
      font-size: 18px;

      &::before {
        position: absolute;
        display: inline-block;
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: g.$ac_color;
        top: 8px;
        left: 0;
      }

      & + li {
        margin-top: 10px;
      }

      &:first-child {
        font-size: 24px;
        font-weight: 700;
        padding: 10px 0;

        &::before {
          display: none;
        }
      }
    }
  }

  div:has(table) {
    @include g.mq {
      width: 100%;
      overflow-x: scroll;
    }
  }

  table {
    margin-top: 40px;
    width: 100%;
    text-align: center;
    border-collapse: collapse;

    @include g.mq {
      width: 800px;
    }

    tr {
      &:first-child {
        th {
          background-color: g.$btn_color;
          color: #fff;
        }
      }
    }

    th,
    td {
      padding: 20px;
      text-align: center;
      border: solid 1px #fff;
      font-size: 16px;
    }

    th {
      background-color: g.$bg_blue;
      color: #fff;
    }

    td {
      background-color: g.$li-blue02;
    }
  }

  .profile {
    border: solid 1px g.$gray06;
    position: relative;
    @include g.flexbox;
    margin-top: 40px;
    padding: 50px 30px 30px;
    font-size: 16px;

    @include g.mq {
      @include g.flex-flow(wrap);
      padding: 50px 20px 25px;
    }

    .ttl {
      position: absolute;
      top: 0;
      left: 0;
      background-color: g.$btn_color;
      color: #fff;
      padding: 5px 30px;
      border-radius: 0 0 20px 0;
      font-weight: 700;
    }

    .photo {
      width: 150px;
      padding-right: 30px;

      @include g.mq {
        width: 100%;
        padding-right: 0px;
        padding-bottom: 15px;
        text-align: center;
      }

      img {
        border-radius: 50%;
      }
    }

    .text {
      width: calc(100% - 150px);

      @include g.mq {
        width: 100%;
      }
    }

    .name {
      font-weight: 700;

      @include g.mq {
        text-align: center;
      }
    }
  }

  .relate_article {
    .ttl {
      margin-top: 50px;
      margin-bottom: 30px;
      padding-bottom: 15px;
      font-size: 32px;
      font-weight: 700;
      border-bottom: solid 1px g.$gray;
      position: relative;

      @include g.mq {
        font-size: 24px;
        margin-bottom: 20px;
        padding-bottom: 10px;
      }

      &::after {
        content: "";
        display: block;
        width: 168px;
        height: 1px;
        background-color: g.$ac_color;
        position: absolute;
        left: 0;
        bottom: -1px;

        @include g.mq {
          width: 100px;
        }
      }
    }

    a {
      @include g.flexbox;
      @include g.flex-align-items(center);
      background-color: #fff;
      padding: 30px;
      box-shadow: 0 0 20px 0px rgba($color: #000000, $alpha: 0.1);

      @include g.mq {
        @include g.flex-flow(wrap);
        padding: 20px;
      }

      picture {
        width: 230px;

        @include g.mq {
          width: 100%;
          text-align: center;
        }

        img {
          @include g.mq {
            width: 100%;
            max-width: 460px;
          }
        }
      }

      p {
        margin: 0;
        width: calc(100% - 230px);
        padding-left: 30px;
        font-size: 18px;
        font-weight: 700;

        @include g.mq {
          width: 100%;
          padding-left: 0px;
          padding-top: 15px;
        }
      }

      & + a {
        margin-top: 20px;
      }
    }
  }

  .internal_link {
    display: block;
    margin: 40px 0;
    .blog_card {
      padding: 20px;
      border: solid 1px g.$base_color;
      display: flex;
      align-items: center;
      position: relative;
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1), 0 12px 28px -12px rgba(0, 0, 0, 0.05);

      @include g.mq {
        padding: 20px 10px;
      }
    }
    .copy {
      padding: 0 5px;
      position: absolute;
      font-size: 12px;
      left: 20px;
      top: -10px;
      background-color: g.$bg_color;
      @include g.mq {
        font-size: 10px;
        left: 10px;
      }
      &::before {
        content: "\f00c";
        font-family: "font awesome 6 free";
        font-weight: 900;
      }
    }
    .photo {
      width: 25%;
      @include g.mq {
        width: 30%;
      }
    }
    .text {
      width: 75%;
      padding-left: 10px;
      @include g.mq {
        width: 70%;
      }
    }
    .ttl {
      @include g.mq {
        font-size: 13px;
      }
    }
    .cont {
      margin-top: 10px;
      font-size: 12px;

      @include g.mq {
        display: none;
      }
    }
  }

  .seminar_box,
  .download_box {
    margin-top: 75px;
    padding: 50px 30px 40px;
    border: solid 1px g.$ac_color;
    position: relative;

    @include g.mq {
      padding: 40px 20px 30px;
    }

    .catch {
      padding: 0 40px;
      position: absolute;
      top: -25px;
      left: -1px;
      font-size: 20px;
      font-weight: 700;
      line-height: 2.5;
      background-color: g.$ac_color;
      color: #fff;
      border-radius: 20px 0 20px 0;

      @include g.mq {
        padding: 0 20px;
        font-size: 16px;
      }
    }

    .ttl {
      font-size: 32px;
      font-weight: 700;
      color: g.$ac_color;
      text-align: center;

      @include g.mq {
        font-size: 24px;
      }
    }

    .text {
      margin-top: 5px;
      text-align: center;
      font-size: 16px;

      @include g.mq {
        margin-top: 15px;
        line-height: 1.7;
      }
    }

    .link {
      margin-top: 40px;

      @include g.mq {
        margin-top: 25px;
      }

      a {
        padding: 15px;
        margin: 0 auto;
        display: block;
        max-width: 340px;
        width: 100%;
        background-color: g.$base_color;
        color: #fff;
        border: solid 1px g.$gray06;
        text-align: center;
        border-radius: 60px;
        position: relative;

        @include g.mq {
          font-size: 15px;
        }

        .baloon {
          position: absolute;
          top: -20px;
          left: 20px;
          background-color: g.$yellow;
          color: g.$base_color;
          width: 50px;
          line-height: 50px;
          text-align: center;
          font-weight: 700;
          border-radius: 50%;

          @include g.mq {
            width: 45px;
            line-height: 45px;
          }
        }

        p {
          margin: 0;
        }

        &::after {
          position: absolute;
          top: 50%;
          right: 20px;
          content: "\f061";
          font-family: "font awesome 6 free";
          font-weight: 900;
          font-size: 15px;
          transform: translateY(-50%);
        }

        &:hover {
          background-color: g.$btn_color;
        }
      }
    }
  }

  .download_box {
    border-color: g.$btn_color;

    .catch {
      background-color: g.$btn_color;
    }

    .ttl {
      color: g.$btn_color;
    }
  }

  .mail_box {
    margin-top: 40px;
    padding: 50px 40px;
    background-color: g.$li-blue02;
    border-radius: 50px 0 50px 0;

    @include g.mq {
      margin-top: 25px;
      padding: 30px 15px 40px;
    }

    .ttl {
      margin-bottom: 20px;
      font-size: 32px;
      color: g.$ac_color;
      text-align: center;
      font-weight: 700;

      @include g.mq {
        font-size: 24px;
      }
    }
    .text {
      text-align: center;
      font-size: 16px;
    }

    form {
      margin-top: 35px;
    }

    .form_box {
      &:nth-child(n + 2) {
        margin-top: 20px;
      }
      label {
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 700;
        display: flex;
        align-items: flex-end;
        span {
          margin-left: 20px;
          padding: 4px 15px;
          display: inline-block;
          background-color: g.$ac_color;
          color: #fff;
          font-size: 14px;
          border-radius: 5px;
        }
      }

      input {
        padding: 15px 20px;
        width: 100%;
        background-color: #fff;
        border: solid 1px g.$gray06;
        border-radius: 5px;
        font-size: 16px;
      }
    }

    .policy {
      margin-top: 20px;
      p {
        font-weight: 700;
        font-size: 16px;
        line-height: 1.5;
        a {
          color: g.$ac_color;
          text-decoration: underline;
          text-underline-offset: 0.1em;

          &:hover {
            text-decoration: none;
          }
        }
      }
      input {
        width: 20px;
        height: 20px;
        margin: 0 0.5em;
      }
      label {
        margin-top: 10px;
        display: flex;
        align-items: center;
        font-size: 16px;

        @include g.mq {
          align-items: flex-start;
        }
      }
    }

    .btn {
      margin-top: 40px;

      @include g.mq {
        margin-top: 25px;
      }

      button {
        padding: 15px;
        margin: 0 auto;
        display: block;
        max-width: 340px;
        width: 100%;
        background-color: g.$base_color;
        color: #fff;
        border: solid 1px g.$gray06;
        text-align: center;
        border-radius: 60px;
        position: relative;

        @include g.mq {
          font-size: 15px;
        }

        .baloon {
          position: absolute;
          top: -20px;
          left: 20px;
          background-color: g.$yellow;
          color: g.$base_color;
          width: 50px;
          line-height: 50px;
          text-align: center;
          font-weight: 700;
          border-radius: 50%;

          @include g.mq {
            width: 45px;
            line-height: 45px;
          }
        }

        p {
          margin: 0;
        }

        &::after {
          position: absolute;
          top: 50%;
          right: 20px;
          content: "\f061";
          font-family: "font awesome 6 free";
          font-weight: 900;
          font-size: 15px;
          transform: translateY(-50%);
        }

        &:hover {
          background-color: g.$btn_color;
        }
      }
    }
  }
}

.blog_relate {
  padding-bottom: 100px;
  h2 {
    text-align: center;
    font-size: 24px;
  }
}

.blog_fix_bnr {
  padding-top: 152px;

  @include g.mq(lg) {
    padding-top: 16vw;
  }

  .bnr {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 100%;
    max-width: 1000px;
    z-index: 1;
    transition: 0.5s all;

    .close {
      position: absolute;
      font-size: 20px;
      font-weight: 900;
      color: #fff;
      background-color: #ccc;
      width: 3%;
      aspect-ratio: 1/1;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 5px;
      right: 5px;
      z-index: 2;
      cursor: pointer;

      @include g.mq {
        width: 5%;
        font-size: 10px;
        top: 2px;
        right: 2px;
      }

      &:hover {
        background-color: #aaa;
      }
    }

    a {
      display: block;
      position: relative;
      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgba($color: #fff, $alpha: 0.3);
        z-index: 1;
        opacity: 0;
      }

      &:hover {
        img {
          opacity: 1;
        }
        &::before {
          opacity: 1;
        }
      }
    }
  }

  &.active {
    .bnr {
      opacity: 0;
      visibility: hidden;
      transform: translateX(-50%) translateY(50%);
    }
  }
}
// バナーと被るため
.grecaptcha-badge {
  @include g.mq {
    bottom: 120px !important;
  }

  @include g.mq(sm) {
    bottom: 65px !important;
  }
}
