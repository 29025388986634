@charset "utf-8";

@use "global" as g;

//共通
.kaso_page {
  margin: 80px 0 0;

  @include g.mq {
    margin: 40px 0 0;
  }
}

.bread {
  position: relative;
  z-index: 5;

  ul {
    @include g.flexbox;
    @include g.flex-flow(wrap);
  }

  li {
    font-size: 13px;
    color: g.$gray;
    position: relative;

    &:not(:last-child) {
      padding-right: 20px;

      &::after {
        content: "/";
        position: absolute;
        right: 10px;
      }
    }

    &:only-child {
      &::after {
        content: none;
      }
    }
  }

  a {
    color: g.$ac_color;
    white-space: nowrap;
  }
}

.big_ttl {
  .ttl {
    position: relative;
    text-align: center;
    margin-top: 30px;
    padding: 70px 0 40px;

    @include g.mq {
      padding: 20px 0;
    }
  }

  h1,
  h2,
  h3 {
    font-size: 48px;
    font-weight: 700;

    @include g.mq {
      font-size: 32px;
    }

    span {
      color: g.$ac_color;
    }

    .pc {
      display: inline;
      color: g.$base_color;

      @include g.mq {
        display: none;
      }
    }

    &.fs32 {
      color: g.$base_color;
      font-size: 32px;

      @include g.mq {
        font-size: 16px;
      }

      span {
        font-size: 48px;

        @include g.mq {
          font-size: 32px;
        }
      }
    }
  }
  h1 {
    .sub-ttl {
      display: block;
      font-size: 20px;
      color: #212121;
      @include g.mq {
        font-size: 15px;
      }
    }
  }

  .en {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 200px;
    font-weight: 700;
    opacity: 0.1;
    white-space: nowrap;

    @include g.mq {
      font-size: 77px;
    }
  }

  .text {
    max-width: 800px;
    margin: 0 auto;
    font-size: 18px;

    &_w1000 {
      max-width: 1000px;
      margin: 0 auto;
      font-size: 16px;
    }
  }

  .blue40 {
    margin-top: 50px;
    text-align: center;
    font-size: 40px;
    color: g.$ac_color;
    font-weight: 700;

    @include g.mq {
      margin-top: 20px;
      font-size: 24px;
    }
  }
}

.kaso_fv {
  margin-top: 80px;

  @include g.mq {
    margin-top: 40px;
  }

  .photo {
    height: 400px;
    border-radius: 100px 0 100px 0;
    background: center center no-repeat;
    background-size: cover;

    @include g.mq {
      height: 200px;
      border-radius: 50px 0 50px 0;
      margin: 40px calc(50% - 50vw) 0;
    }

    &.product-dispatch {
      background-image: url(../img/service/product-dispatch/fv.jpg);

      @include g.mq {
        background-image: url(../img/service/product-dispatch/fv_sp.jpg);
      }
    }

    &.team {
      background-image: url(../img/service/team/fv.jpg);

      @include g.mq {
        background-image: url(../img/service/team/fv_sp.jpg);
      }
    }

    &.specified-skill {
      background-image: url(../img/service/specified-skill/fv.jpg);

      @include g.mq {
        background-image: url(../img/service/specified-skill/fv_sp.jpg);
      }
    }

    &.dormitory {
      background-image: url(../img/service/dormitory/fv.jpg);

      @include g.mq {
        background-image: url(../img/service/dormitory/fv_sp.jpg);
      }
    }

    &.manufacturing-contract {
      background-image: url(../img/service/manufacturing-contract/fv.jpg);

      @include g.mq {
        background-image: url(../img/service/manufacturing-contract/fv_sp.jpg);
      }
    }

    &.internship {
      background-image: url(../img/service/internship/fv.jpg);

      @include g.mq {
        background-image: url(../img/service/internship/fv_sp.jpg);
      }
    }

    &.support {
      background-image: url(../img/service/support/fv.jpg);

      @include g.mq {
        background-image: url(../img/service/support/fv_sp.jpg);
      }
    }

    &.philosophy {
      background-image: url(../img/info/philosophy/fv.png);

      @include g.mq {
        background-image: url(../img/info/philosophy/fv_sp.png);
      }
    }

    &.compliance {
      background-image: url(../img/info/compliance/fv.jpg);

      @include g.mq {
        background-image: url(../img/info/compliance/fv_sp.jpg);
      }
    }

    &.company {
      overflow: hidden;
      height: auto;
      // background-image: url(../img/info/company/fv.jpg);

      @include g.mq {
        // background-image: url(../img/info/company/fv_sp.jpg);
        width: 100%;
        margin: 0 auto;
      }
    }

    &.access {
      background-image: url(../img/info/access/fv.jpg);

      @include g.mq {
        background-image: url(../img/info/access/fv_sp.jpg);
      }
    }

    &.r_sales {
      background-image: url(../img/recruit/sales/fv.jpg);

      @include g.mq {
        background-image: url(../img/recruit/sales/fv_sp.jpg);
      }
    }

    &.r_manager {
      background-image: url(../img/recruit/manager/fv.jpg);

      @include g.mq {
        background-image: url(../img/recruit/manager/fv_sp.jpg);
      }
    }

    &.r_coordinator {
      background-image: url(../img/recruit/coordinator/fv.jpg);

      @include g.mq {
        background-image: url(../img/recruit/coordinator/fv_sp.jpg);
      }
    }

    &.r_assistant {
      background-image: url(../img/recruit/assistant/fv.jpg);

      @include g.mq {
        background-image: url(../img/recruit/assistant/fv_sp.jpg);
      }
    }

    &.r_hrga {
      background-image: url(../img/recruit/hrga/fv.jpg);

      @include g.mq {
        background-image: url(../img/recruit/hrga/fv_sp.jpg);
      }
    }
    &.photo404 {
      background-image: url(../img/404/fv.jpg);
      @include g.mq {
        background-image: url(../img/404/fv_sp.jpg);
      }
    }
  }
}

.archive_sec,
.relate_page {
  margin: 60px 0 100px;

  @include g.mq {
    margin: 40px 0 60px;
  }

  .type_list {
    .desc {
      font-size: 16px;
      font-weight: 700;
      text-align: center;
    }

    .list {
      margin: 20px auto 0;
      //max-width: 670px;
      max-width: 790px;
      @include g.flexbox;
      @include g.flex-justyfy-content(flex-start);
      @include g.flex-align-items(center);
      @include g.flex-flow(wrap);

      .box {
        width: calc((100% - 10px * 3) / 3);

        @include g.mq {
          width: calc((100% - 14px) / 2);
        }

        &:not(:nth-child(3n)) {
          margin-right: 10px;

          @include g.mq {
            margin-right: 0px;
          }
        }

        &:not(:nth-child(2n)) {
          @include g.mq {
            margin-right: 14px;
          }
        }

        &:nth-child(n + 4) {
          margin-top: 20px;
        }

        &:nth-child(n + 3) {
          @include g.mq {
            margin-top: 10px;
          }
        }
      }

      input {
        display: none;
      }

      label {
        display: block;
        background-color: g.$ac_color;
        color: #fff;
        text-align: center;
        padding: 10px;
        border-radius: 44px;
        font-size: 15px;
        font-weight: 700;
      }

      input[type="checkbox"]:checked + label,
      input[type="radio"]:checked + label {
        background-color: g.$li-gray;
        color: g.$base_color;
      }
    }
  }

  .page_list {
    margin-top: 10px;

    @include g.mq {
      margin-top: 40px;
    }

    .list {
      @include g.flexbox;
      @include g.flex-justyfy-content(space-between);
      @include g.flex-flow(wrap);
      @include g.flex-align-items(stretch);

      &::after {
        content: "";
        display: block;
        width: calc((100% - 30px * 2) / 3);
      }
    }

    .box {
      margin-top: 40px;
      width: calc((100% - 30px * 2) / 3);
      position: relative;

      @include g.mq {
        margin-top: 10px;
        width: 100%;
      }

      //&:nth-child(n + 4) {
      //  margin-top: 38px;
      //}

      &:nth-child(n + 2) {
        @include g.mq {
          margin-top: 20px;
        }
      }

      > a {
        height: 100%;

        &:hover {
          .ttl {
            color: g.$ac_color;
          }
        }
      }

      .photo {
        aspect-ratio: 16/9;

        img {
          height: 100%;
          object-fit: cover;
          object-position: center center;
        }
      }

      &.not_exist {
        text-align: center;
        width: 100%;
      }
    }
    .tag {
      position: absolute;
      top: 10px;
      left: 10px;
      border: solid 2px g.$ac_color;
      background-color: #fff;
      color: g.$ac_color;
      font-size: 14px;
      padding: 2px 20px;
      border-radius: 29px;
    }

    .tag_wrap {
      position: absolute;
      top: 10px;
      left: 10px;

      .tag {
        position: relative;
        width: fit-content;
        margin-bottom: 5px;
        top: 0;
        left: 0;
      }
    }

    .area-tag {
      display: inline-block;
      .tag {
        position: static;
        border: none;
        display: inline-block;
        margin: 10px 0 5px;
        color: #fff;
        background-color: #004898;
        font-size: 12px;
        font-weight: 700;
        padding: 3px 10px;
        border-radius: 10px 0 10px 0;
      }
    }

    .cate {
      display: inline-block;
      margin-top: 10px;
      padding: 3px 10px;
      font-size: 12px;
      color: #fff;
      background-color: g.$btn_color;
      font-weight: 700;
      border-radius: 10px 0 10px 0;
    }

    .ttl {
      margin-top: 15px;
      transition: g.$link_time;
      font-size: 18px;

      &.blog {
        margin-top: 5px;
      }
    }

    .desc {
      margin-top: 10px;
      font-size: 16px;
      font-weight: 400;
    }

    .industry {
      margin-top: 15px;
      @include g.flexbox;
      font-size: 14px;

      dt {
        width: 110px;
        color: g.$btn_color;
        font-weight: 700;
      }

      dd {
        width: calc(100% - 110px);
      }
    }

    .date {
      margin-top: 5px;
      font-size: 14px;
      color: g.$gray;
    }

    .pager {
      margin-top: 90px;

      @include g.mq {
        margin-top: 50px;
      }

      .screen-reader-text {
        font-size: 0;
      }
      .nav-links {
        .page-numbers {
          @include g.mq {
            margin: 0 10px;
          }
        }
      }

      .page-numbers {
        @include g.flexbox;
        @include g.flex-justyfy-content(center);
        @include g.flex-align-items(center);
        > li {
          margin: 0 15px;
          font-size: 24px;
          font-weight: 700;
          color: g.$gray;
          @include g.mq {
            margin: 0 5px;
          }

          a {
            &:hover {
              opacity: 0.7;
            }
          }

          .prev,
          .next {
            font-size: 20px;
          }

          .current {
            color: g.$ac_color;
          }
        }
      }
      &-right {
        .nav-links {
          justify-content: flex-end;
        }
        .page-numbers {
          margin: 0;
          & > li {
            margin: 0 10px;
          }
        }
      }
    }

    &.download {
      .box {
        > a {
          display: block;
          background-color: g.$li-blue02;
          padding: 30px 20px;
          border-radius: 50px 0 50px 0;

          .photo {
            img {
              height: 170px;
              width: auto;
            }
          }

          picture {
            max-width: 90%;
            margin: 0px auto 0;
          }

          &:hover {
            background-color: g.$li-blue03;

            .ttl {
              color: g.$base_color;
            }
          }
        }
      }

      .tag {
        top: 30px;
        left: 20px;
      }

      .photo {
        margin-top: 50px;
        //height: 180px;
        @include g.flexbox;
        @include g.flex-align-items(center);
        @include g.flex-justyfy-content(center);
      }

      .ttl {
        margin-top: 10px;
      }
    }

    &.news {
      max-width: 1000px;
      margin: -20px auto 0;

      > ul {
        a {
          @include g.flexbox;
          @include g.flex-align-items(stretch);
          @include g.flex-justyfy-content(flex-start);
          border-bottom: 1px solid g.$gray;
          border-image: linear-gradient(to right, g.$ac_color 100px, g.$gray 100px);
          border-image-slice: 1;

          &:hover {
            opacity: 0.8;
          }
        }

        .u_line {
          &:after {
            bottom: -1px;
          }
        }

        .date,
        .ttl {
          padding: 20px 0;
          font-size: 16px;
          margin-top: 0;
        }

        .date {
          width: 100px;
          font-weight: bold;
        }

        .ttl {
          width: calc(100% - 100px);
        }
      }
    }

    #loading {
      text-align: center;
      font-size: 30px;
      color: #ccc;
    }
  }

  .news_detail {
    max-width: 860px;
    margin: 0 auto 100px;

    @include g.mq {
      margin: 0 auto 60px;
    }

    .date {
      font-size: 20px;
      color: g.$gray;

      @include g.mq {
        font-size: 16px;
      }
    }

    img {
      width: 100%;
      margin: 10px 0;
    }

    p {
      margin-bottom: 20px;
      font-size: 16px;
    }

    a {
      color: g.$ac_color;
    }

    .right_text {
      width: fit-content;
      margin-left: auto;

      @include g.mq {
        width: 100%;
      }
    }
  }

  .link {
    margin: 40px 0 0;
    text-align: center;
  }
}

.loading_body {
  .type_list {
    li {
      pointer-events: none;
    }
  }

  .page-numbers {
    li a {
      pointer-events: none;
    }
  }
}

.relate_page {
  .relate_ttl {
    font-size: 24px;
    font-weight: 700;
    text-align: center;

    @include g.mq {
      font-size: 18px;
    }
  }

  .page_list {
    margin-top: 35px;

    @include g.mq {
      margin-top: 20px;
    }
  }
}

.privacy,
.terms {
  max-width: 1000px;
  margin: 0 auto;

  > div {
    margin-bottom: 60px;
  }

  h3,
  h2 {
    font-size: 40px;
    text-align: center;
    font-weight: bold;
    color: g.$ac_color;
    margin-bottom: 20px;

    @include g.mq {
      font-size: 24px;
    }
  }

  p,
  li {
    font-size: 16px;
  }

  ol {
    margin-left: 1.5rem;
    margin-top: 30px;

    @include g.mq {
      margin-left: 1.8rem;
    }

    li {
      list-style-type: decimal;
    }

    &.ol_space {
      li {
        margin-bottom: 20px;
      }
    }
  }

  a {
    color: g.$btn_color;
    text-decoration: underline;

    &:hover {
      opacity: 0.8;
    }
  }

  .right_text {
    width: fit-content;
    margin-left: auto;
  }

  .info_box {
    padding: 30px 20px;
    margin: 20px 0;
    background: #fff;
    border: solid 1px g.$gray;
  }
}

.terms {
  h3 {
    margin-bottom: 40px;

    @include g.mq {
      margin-bottom: 20px;
    }
  }

  ol {
    margin-top: 0;
  }

  li {
    padding-left: 15px;

    @include g.mq {
      padding-left: 10px;
    }

    ol {
      li {
        list-style-type: lower-alpha;
      }
    }
  }
}
