@charset "utf-8";

@use "global" as g;

.tel-box {
  width: 66.66%;
  margin: 60px auto 0;
  padding: 30px;
  background-color: #e1e5e6;
  text-align: center;
  @include g.mq(lg) {
    width: 100%;
  }
  @include g.mq(md) {
    margin-top: 30px;
    padding: 20px;
  }
}
.tel-box h3 {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
  @include g.mq(md) {
    font-size: 16px;
  }
}
.tel-box .txt {
  @include g.mq(md) {
    text-align: left;
    width: calc(212 / 295 * 100%);
    margin: 0 0 0 auto;
  }
  @include g.mq(sm-s) {
    width: 84%;
  }
}
.tel-box .txt br {
  display: none;
  @include g.mq(md) {
    display: block;
  }
}
.tel-box .tel-no {
  font-size: 32px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-wrap: wrap;
}
.tel-box .tel-no a {
  @include g.mq(md) {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
.tel-box .tel-no .icon {
  display: inline-block;
  width: 40px;
  margin-right: 5px;
  @include g.mq(sm-s) {
    width: 30px;
  }
}
.tel-box .tel-no .txt {
  @include g.mq(md) {
    width: calc(212 / 295 * 100%);
    margin: 0;
  }
  @include g.mq(sm-s) {
    width: 84%;
  }
}
.tel-box .tel-no .time {
  font-size: 14px;
  margin-left: 15px;
  @include g.mq(md) {
    width: calc(212 / 295 * 100%);
    margin: 0 0 0 auto;
    text-align: left;
  }
  @include g.mq(sm-s) {
    width: 84%;
  }
}

.inquiry {
  margin-top: 80px;
  padding: 100px 0;
  background-color: g.$li-blue02;

  @include g.mq {
    margin-top: 40px;
    padding: 60px 0 80px;
  }

  .ttl {
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;

    @include g.mq {
      font-size: 24px;
    }
  }

  .text {
    text-align: center;
    font-size: 18px;
    margin-bottom: 40px;

    @include g.mq {
      font-size: 16px;
      margin-bottom: 20px;
    }
  }

  #tab {
    text-align: center;

    label {
      @include g.flex-justyfy-content(center);
      font-size: 16px;
    }

    ul {
      @include g.flexbox;
      @include g.flex-justyfy-content(center);
      border: solid 1px #9b9b9b;
      border-radius: 50px;
      width: 100%;
      max-width: 420px;
      margin: 20px auto 40px;
      position: relative;
    }

    li {
      width: 100%;
      z-index: 1;
    }

    input[type="radio"] {
      display: none;
    }

    label {
      margin-top: 0;
      transition: g.$link_time;
    }

    a,
    label {
      display: block;
      font-size: 15px;
      font-weight: bold;
      text-align: center;
      width: 100%;
      padding: 8px;
      color: #9b9b9b;

      &.selected {
        color: #fff;
        //background: g.$ac_color;
        //border-radius: 50px;
      }
    }

    .select_lamp {
      display: block;
      width: 50%;
      height: 40px;
      background: g.$ac_color;
      border-radius: 50px;
      position: absolute;
      z-index: 0;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      transition: all 0.3s ease-in;

      &.form_corporate {
        left: 0;
      }

      &.form_individual {
        left: 100%;
        transform: translate(-100%, -50%);
      }
    }
  }

  .form_wrap {
    background-color: #fff;
    border-radius: 100px 0 100px 0;
    padding: 100px 20px;
    text-align: center;
    margin: 60px 0 50px;

    @include g.mq {
      border-radius: 25px;
      padding: 10px 20px 30px;
      margin: 30px 0 0;
      box-shadow: 0 0 10px rgba($color: g.$blue, $alpha: 0.1);
    }

    .form_block {
      max-width: 520px;
      margin: 0 auto;
      text-align: left;

      &.confirm {
        max-width: 1000px;
      }
    }

    .form_list {
      > li {
        font-size: 16px;
      }
    }

    label {
      font-weight: 700;
      margin-top: 15px;
      display: block;

      &.required {
        position: relative;
        @include g.flexbox;
        @include g.flex-align-items(center);

        &::after {
          margin-left: 5px;
          display: inline-block;
          content: "必須";
          background-color: g.$form_red;
          color: #fff;
          font-size: 10px;
          font-weight: 700;
          padding: 1px 10px;
          border-radius: 3px;
        }
      }
    }

    .form_select {
      position: relative;

      &::after {
        content: "";
        display: block;
        position: absolute;
        border-top: 10px solid g.$base_color;
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;
        width: 12px;
        height: 10px;
        right: 40px;
        top: 50%;
        transform: translateY(-50%);

        @include g.mq {
          right: 18px;
        }
      }

      &.w_short {
        width: 50%;

        @include g.mq {
          width: 90%;
          max-width: 260px;
        }
      }
    }
    .form_checkbox {
      display: flex;
      flex-wrap: wrap;
      .horizontal-item {
        @include g.mq {
          margin-left: 0px;
          width: 100%;
        }
      }
    }

    input[type="text"],
    input[type="number"],
    input[type="tel"],
    input[type="email"],
    select,
    textarea {
      margin-top: 5px;
      width: 100%;
      border: solid 1px g.$gray;
      background-color: g.$li-blue02;
      border-radius: 5px;
      padding: 15px 10px;

      &::placeholder {
        color: g.$gray04;
      }

      &.w_short {
        width: 50%;

        @include g.mq {
          width: 90%;
          max-width: 260px;
        }
      }

      &.error {
        border: solid 1px #f41818;
      }
    }

    .error_message {
      display: block;
      font-size: 15px;
      color: #f41818;
      margin-top: 5px;
    }

    textarea {
      height: 160px;

      @include g.mq {
        height: 120px;
      }
    }

    .postcode_wrap {
      position: relative;
    }

    .postcode_btn {
      position: absolute;
      top: 10px;
      left: 280px;
      color: #fff;
      background: g.$base_color;
      font-size: 15px;
      font-weight: bold;
      padding: 10px 25px;
      border-radius: 50px;

      @include g.mq {
        position: relative;
        top: auto;
        left: auto;
        display: block;
        margin-top: 10px;
      }
    }

    .note {
      margin-top: 20px;
      font-size: 16px;
    }

    .privacy_agreement {
      border: solid 1px #909090;
      border-radius: 5px;
      height: 200px;
      overflow: scroll;
      padding: 20px 20px 1px;
      margin-bottom: 20px;
      dt {
        font-weight: bold;
        margin-bottom: 10px;
      }
      dd {
        font-size: 14px;
        margin-bottom: 20px;
      }
    }

    .policy {
      font-weight: 700;

      a {
        color: g.$btn_color;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .policy_agree {
      margin-top: 10px;

      label {
        display: inline;
        padding-left: 5px;
        font-weight: 500;
      }
    }

    table {
      @include g.mq {
        margin-top: 30px;
      }

      th,
      td {
        font-size: 18px;
        border-bottom: solid 1px g.$gray;
        padding: 26px 40px;

        @include g.mq(lg) {
          padding: 26px 20px;
        }

        @include g.mq {
          display: block;
          padding: 15px 5px;
        }
      }

      tr {
        position: relative;

        &:first-child {
          th,
          td {
            padding-top: 0;
          }
        }
      }

      th {
        font-weight: bold;
        width: 25%;
        white-space: nowrap;

        @include g.mq {
          width: 100%;
          border-bottom: none;
          padding-bottom: 5px;
        }

        &:before {
          content: "";
          display: block;
          width: 120px;
          height: 1px;
          background: g.$ac_color;
          position: absolute;
          left: 0;
          bottom: -1px;

          @include g.mq {
            width: 100px;
          }
        }
      }

      td {
        width: 65%;

        @include g.mq {
          width: 100%;
          padding-top: 0;
        }
      }

      span {
        display: block;
        margin-bottom: 10px;
      }
    }

    .divide {
      display: none;
    }

    .mw_wp_form .error {
      color: inherit;
    }

    .mw_wp_form .form_checkbox .error {
      width: 100%;
    }

    span.error {
      color: #f41818 !important;
    }
  }

  .btn {
    @include g.flexbox;
    @include g.flex-justyfy-content(center);
    margin-top: 40px;

    @include g.mq {
      @include g.flex-flow(column);
      @include g.flex-align-items(center);
    }
    .wpcf7-spinner {
      display: none;
    }
  }

  .arrow_r {
    color: #fff;
    position: relative;

    &::after {
      position: absolute;
      right: 40px;
      top: 50%;
      transform: translateY(-50%);
      content: "\f061";
      font-family: "font awesome 6 free";
      font-weight: 900;
      padding-left: 0.8em;
      font-size: 18px;
      pointer-events: none;

      @include g.mq(sm) {
        right: 20px;
      }
    }
  }

  .arrow_l {
    color: g.$base_color;
    position: relative;

    &::after {
      position: absolute;
      left: 40px;
      top: 50%;
      transform: translateY(-50%);
      content: "\f060";
      font-family: "font awesome 6 free";
      font-weight: 900;
      padding-right: 0.8em;
      font-size: 18px;
      pointer-events: none;
      transition: g.$link_time;

      @include g.mq(sm) {
        left: 20px;
      }
    }

    &:hover {
      &:after {
        color: #fff;
      }
    }
  }

  .multiple {
    input {
      width: 100%;
    }

    div {
      width: 100%;
      max-width: 280px;
      margin: 0 15px;

      @include g.mq {
        margin-bottom: 20px;
      }
    }
  }
}

// 入力画面
.mw_wp_form_input {
  .btn {
    .arrow_l {
      display: none;
    }
  }
}

// 確認画面
.mw_wp_form_confirm {
  .form_list {
    margin-top: -20px;

    @include g.mq {
      margin-top: 15px;
    }

    li {
      @include g.flexbox;
      font-size: 18px !important;
      border-bottom: solid 1px g.$gray;
      padding: 26px 40px;
      position: relative;

      @include g.mq {
        @include g.flex-flow(column);
        @include g.flex-justyfy-content(flex-start);
        padding: 15px 5px;
      }

      &:before {
        content: "";
        display: block;
        width: 120px;
        height: 1px;
        background: g.$ac_color;
        position: absolute;
        left: 0;
        bottom: -1px;

        @include g.mq {
          width: 100px;
        }
      }

      > div {
        label {
          display: none;
        }
      }
    }

    label {
      margin-top: 0 !important;
      width: 25%;
      min-width: 230px;
      margin-right: 30px;
      cursor: auto;

      @include g.mq {
        margin-bottom: 5px;
      }

      &:after {
        display: none !important;
      }
    }

    .form_select {
      &.w_short {
        width: 100% !important;
      }

      &:after {
        display: none !important;
      }
    }

    .ad_confirm {
      label {
        display: none !important;
      }
    }
  }

  .note {
    display: none;
  }

  .divide {
    display: flex !important;
  }
}

.mw_wp_form_confirm .rec-btn {
  display: none;
}
