@charset "utf-8";

$base_font: "Montserrat", "Noto Sans JP", "游明朝", "Yu Mincho", YuMincho,
  "Shippori Mincho", serif, "游ゴシック体", YuGothic, "游ゴシック Medium",
  "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ Pro W3",
  "Hiragino Kaku Gothic Pro", "Noto Serif JP", sans-serif;
$en_font: "Montserrat", "Noto Sans JP", "游明朝", "Yu Mincho", YuMincho,
  "Shippori Mincho", serif, "游ゴシック体", YuGothic, "游ゴシック Medium",
  "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ Pro W3",
  "Hiragino Kaku Gothic Pro", "Noto Serif JP", sans-serif;
$mincho_font: "Noto Serif JP", "游明朝体", "YuMincho", "游明朝", "Yu Mincho",
  "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN W3", HiraMinProN-W3,
  "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro",
  "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;

$link_time: all 0.5s ease; //hoverの時間

$breakpoints: (
  "sm-s": "screen and (max-width: 350px)",
  "sm": "screen and (max-width: 479px)",
  "md": "screen and (max-width: 767px)",
  "lg": "screen and (max-width: 999px)",
  "lg-x": "screen and (max-width: 1099px)",
  "xl": "screen and (max-width: 1199px)",
  "xml": "screen and (max-width: 1299px)",
  "xxl": "screen and (max-width: 1399px)",
  "xxxl": "screen and (max-width: 1499px)",
) !default;

@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin calc($property, $expression) {
  #{$property}: -moz-calc(#{$expression});
  #{$property}: -o-calc(#{$expression});
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

@mixin opacity($opacity) {
  opacity: $opacity;
  filter: alpha(opacity=($opacity * 100));
}

@mixin flexbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin inline-flexbox {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
}

@mixin flex-flow($flow) {
  -ms-flex-flow: $flow;
  -webkit-flex-flow: $flow;
  flex-flow: $flow;
}

@mixin flex-align-items($align) {
  -ms-align-items: $align;
  -webkit-align-items: $align;
  align-items: $align;
}

@mixin flex-align-content($content) {
  -ms-align-content: $content;
  -webkit-align-content: $content;
  align-content: $content;
}

@mixin flex-justyfy-content($justyfy) {
  -ms-justify-content: $justyfy;
  -webkit-justify-content: $justyfy;
  justify-content: $justyfy;
}
