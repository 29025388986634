@charset "utf-8";

@use "global" as g;

//service

.service_group {
  margin-top: 60px;

  @include g.mq {
    margin-top: 40px;
  }

  .list {
    @include g.flexbox;
    @include g.flex-justyfy-content(center);
    @include g.flex-flow(wrap);
  }

  li {
    position: relative;
    width: calc((100% - 60px) / 2);
    max-width: 480px;

    @include g.mq {
      width: 100%;
    }

    &:nth-child(even) {
      margin-left: 60px;

      @include g.mq {
        margin-left: 0px;
      }
    }

    &:nth-child(n + 3) {
      margin-top: 60px;
    }

    &:nth-child(n + 2) {
      @include g.mq {
        margin-top: 20px;
      }
    }
  }

  a {
    &:hover {
      .ttl {
        color: g.$ac_color;
        transition: g.$link_time;
      }
    }
  }

  .tag {
    position: absolute;
    top: 0;
    left: 0;
    background-color: g.$bg_blue;
    color: #fff;
    font-weight: 700;
    padding: 5px 30px 5px 30px;
    border-radius: 0 0 20px 0;
    font-size: 16px;

    @include g.mq(lg) {
      font-size: 14px;
    }

    @include g.mq {
      font-size: 16px;
    }

    @include g.mq(sm-s) {
      font-size: 12px;
      padding: 5px 20px 5px 20px;
    }
  }

  .ttl {
    margin-top: 10px;
    font-size: 24px;
    font-weight: 700;
  }

  .text {
    margin-top: 5px;
    font-size: 16px;
    font-weight: 400;
  }
}

.service_plan {
  margin: 150px 0;

  @include g.mq {
    margin: 80px 0;
  }

  .box {
    background-color: g.$ac_color;
    border-radius: 100px 0 100px 0;
    padding: 100px;
    @include g.flexbox;
    @include g.flex-justyfy-content(center);

    @include g.mq(lg) {
      padding: 60px 20px 40px;
      @include g.flex-flow(wrap);
    }
  }

  .text {
    width: calc(100% - 362px);
    padding-right: 40px;
    min-width: 390px;

    @include g.mq(lg) {
      width: 100%;
      padding-right: 0px;
      min-width: auto;
    }
  }

  .photo {
    max-width: 362px;
    text-align: right;

    @include g.mq(lg) {
      margin-top: 20px;
      width: 100%;
      max-width: auto;
      text-align: center;
    }
  }

  h2 {
    font-size: 48px;
    color: #fff;

    @include g.mq(lg) {
      font-size: 32px;
      text-align: center;
    }
  }

  p {
    margin-top: 20px;
    font-size: 18px;
  }
}

.service_support_bnr {
  margin: 80px 0 120px;

  @include g.mq {
    background-color: #008ce3;
    border-radius: 50px 0 50px 0;
  }

  .box {
    max-width: 820px;
    background-color: #008ce3;
    border-radius: 50px 0 50px 0;
    margin: 0 auto;
    padding: 0 20px 40px;
    @include g.flexbox;
    @include g.flex-justyfy-content(space-between);
    @include g.flex-flow(row wrap);
    @include g.flex-align-items(center);

    @include g.mq(lg) {
      padding: 40px 20px;
    }
    @include g.mq {
      padding: 10px 0 20px;
    }

    .photo {
      width: 56%;
      position: relative;

      @include g.mq(lg) {
        width: 45%;
      }
      @include g.mq {
        width: calc(100% + 12px);
        margin-left: -12px;
      }
      p {
        position: absolute;
        bottom: -1px;
        right: -1px;
        display: inline-block;
        padding: 5px 30.25px;
        color: #fff;
        background-color: #008ce3;
        border-radius: 20px 0 0 0;
      }
    }
    .text {
      width: 40%;
      color: #fff;
      padding-top: 20px;
      @include g.mq(lg) {
        width: 50%;
      }
      @include g.mq {
        width: 100%;
      }
      h2 {
        text-align: center;
        font-size: 40px;
        font-weight: bold;
        margin-bottom: 10px;
        @include g.mq {
          font-size: 24px;
        }
      }
      p {
        font-weight: 400;
        margin-bottom: 20px;
        @include g.mq {
          width: 92%;
          font-size: 16px;
          margin: 0 auto 20px;
        }
      }
      .btn {
        text-align: center;
        .bk_btn {
          width: 240px;
          padding: 13px 0;
          margin: 0 auto;

          &::after {
            display: none;
          }
        }
      }
    }
  }
}

//service下層

.service_worry {
  margin-top: 100px;

  @include g.mq {
    margin-top: 40px;
  }

  h2 {
    font-size: 48px;
    text-align: center;

    @include g.mq {
      font-size: 32px;
    }

    span {
      color: g.$ac_color;
    }
  }

  .center-txt {
    margin: 30px auto 60px;
    width: 60%;

    @include g.mq(lg) {
      width: 100%;
    }
    @include g.mq {
      margin: 10px auto 40px;
    }
  }

  .worry_list {
    margin: 60px auto 0;
    max-width: 1050px;
    @include g.flexbox;
    @include g.flex-justyfy-content(center);
    @include g.flex-flow(row-reverse wrap-reverse);
    @include g.flex-align-items(flex-end);

    @include g.mq {
      margin: 25px auto 0;
    }

    li {
      max-width: calc(50% - 30px);
      margin: 0 15px;
      background-color: g.$gray02;
      font-size: 20px;
      font-weight: 700;
      padding: 20px 30px;
      border-radius: 100px;
      position: relative;

      @include g.mq(lg) {
        max-width: none;
        width: 100%;
        margin: 0 auto;
        font-size: 16px;
      }

      &:nth-child(-n + 2) {
        margin-top: 25px;
      }

      &:not(:last-child) {
        @include g.mq(lg) {
          margin-top: 25px;
        }
      }

      &:nth-child(3):last-child {
        margin: 0 auto;
      }

      &::after {
        content: "";
        position: absolute;
        border-bottom: 22px solid transparent;
        border-left: 12px solid g.$gray02;
        bottom: -15px;
        right: 40px;
        transform: skew(-12deg);
        z-index: -1;

        @include g.mq {
          border-bottom: 30px solid transparent;
          border-left: 18px solid g.$gray02;
          bottom: -15px;
        }
      }
    }

    p {
      position: relative;
      padding-left: 40px;

      @include g.mq {
        padding-left: 35px;
      }

      &::before {
        position: absolute;
        content: "\f058";
        font-family: "font awesome 6 free";
        font-weight: 900;
        color: g.$ac_color;
        background-color: #fff;
        font-size: 30px;
        border-radius: 50%;
        line-height: 1;
        top: 0;
        left: 0;

        @include g.mq {
          font-size: 24px;
        }
      }
    }

    span {
      color: g.$ac_color;
    }
  }

  .photo {
    margin-top: 38px;
    text-align: center;
  }
}

.service_strong {
  @include g.mq {
    background-color: g.$ac_color;
  }

  .bg {
    background-color: g.$ac_color;
    border-radius: 100px 0 100px 0;
    padding: 100px;

    @include g.mq {
      background: none;
      padding: 60px 0 40px;
    }
  }

  .ttl {
    text-align: center;

    h2,
    h3 {
      font-size: 32px;

      @include g.mq {
        font-size: 16px;
      }
    }

    .large {
      font-size: 48px;

      @include g.mq {
        font-size: 32px;
      }
    }

    .white {
      color: #fff;
    }
  }

  .intro {
    font-size: 18px;
    max-width: 800px;
    margin: 40px auto 0;

    @include g.mq {
      font-size: 16px;
      margin: 10px auto 0;
    }
  }

  .strong_list {
    margin-top: 55px;

    @include g.mq {
      margin-top: 20px;
    }

    .box {
      max-width: 1000px;
      width: 100%;
      margin: 0 auto;
      padding: 60px;
      background-color: #fff;
      border-radius: 50px 0 50px 0;
      @include g.flexbox;
      @include g.flex-flow(wrap);
      @include g.flex-align-items(center);

      @include g.mq {
        padding: 30px 20px;
        @include g.flex-flow(column-reverse);
      }

      &:nth-child(n + 2) {
        margin-top: 40px;

        @include g.mq {
          margin-top: 20px;
        }
      }
    }

    .text {
      width: 55%;

      @include g.mq {
        width: 100%;
        margin-top: 20px;
      }
    }

    .photo {
      width: 45%;
      padding-left: 40px;

      @include g.mq {
        width: 100%;
        padding-left: 0px;
      }
    }

    .num {
      color: g.$ac_color;
      font-size: 20px;
      font-weight: 700;

      @include g.mq {
        text-align: center;
      }
    }

    h3 {
      margin-top: 10px;
      font-size: 32px;

      @include g.mq {
        font-size: 24px;
        text-align: center;
      }
    }

    p {
      margin-top: 10px;
      font-weight: 400;
      font-size: 16px;
    }

    .point_box {
      margin-top: 10px;
      background-color: g.$gray03;
      padding: 15px;

      h5 {
        color: g.$btn_color;
        font-size: 20px;
      }

      ul {
        margin-top: 10px;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .num_list {
      counter-reset: number 0;

      li {
        font-size: 20px;
        font-weight: 700;
        padding-left: 60px;
        position: relative;

        @include g.mq {
          font-size: 18px;
          padding-left: 0px;
          padding-top: 40px;
        }

        &::before {
          position: absolute;
          counter-increment: number 1;
          content: counter(number, decimal-leading-zero) ".";
          font-size: 32px;
          font-weight: 700;
          color: g.$ac_color;
          top: 50%;
          left: 0;
          transform: translateY(-50%);

          @include g.mq {
            font-size: 28px;
            top: 0px;
            transform: translateY(0);
          }
        }

        &:nth-child(n + 2) {
          margin-top: 30px;

          @include g.mq {
            margin-top: 20px;
          }
        }
      }
    }

    .link {
      text-align: right;
      color: g.$btn_color;

      a {
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &.col2 {
      > ul {
        @include g.flexbox;
        @include g.flex-justyfy-content(space-between);
        @include g.flex-flow(wrap);
      }

      .box {
        width: calc((100% - 40px) / 2);
        margin: 0;
        padding: 40px;
        @include g.flex-flow(column-reverse);
        @include g.flex-justyfy-content(flex-end);
        position: relative;

        @include g.mq(lg) {
          width: 100%;
          padding: 30px 20px;
        }

        &:nth-child(n + 3) {
          margin-top: 40px;
        }

        &:nth-child(n + 2) {
          @include g.mq(lg) {
            margin-top: 20px;
          }
        }
      }

      .text {
        width: 100%;
        margin-top: 20px;
      }

      .photo {
        width: 100%;
        text-align: center;
        padding-left: 0;
      }

      .num {
        position: absolute;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        top: 40px;
        left: 40px;

        @include g.mq {
          top: 30px;
          left: 20px;
        }
      }

      h3 {
        text-align: center;
      }
    }

    &.col3 {
      .box {
        width: 100%;
        @include g.flexbox;
        @include g.flex-justyfy-content(space-between);
        @include g.flex-flow(wrap);

        .textbox {
          @include g.flexbox;
          @include g.flex-flow(column-reverse);
          @include g.flex-justyfy-content(flex-end);
          width: calc((100% - 40px) / 2);
          @include g.mq(lg) {
            width: 100%;
          }
        }
        .photo-box {
          @include g.flexbox;
          @include g.flex-justyfy-content(space-between);
          @include g.flex-flow(wrap);
          width: 50%;
          @include g.mq(lg) {
            width: 100%;
            margin-top: 20px;
          }
          > ul {
            @include g.flexbox;
            @include g.flex-justyfy-content(space-between);
            @include g.flex-flow(wrap);
            .item {
              width: calc((100% - 28px) / 2);
              background-color: #ecf4f7;
              border-radius: 20px 0 20px 0;
              margin-bottom: 15px;
              padding: 20px 10px 15px;
              position: relative;
              @include g.mq(lg) {
                width: 100%;
                margin: 10px 0 0;
                padding: 7px 15px;
                @include g.flexbox;
                @include g.flex-justyfy-content(space-between);
                align-items: center;
              }
              .no {
                position: absolute;
                top: 10px;
                left: 20px;
                font-size: 18px;
                font-weight: bold;
                color: #008ce3;
                @include g.mq(lg) {
                  top: -13px;
                  left: 5px;
                }
              }
              .img {
                text-align: center;
                margin-bottom: 10px;
                @include g.mq(lg) {
                  width: 21%;
                }
              }
              h4 {
                text-align: center;
                font-size: 16px;
                @include g.mq(lg) {
                  width: 77%;
                  text-align: left;
                }
              }
              &:nth-child(5) {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  &_support {
    @include g.mq {
      margin-bottom: 40px;
    }
    .bg {
      border-radius: 50px 0 50px 0;
      padding: 50px;

      @include g.mq(lg) {
        border-radius: 30px 0 30px 0;
        padding: 30px;
      }
      @include g.mq {
        padding: 40px 0 20px;
      }
    }
  }

  .support_list {
    ul {
      width: 100%;
      @include g.flexbox;
      @include g.flex-justyfy-content(space-between);
      @include g.flex-flow(wrap);
      .box {
        width: calc((100% - 80px) / 3);
        background-color: #fff;
        border-radius: 50px 0 50px 0;
        padding: 20px 10px;
        text-align: center;

        @include g.mq(lg) {
          width: calc((100% - 50px) / 3);
          border-radius: 30px 0 30px 0;
        }
        @include g.mq {
          width: 100%;
          margin-bottom: 20px;
        }
        .text {
          font-weight: bold;
          h3 {
            font-size: 27px;

            @include g.mq(lg) {
              font-size: 26px;
              min-height: 3em;
            }
            @include g.mq {
              min-height: initial;
              min-height: auto;
            }
          }
        }
        .photo {
          width: 53%;
          margin: 10px auto 30px;
          img {
            width: 100%;
            height: auto;
          }
        }
        .btn {
          .bk_btn {
            text-align: center;
            width: 222px;
            padding: 12px 0;

            @include g.mq(lg) {
              width: 100%;
            }
            @include g.mq {
              width: 222px;
              font-size: 16px;
            }

            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
}

.service_merit {
  margin-top: 80px;

  @include g.mq {
    margin-top: 60px;
  }

  .merit_list {
    margin-top: 40px;

    @include g.mq {
      margin-top: 20px;
    }

    .list {
      @include g.flexbox;
      @include g.flex-justyfy-content(space-between);
      @include g.flex-flow(wrap);

      .box {
        width: calc((100% - 60px * 2) / 3);
        background-color: g.$li-blue02;
        border-radius: 50px 0 50px 0;
        padding: 40px;

        @include g.mq(lg) {
          width: 100%;
          padding: 25px;
        }

        &:nth-child(n + 2) {
          @include g.mq(lg) {
            margin-top: 20px;
          }
        }
      }

      &.col4 {
        .box {
          width: calc((100% - 25px * 3) / 4);
          padding: 40px 30px;

          @include g.mq(xl) {
            width: calc((100% - 40px) / 2);
            margin-bottom: 40px;
          }
          @include g.mq(lg) {
            width: 100%;
            padding: 25px;
            margin-bottom: 0;
          }

          &:nth-child(n + 2) {
            @include g.mq(lg) {
              margin-top: 20px;
            }
          }
        }
      }

      .photo {
        text-align: center;
        height: 120px;
        vertical-align: middle;

        @include g.mq(lg) {
          height: auto;
        }
      }

      .text {
        margin-top: 30px;

        @include g.mq() {
          margin-top: 10px;
        }

        h3 {
          font-size: 20px;
          font-weight: 700;
          color: g.$ac_color;
          text-align: center;

          @include g.mq {
            font-size: 16px;
          }
        }
        p {
          margin-top: 20px;
          font-size: 16px;
        }
      }
    }
  }
}

.service_Difference {
  margin-top: 100px;

  @include g.mq {
    margin-top: 60px;
  }

  #Difference_list {
    max-width: 1000px;
    width: 100%;
    margin: 50px auto 0;
    font-size: 18px;

    @include g.mq {
      font-size: 16px;
      margin-top: 20px;
    }

    @include g.mq(sm-s) {
      font-size: 13px;
    }

    .tab {
      @include g.flexbox;
      @include g.flex-justyfy-content(space-between);
      padding-left: 16%;

      @include g.mq {
        padding-left: 0;
        @include g.flex-align-items(flex-end);
      }

      .box {
        background-color: g.$gray;
        color: #fff;
        width: calc((100% - 4px * 2) / 3);
        padding: 38px 30px;
        text-align: center;
        font-weight: 700;
        pointer-events: none;

        @include g.mq {
          padding: 15px 0;
          border-radius: 10px 10px 0 0;
          pointer-events: all;
        }

        &:nth-child(2) {
          background-color: g.$ac_color;
        }

        &.active {
          @include g.mq {
            padding: 17px 0px;
          }
        }
      }
    }

    .list {
      .row {
        @include g.flexbox;
        @include g.flex-justyfy-content(space-between);
        border-bottom: solid 1px g.$gray;

        .box {
          padding: 38px 30px;
          width: calc((84% - 4px * 2) / 3);
          background-color: #f7f7f7;
          @include g.flexbox;
          @include g.flex-justyfy-content(center);
          @include g.flex-align-items(center);
          @include g.flex-flow(wrap);
          text-align: center;

          @include g.mq {
            display: none;
            width: calc(100% - 125px);
            padding: 25px 15px;
            text-align: left;
            @include g.flex-justyfy-content(flex-start);
          }

          &:nth-child(1) {
            padding: 38px 20px;
            width: calc(16% - 4px);
            text-align: left;
            background-color: transparent;
            text-align: left;
            @include g.flex-justyfy-content(flex-start);

            @include g.mq {
              display: flex;
              width: 125px;
              padding-right: 0;
            }
          }

          &:nth-child(3) {
            background-color: #ecf4f7;
          }

          &.active {
            @include g.mq {
              display: flex;
            }

            &:nth-child(1) {
              background-color: #ecf4f7;
            }
          }

          picture {
            margin-top: 20px;
            width: 100%;
          }
        }
      }
    }

    .note {
      margin-top: 15px;
    }
  }
}

.service_benefit {
  margin-top: 80px;

  @include g.mq {
    margin-top: 60px;
  }

  .benefit_list {
    margin-top: 20px;
  }

  .box {
    @include g.flexbox;
    @include g.flex-align-items(center);

    @include g.mq {
      @include g.flex-flow(wrap);
    }

    &:nth-child(n + 2) {
      margin-top: 80px;

      @include g.mq {
        margin-top: 40px;
      }
    }

    &.reverse {
      @include g.flex-flow(row-reverse wrap);

      .photo {
        padding-right: 0;
        padding-left: 20px;

        @include g.mq {
          padding-left: 0;
        }
      }

      .text {
        padding-left: 0;
        padding-right: 10px;

        @include g.mq {
          padding-right: 0;
        }
      }
    }

    &:nth-child(even) {
      .photo {
        @include g.mq {
          margin-left: 0;
          margin-right: calc(50% - 50vw);
        }

        img {
          @include g.mq {
            border-radius: 50px 0 0 0;
          }
        }
      }
    }
  }

  .text,
  .photo {
    width: 50%;

    @include g.mq {
      width: 100%;
    }
  }

  .photo {
    padding-right: 20px;

    @include g.mq {
      width: auto;
      margin-left: calc(50% - 50vw);
      padding-right: 0px;
    }

    img {
      border-radius: 100px 0 100px;
      overflow: hidden;

      @include g.mq {
        border-radius: 0 50px 0 0;
      }
    }
  }

  .text {
    padding-left: 10px;

    @include g.mq {
      padding-left: 0;
      margin-top: 10px;
    }

    .img {
      margin-top: 20px;
    }
  }

  .tag {
    margin-bottom: 20px;
    display: inline-block;
    color: #fff;
    background-color: g.$ac_color;
    padding: 5px 30px;
    border-radius: 20px 0 20px 0;
    font-size: 20px;
    font-weight: 700;

    @include g.mq {
      margin-bottom: 10px;
      font-size: 13px;
      padding: 5px 20px;
    }
  }

  h3 {
    font-size: 32px;

    @include g.mq {
      font-size: 24px;
    }
  }

  p {
    margin-top: 10px;
    font-weight: 400;
    font-size: 16px;
  }
}

.service_type {
  margin-top: 80px;

  @include g.mq {
    margin-top: 60px;
  }

  .type_list {
    margin-top: 20px;
    padding: 50px 100px 100px;
    border-radius: 50px 0 50px 0;
    background-color: g.$li-blue02;
    @include g.mq(lg) {
      padding: 60px 40px;
    }
    @include g.mq {
      padding: 30px 20px;
    }

    .list {
      .box {
        padding: 45px 0;
        border-bottom: g.$gray 1px solid;
        @include g.mq {
          padding: 30px 0;
        }
        .ttl {
          margin-bottom: 20px;
          font-size: 32px;
          font-weight: bold;
          @include g.mq {
            font-size: 24px;
          }
        }
        .text {
          a {
            display: block;
            color: g.$btn_color;
            text-decoration: underline;
          }
        }
        .accordion {
          margin: 30px 0 0;
          width: 660px;
          @include g.mq(lg) {
            width: 100%;
          }
          dt,
          dd {
            padding: 20px 70px 20px 30px;
            border-radius: 100px;
            margin-bottom: 15px;

            @include g.mq(lg) {
              padding: 15px 40px 15px 50px;
            }
            @include g.mq {
              padding: 15px 40px 15px 20px;
            }
          }

          dt {
            background: #fff;
            color: g.$base_color;
            border: g.$btn_color 1px solid;
            position: relative;
            font-size: 18px;
            cursor: pointer;
            transition: all 0.5s;
            @include g.mq {
              font-size: 15px;
            }
            &:hover {
              color: g.$ac_color;
              border: g.$ac_color 1px solid;
              transition: all 0.5s;
              &:before,
              &:after {
                background-color: g.$ac_color;
              }
            }

            &:before,
            &:after {
              content: "";
              display: block;
              width: 20px;
              height: 2px;
              background-color: g.$base_color;
              position: absolute;
              right: 30px;
              top: 50%;
              transform: translateY(-50%);
              transition: g.$link_time;
              transition: all 0.5s;

              @include g.mq {
                width: 10px;
                right: 20px;
              }
            }

            &:after {
              transform: translateY(-50%) rotate(90deg);
            }
            &:before {
              background-color: g.$base_color;
            }
            &.active {
              color: g.$ac_color;
              border: g.$ac_color 1px solid;

              &:after {
                background-color: g.$ac_color;
                transform: translateY(-50%) rotate(0deg);
              }
            }

            span {
              background: g.$ac_color;
              color: #fff;
              font-size: 16px;
              font-weight: bold;
              line-height: 31px;
              text-align: center;
              width: 31px;
              height: 31px;
              border-radius: 31px;
              position: absolute;
              top: 20px;
              left: 20px;

              @include g.mq {
                font-size: 10px;
                line-height: 21px;
                width: 21px;
                height: 21px;
                top: 15px;
                left: 20px;
              }
            }
          }
          dd {
            display: none;
            padding-top: 10px;
            padding-bottom: 15px;
            border-radius: 0;
            position: relative;
          }
        }
      }
    }
  }
}

.service_video {
  .video-section {
    padding: 100px 0 0;
    @include g.mq {
      padding: 50px 0 0;
    }
    .wrap {
      padding: 100px 0 0;
      border-radius: 100px 0 0 0;
      background-image: url("../img/service/internship/bg-movie-1.webp");
      background-repeat: no-repeat;
      background-position: top center;
      background-size: cover;
      @include g.mq {
        padding: 50px 0 0;
        border-radius: 50px 0 0 0;
        background-image: url("../img/service/internship/sp-bg-movie-1.webp");
      }
      .page_width {
        position: relative;
      }
    }
    .logo {
      position: absolute;
      top: -60px;
      right: 0;
      text-align: center;
      @include g.mq(lg) {
        top: -40px;
        right: 50px;
        width: 31.5%;
      }
      @include g.mq {
        top: -20px;
        right: 20px;
      }
    }
    .ttl {
      margin-bottom: 16px;
      @include g.mq(lg) {
        display: none;
      }
      &-txt {
        display: inline-block;
        margin-bottom: 24px;
        padding: 11px 35px;
        line-height: 1;
        font-size: 48px;
        font-weight: bold;
        color: #fff;
        background-color: #00a5e3;
        border-radius: 35px 0 35px 0;
        @include g.mq {
          margin-bottom: 10px;
          padding: 7.5px 20px;
          font-size: 24px;
          border-radius: 20px 0 20px 0;
        }
      }
    }
    .sp-ttl {
      display: none;
      @include g.mq(lg) {
        display: block;
      }
      @include g.mq {
        margin-bottom: 10px;
      }
    }
    .video {
      margin: 0 auto;
      width: 100%;
      text-align: center;
      position: relative;
      padding-top: 54%;
      border: #154a95 20px solid;
      border-radius: 45px;
      background-color: #000;
      position: relative;
      @include g.mq {
        margin: 0 auto;
        padding-top: 52.31769%;
        border: #154a95 10px solid;
        border-radius: 25px;
      }
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        border-radius: 25px;
        @include g.mq {
          border-radius: 15px;
        }
      }
    }
    .tr-ar {
      margin: 0 auto;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 30px 30px 0 30px;
      border-color: #154a95 transparent transparent transparent;
      position: relative;
      z-index: 2;
      @include g.mq {
        border-width: 15px 15px 0 15px;
      }
    }
    .txtbox {
      text-align: center;
      margin: -10px auto 0;
      padding: 50px 0;
      background-color: #00a5e3;
      border-radius: 0 0 100px 0;
      color: #fff;
      position: relative;
      @include g.mq {
        margin: -5px auto 0;
        padding: 20px 0;
        border-radius: 0 0 50px 0;
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 50%;
        -webkit-transform: translate(50%, 0);
        -moz-transform: translate(50%, 0);
        -ms-transform: translate(50%, 0);
        -o-transform: translate(50%, 0);
        transform: translate(50%, 0);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 20px 20px 0 20px;
        border-color: #fff transparent transparent transparent;
        @include g.mq {
          border-width: 10px 10px 0 10px;
        }
      }
      p {
        font-size: 30px;
        text-align: center;
        color: #fff;
        font-weight: bold;
        @include g.mq(lg) {
          font-size: 36px;
          text-align: left;
        }
        @include g.mq {
          font-size: 18px;
        }
        .tb-cont {
          @include g.mq(lg) {
            display: none;
          }
        }
      }
    }
  }
}

.service_osusume {
  margin-top: 100px;

  .box {
    background-color: g.$gray02;
    border-radius: 100px 0 100px 0;
    padding: 100px;
    @include g.flexbox;
    @include g.flex-align-items(center);

    @include g.flex-flow(wrap);

    @include g.mq(lg) {
      padding: 100px 40px;
    }

    @include g.mq {
      padding: 70px 25px;
    }
  }

  .left {
    width: 60%;

    @include g.mq(lg) {
      width: 100%;
    }
  }

  .right {
    width: 40%;
    padding-left: 10%;

    @include g.mq(lg) {
      max-width: 220px;
      width: 100%;
      padding-left: 0%;
      margin: 20px auto 0;
    }
  }

  h2 {
    font-size: 48px;
    font-weight: 700;

    @include g.mq(lg) {
      text-align: center;
    }

    @include g.mq {
      font-size: 28px;
    }

    span {
      color: g.$ac_color;
    }
  }

  .list {
    margin-top: 40px;

    li {
      &::before {
        content: "・";
      }

      &:nth-child(n + 2) {
        margin-top: 25px;
      }

      span {
        font-weight: 700;
        background: linear-gradient(transparent 60%, g.$yellow 60%);
      }
    }
  }
}

.service_occupation {
  margin: 100px 0 150px;

  @include g.mq {
    margin: 80px 0;
  }

  .occupation_list {
    margin-top: 60px;

    @include g.mq {
      margin-top: 20px;
    }

    .occupation {
      @include g.flexbox;
      @include g.flex-justyfy-content(flex-start);
      @include g.flex-flow(wrap);

      > li {
        width: calc((100% - 54px) / 2);
        margin-bottom: 40px;
        position: relative;

        @include g.mq(lg) {
          width: calc((100% - 30px) / 2);
        }

        @include g.mq {
          width: 100%;
          margin-bottom: 20px;
        }

        &:nth-of-type(odd) {
          margin-right: 54px;

          @include g.mq(lg) {
            margin-right: 30px;
          }

          @include g.mq {
            margin-right: 0;
          }
        }
      }

      .inner {
        min-height: 176px;
        height: 100%;
        background-color: #fff;
        border: solid 1px g.$gray;
        border-radius: 50px 0 50px 0;
        padding: 40px 50px;
        position: relative;

        @include g.mq {
          padding: 30px;
        }

        > ul {
          margin-top: 20px;

          @include g.mq {
            margin-top: 10px;
          }

          li {
            font-size: 18px;
          }
        }
      }

      h3 {
        font-size: 32px;
        color: g.$ac_color;

        @include g.mq {
          font-size: 24px;
        }
      }
    }
  }
}

.service_example {
  margin-top: 240px;
  padding-bottom: 60px;
  background-color: g.$li-blue02;

  @include g.mq {
    margin-top: 100px;
  }

  .ttl {
    padding-top: 100px;
    position: relative;

    @include g.mq {
      padding-top: 60px;
    }
  }

  .bg_ttl {
    position: absolute;
    top: -160px;
    left: 0;

    @include g.mq {
      top: -60px;
      left: -20px;
    }
  }

  h2,
  h3 {
    font-size: 48px;

    @include g.mq {
      font-size: 32px;
    }
  }

  .box {
    margin-top: 40px;
    @include g.flexbox;

    @include g.mq {
      @include g.flex-flow(wrap);
      margin-top: 20px;
    }
  }

  .photo {
    width: 56%;
    padding-right: 30px;

    @include g.mq {
      width: 100%;
      padding-right: 0px;
    }
  }

  .text {
    width: 44%;

    @include g.mq {
      width: 100%;
      margin-top: 10px;
    }
  }

  dl {
    @include g.flexbox;
    @include g.flex-flow(wrap);
    font-size: 18px;

    @include g.mq {
      font-size: 16px;
    }
  }

  dt {
    width: 110px;
    color: g.$btn_color;
    font-weight: 700;

    @include g.mq {
      width: 95px;
    }
  }

  dd {
    width: calc(100% - 110px);

    @include g.mq {
      width: calc(100% - 95px);
    }
  }

  .desc {
    margin-top: 20px;
    font-weight: 400;
  }

  .link {
    margin-top: 40px;
    text-align: center;
  }
}

.service_flow {
  margin: 100px 0 150px;

  @include g.mq {
    margin: 80px 0;
  }

  .flow_list {
    margin-top: 60px;

    @include g.mq {
      margin-top: 20px;
    }

    &.col2 {
      @include g.mq(lg) {
        @include g.flexbox;
        @include g.flex-align-items(stretch);
      }

      .flow {
        @include g.mq(lg) {
          padding: 60px 0 60px 10px;
          width: calc(100% - 50px);
          @include g.flex-justyfy-content(space-between);
          @include g.flex-flow(column);
        }
      }
    }

    .schedule {
      @include g.mq(lg) {
        @include g.flexbox;
        @include g.flex-align-items(stretch);
      }
    }

    .month {
      ol {
        @include g.flexbox;
        @include g.flex-justyfy-content(center);

        @include g.mq(lg) {
          display: none;
        }

        &.col3 {
          @include g.mq(lg) {
            @include g.flexbox;
            @include g.flex-justyfy-content(space-between);
            @include g.flex-flow(column);
            height: 100%;
          }

          > li {
            width: calc(100% / 3);
            text-align: center;
            padding-right: 40px;

            @include g.mq(lg) {
              width: 100%;
              height: calc(100% / 3);
              padding-right: 5px;

              -ms-writing-mode: tb-rl;
              writing-mode: vertical-rl;
            }

            &:nth-child(n + 2) {
              margin-left: 0%;
            }
          }
        }
      }

      li {
        font-size: 20px;
        font-weight: 700;
        color: g.$gray;

        @include g.mq(lg) {
          font-size: 14px;
        }

        &:nth-child(n + 2) {
          margin-left: 6%;
        }
      }

      em {
        text-combine-upright: all;
        font-style: normal;
        text-align: center;
      }
    }

    .timeline {
      margin: 10px 0 20px;

      .chart {
        height: 90px;
        background: linear-gradient(to right, g.$bg_blue, #68c3e5);
        @include g.flexbox;
        position: relative;

        @include g.mq(lg) {
          height: auto;
          width: 50px;
          background: linear-gradient(to bottom, g.$bg_blue, #68c3e5);
          @include g.flex-flow(wrap);
        }

        &::before,
        &::after {
          position: absolute;
          display: block;
          content: "";
          right: 0;

          @include g.mq(lg) {
            bottom: 0;
            right: auto;
          }
        }

        &::before {
          top: 0;
          border-top: 45px solid g.$bg_color;
          border-left: 45px solid transparent;

          @include g.mq(lg) {
            border-top: 45px solid transparent;
            border-left: 25px solid g.$bg_color;
            top: auto;
            left: 0;
          }
        }

        &::after {
          bottom: 0;
          border-top: 45px solid transparent;
          border-right: 45px solid g.$bg_color;

          @include g.mq(lg) {
            border-top: 45px solid transparent;
            border-right: 25px solid g.$bg_color;
            right: 0;
          }
        }

        &.col3 {
          @include g.mq(lg) {
            width: 35px;
          }

          .box {
            width: calc(100% / 3);

            @include g.mq(lg) {
              width: 100%;
              min-height: 400px;
            }
          }
        }
      }

      .box {
        width: calc(100% / 4);
        padding-right: 40px;
        color: #fff;
        font-size: 18px;
        font-weight: 700;
        @include g.flexbox;
        @include g.flex-align-items(center);
        @include g.flex-justyfy-content(center);
        position: relative;

        @include g.mq(lg) {
          width: 100%;
          padding-right: 0px;
          min-height: 300px;
          -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;
          line-height: 1;
        }

        &::after {
          content: "";
          display: block;
          width: 70px;
          height: 70px;
          border-right: solid 3px g.$bg_color;
          border-bottom: solid 3px g.$bg_color;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%) rotate(-45deg);

          @include g.mq(lg) {
            top: auto;
            bottom: 0;
            right: auto;
            left: 50%;
            transform: translate(-50%, 0) rotate(45deg);
          }
        }

        ul {
          @include g.mq(lg) {
            @include g.flexbox;
          }
        }
      }
    }

    .flow {
      @include g.flexbox;
      @include g.flex-justyfy-content(flex-start);
      @include g.flex-flow(wrap);
      counter-reset: number 0;

      > li {
        width: calc((100% - 54px * 3) / 4);
        position: relative;

        @include g.mq(xl) {
          width: calc((100% - 54px) / 2);
          position: relative;
        }

        @include g.mq {
          width: 100%;
        }

        &:not(:nth-child(4n)) {
          margin-right: 54px;

          @include g.mq(xl) {
            margin-right: 0;
          }

          &::after {
            content: "\f061";
            font-family: "font awesome 6 free";
            font-weight: 900;
            font-size: 17px;
            display: block;
            position: absolute;
            top: 50%;
            right: -37px;
            transform: translateY(-50%);

            @include g.mq(xl) {
              display: none;
            }
          }
        }

        &:not(:nth-child(2n)) {
          @include g.mq(xl) {
            margin-right: 54px;

            &::after {
              content: "\f061";
              font-family: "font awesome 6 free";
              font-weight: 900;
              font-size: 17px;
              display: block;
              position: absolute;
              top: 50%;
              right: -37px;
              transform: translateY(-50%);
            }
          }

          @include g.mq {
            margin-right: 0;
          }
        }

        &:not(:last-child) {
          &::after {
            @include g.mq {
              content: "\f063";
              font-family: "font awesome 6 free";
              font-weight: 900;
              font-size: 17px;
              display: block;
              position: absolute;
              top: auto;
              right: auto;
              bottom: -29px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }

        &:last-child {
          &::after {
            display: none;
          }
        }

        &:nth-child(n + 5) {
          margin-top: 30px;
        }

        &:nth-child(n + 3) {
          @include g.mq(xl) {
            margin-top: 30px;
          }
        }

        &:nth-child(n + 2) {
          @include g.mq {
            margin-top: 38px;
          }
        }

        &.col1,
        &.col2 {
          margin-right: 110px;

          @include g.mq(xl) {
            margin-right: 54px;
          }

          @include g.mq {
            margin-right: 0px;
          }

          &::after {
            right: -62px;

            @include g.mq(xl) {
              right: -37px;
            }

            @include g.mq(lg) {
              content: "\f063";
              height: 15px;
              right: auto;
              left: 50%;
              top: auto;
              bottom: -80px;
              // transform: rotate(90deg);
            }

            @include g.mq(sm-s) {
              bottom: -40px;
            }
          }
        }

        &.col1 {
          width: calc((100% - 110px * 2) / 4);

          @include g.mq(xl) {
            width: calc((100% - 54px * 2) / 4);
          }

          @include g.mq(lg) {
            width: 100%;
          }
        }

        &.col2 {
          width: calc((100% - 110px * 2) / 2);

          @include g.mq(xl) {
            width: calc((100% - 54px * 2) / 2);
          }

          @include g.mq(lg) {
            width: 100%;
            margin-top: 0;
          }

          &::after {
            display: block;
          }
        }

        &.col3 {
          width: calc((100% - 80px * 3) / 3);
          margin: 0 40px;

          @include g.mq(lg) {
            width: 100%;
            margin: 30px 0;
          }

          &::after {
            right: -50px;

            @include g.mq(lg) {
              content: "\f063";
              height: 15px;
              right: auto;
              left: 50%;
              top: auto;
              bottom: -80px;
              // transform: rotate(90deg);
            }
          }

          &:not(:last-child) {
            &::after {
              @include g.mq(xl) {
                display: block;
              }
            }
          }
        }

        &.col2,
        &.col3 {
          &:nth-child(2) {
            .inner {
              @include g.mq(lg) {
                padding: 120px 30px;
              }

              @include g.mq(sm) {
                padding: 30px;
              }
            }
          }
        }

        &.last {
          margin-right: 0;

          @include g.mq(xl) {
            margin-top: 0;
          }
        }
      }

      .inner {
        min-height: 176px;
        height: 100%;
        background-color: #fff;
        border: solid 2px g.$gray;
        border-radius: 50px 0 50px 0;
        padding: 30px;
        position: relative;

        @include g.mq {
          min-height: auto;
        }

        &::before,
        &::after {
          content: "";
          display: block;
          position: absolute;
        }

        &::before {
          border-top: 80px solid g.$ac_color;
          border-left: 80px solid transparent;
          top: -2px;
          right: -2px;
        }

        &::after {
          counter-increment: number 1;
          content: counter(number, decimal-leading-zero) "";
          color: #fff;
          font-size: 30px;
          font-weight: 700;
          line-height: 1.2;
          top: 3px;
          right: 3px;
        }
      }

      h3 {
        font-size: 20px;
      }

      p,
      .list > li {
        font-size: 16px;
        font-weight: 400;
      }

      p {
        margin-top: 10px;
      }

      .list {
        li {
          &::before {
            content: "・";
          }
        }
      }
    }
  }
}

.service_faq {
  margin-bottom: 150px;

  @include g.mq {
    margin-bottom: 80px;
  }

  .faq_list {
    margin-top: 50px;

    @include g.mq {
      margin-top: 10px;
    }

    dt {
      background-color: g.$li-blue02;
      border-radius: 70px;
      padding: 20px 70px 20px 30px;
      position: relative;
      font-size: 18px;
      cursor: pointer;

      @include g.mq {
        padding: 15px 50px 15px 20px;
        font-size: 15px;
      }

      &:not(:first-child) {
        margin-top: 20px;
      }

      &::before,
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        right: 35px;
        background-color: g.$base_color;
        transform: translateY(-50%);
        transition: g.$link_time;
      }

      &::before {
        width: 20px;
        height: 2px;

        @include g.mq {
          width: 10px;
          right: 20px;
        }
      }

      &::after {
        width: 2px;
        height: 20px;
        right: 44px;

        @include g.mq {
          height: 10px;
          right: 24px;
        }
      }

      &.active {
        &::after {
          transform: translateY(-50%) rotate(90deg);
        }
      }

      p {
        position: relative;
        padding-left: 45px;

        @include g.mq {
          padding-left: 30px;
        }

        &::before {
          position: absolute;
          content: "?";
          display: block;
          background-color: g.$ac_color;
          color: #fff;
          font-weight: 700;
          width: 30px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          border-radius: 50%;
          left: 0;
          top: 15px;
          transform: translatey(-50%);

          @include g.mq {
            width: 20px;
            height: 20px;
            line-height: 20px;
            font-size: 10px;
            top: 10px;
          }
        }
      }
    }

    dd {
      padding: 20px 15px 20px 30px;
      display: none;
      font-size: 18px;

      @include g.mq {
        padding: 15px 15px 15px 20px;
        font-size: 15px;
      }

      p {
        position: relative;
        padding-left: 45px;

        @include g.mq {
          padding-left: 30px;
        }

        &::before {
          position: absolute;
          content: "A";
          display: block;
          border: 2px solid g.$ac_color;
          color: g.$ac_color;
          font-weight: 700;
          width: 30px;
          height: 30px;
          line-height: 26px;
          text-align: center;
          border-radius: 50%;
          left: 0;
          top: 10px;
          transform: translatey(-50%);

          @include g.mq {
            width: 20px;
            height: 20px;
            line-height: 16px;
            font-size: 10px;
            top: 10px;
          }
        }
      }

      a {
        color: g.$ac_color;
      }
    }
  }
  .link {
    margin-top: 60px;
    text-align: center;

    @include g.mq {
      margin-top: 40px;
    }
  }
}

.service_banner {
  margin-top: 100px;

  @include g.mq {
    margin-top: 40px;
  }
}

.service_support {
  margin: 60px 0 0;

  @include g.mq {
    margin: 0;
    background-color: #ecf4f7;
  }

  .box {
    background-color: #ecf4f7;
    border-radius: 100px 0 100px 0;
    padding: 60px 50px;

    @include g.mq {
      background-color: transparent;
      border-radius: 0;
      padding: 40px 0 0;
    }
    .wrap {
      margin-bottom: 30px;
      width: 100%;
      @include g.flexbox;
      @include g.flex-justyfy-content(space-between);
      @include g.flex-flow(row-reverse wrap-reverse);

      @include g.mq(lg) {
        @include g.flex-flow(row wrap);
      }
      @include g.mq {
        margin-bottom: 20px;
      }
      .text {
        width: 48%;

        @include g.mq(lg) {
          width: 100%;
        }
        .no {
          color: #00a5e3;
          margin-bottom: 10px;
          font-family: g.$base_font;
          font-weight: bold;

          @include g.mq {
            font-size: 16px;
          }
        }
        h3 {
          font-size: 32px;
          margin-bottom: 20px;

          @include g.mq {
            font-size: 24px;
            margin-bottom: 10px;
          }
        }
        p {
          font-size: 18px;
          font-weight: 400;

          @include g.mq {
            font-size: 16px;
          }
        }
      }
      .photo {
        width: 50%;

        @include g.mq(lg) {
          width: 100%;
          margin-bottom: 15px;
        }
        @include g.mq {
          width: calc(100% + 20px);
          margin-left: -20px;
          margin-bottom: 10px;
        }
        img {
          @include g.mq {
            border-radius: 0 50px 0 0;
          }
        }
      }
    }
    ul {
      width: 100%;
      @include g.flexbox;
      @include g.flex-justyfy-content(space-between);
      @include g.flex-flow(wrap);
      li {
        width: calc((100% - 60px) / 2);
        background-color: #fff;
        padding: 65px 80px 45px;
        border-radius: 50px 0 50px 0;
        position: relative;

        @include g.mq(lg) {
          width: 60%;
          margin: 0 auto 20px;
          padding: 60px 30px 50px;
        }
        @include g.mq {
          width: 100%;
          padding: 60px 25px 50px;
        }

        &::before {
          content: "";
          position: absolute;
          display: block;
          border-top: 98px solid #004898;
          border-left: 98px solid transparent;
          top: -2px;
          right: -2px;

          @include g.mq {
            border-top: 80px solid #004898;
            border-left: 80px solid transparent;
          }
        }
        .no {
          position: absolute;
          top: 10px;
          right: 0;
          color: #fff;
          font-size: 15px;
          text-align: center;
          width: 68px;
          line-height: 1.1;
          @include g.mq {
            top: 5px;
            width: 50px;
          }
          span {
            font-size: 20px;
          }
        }
        h4 {
          text-align: center;
          font-size: 20px;
          margin-bottom: 30px;
          @include g.mq {
            margin-bottom: 10px;
          }
        }
        p {
          font-size: 16px;
          font-weight: 400;
          span {
            display: block;
            background-color: #eee;
            border-radius: 6px;
            padding: 10px 5px;
            margin-bottom: 10px;
            font-size: 14px;
          }
        }
      }
    }
    .col3 {
      li {
        width: calc((100% - 40px) / 3);
        padding: 65px 30px 45px;
        @include g.mq(lg) {
          width: 60%;
          margin: 0 auto 20px;
          padding: 60px 30px 50px;
        }
        @include g.mq {
          width: 100%;
          padding: 60px 25px 50px;
        }
      }
    }
  }
  &:last-of-type {
    margin-bottom: 150px;
    padding-bottom: 50px;
    @include g.mq {
      margin-bottom: 80px;
      padding-bottom: 20px;
    }
  }
}
#support02 {
  .box {
    .wrap {
      .photo {
        @include g.mq {
          margin-right: -20px;
          margin-left: 0;
        }
        img {
          @include g.mq {
            border-radius: 50px 0 0 0;
          }
        }
      }
    }
  }
}
