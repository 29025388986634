@charset "utf-8";

@use "global" as g;

.fix_bnr {
  margin-bottom: 20px;
  // position: fixed;
  // bottom: 80px;
  // right: 20px;
  // z-index: 50;
  // text-align: right;
  // width: 31%;

  img {
    width: 100%;
  }

  @include g.mq {
    // right: auto;
    // left: 50%;
    // bottom: 20px;
    // transform: translateX(-50%);
  }

  &.active {
    display: none;
  }

  .bnr {
    position: relative;
    // margin-right: 12px;
    min-width: 270px;

    @include g.mq {
      display: inline-block;
      // margin-right: 0px;
    }
  }

  // .close {
  //   position: absolute;
  //   top: -2px;
  //   right: -12px;
  //   background-color: g.$base_color;
  //   color: #fff;
  //   border-radius: 50%;
  //   width: 27px;
  //   height: 27px;
  //   line-height: 27px;
  //   text-align: center;
  //   cursor: pointer;
  //   z-index: 5;
  // }
}

.top_fv {
  // margin-top: 30px;
  position: relative;

  @include g.mq {
    // margin-top: 40px;
  }

  .fv_slider {
    width: 90%;
    margin-left: auto;

    @include g.mq {
      width: calc(100% - 20px);
    }

    .slide {
      background: center center no-repeat;
      background-size: cover;
      border-radius: 100px 0 0 0;
      height: 620px;
      overflow: hidden;

      @include g.mq {
        height: 520px;
      }

      &.slide01 {
        background-image: url(../img/top/fv_slide01.png);
      }

      &.slide02 {
        background-image: url(../img/top/fv_slide02.png);
      }

      &.slide03 {
        background-image: url(../img/top/fv_slide01.png);
      }
    }
  }

  .inner {
    position: absolute;
    bottom: 40px;
    left: 5%;

    @include g.mq {
      bottom: -33px;
      left: 10px;
    }

    h1 {
      color: #fff;
      font-size: 62px;
      text-shadow: 0 0 8px #212121;

      @include g.mq(lg) {
        font-size: 42px;
        line-height: 1.2;
      }

      .b_box {
        background-color: g.$ac_color;
        text-shadow: none;
        border-radius: 50px 0 50px 0;
        padding: 10px 38px;

        @include g.mq {
          display: inline-block;
          border-radius: 40px 0 40px 0;
          padding: 5px 30px;
          margin-bottom: 5px;
          font-size: 40px;
        }
      }

      .sp_align {
        @include g.mq {
          vertical-align: bottom;
        }
      }
    }

    .badge {
      position: relative;

      @include g.mq {
        margin-top: 10px;
      }

      ul {
        @include g.flexbox;
      }

      li {
        @include g.mq {
          max-width: 91px;
        }

        &:nth-child(n + 2) {
          margin-left: 35px;

          @include g.mq {
            margin-left: 10px;
          }
        }
      }
    }

    .note {
      margin-top: 10px;
      max-width: 460px;
      text-shadow: 0 0 4px #fff;
      font-size: 13px;
      font-weight: 700;
      text-align: right;

      @include g.mq {
        max-width: 293px;
        text-shadow: none;
        color: #fff;
        font-size: 10px;
      }
    }

    .link {
      margin-top: 20px;

      @include g.mq {
        margin-top: 80px;
        margin-left: 15px;
      }
    }
  }

  .youtube {
    a {
      display: block;
      width: 100%;
      height: 100%;

      > div {
        pointer-events: none;

        @include g.mq(sm) {
          width: 100%;
          // aspect-ratio: 353 / 628;
        }
      }

      .iframe_wrap {
        width: 100%;
        height: calc(100vh - 70px);
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        @media (max-aspect-ratio: 14.8/9) {
          width: calc(100vw);
          height: calc(100vh - 60px);
          // height: calc(55.65vw);
        }

        @include g.mq {
          // @include g.flex-align-items(flex-start);
        }

        iframe {
          width: 100%;
          height: 120%;
          // width: 100%;
          // height: calc(100vh - 70px);
          // box-sizing: border-box;

          @include g.mq {
            height: 130%;
          }

          // @media (max-aspect-ratio: 14.8/9) {
          //   width: calc(100vw);
          //   // height: calc(55.65vw);
          // }
        }
      }

      #pause,
      #play {
        display: none;
      }
    }
  }

  .text {
    .top,
    .bot {
      margin: 0 auto;
      width: 100%;
      left: 0;
      position: absolute;

      @include g.mq {
        max-width: 464px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .top {
      top: 30px;

      @include g.mq(lg) {
        top: 10vw;
      }

      @include g.mq {
        top: 30px;
      }
    }

    .bot {
      bottom: 20px;
    }

    .text01 {
      font-size: 62px;
      color: #fff;
      text-shadow: 0 0 8px g.$base_color;

      @include g.mq(lg) {
        font-size: 40px;
      }

      @include g.mq {
        font-size: 28px;
        max-width: 395px;
        margin-left: 5px;
      }

      @include g.mq(sm) {
        font-size: 25px;
      }

      @include g.mq(sm-s) {
        font-size: 22px;
      }

      span {
        background-color: g.$ac_color;
        display: inline-block;
        border-radius: 50px 0 50px 0;
        text-shadow: none;
        padding: 0 35px;

        @include g.mq {
          border-radius: 25px 0 25px 0;
          padding: 0 15px;
        }

        @include g.mq(sm) {
          border-radius: 15px 0 15px 0;
        }
      }
    }

    .text02 {
      @include g.mq {
        max-width: 230px;
      }
    }

    .text03 {
      @include g.mq {
        // max-width: 239px;
      }
    }
  }

  // セミナーFV
  .seminar_top_fv {
    height: calc(100vh - 70px);
    // max-height: 873px;
    width: 100%;
    // overflow: hidden;
    position: relative;

    @include g.mq(lg-x) {
      max-height: initial;
      max-height: auto;
      height: auto;
    }
    a {
      display: block;
      width: 100%;
      height: 100%;
      &:hover {
        opacity: 1;
        .seminar_top_fv__bnr {
          background-color: #212121;
          transition: all 0.5s ease;
        }
        .seminar_top_fv__txt {
          // color: #fff;
          transition: all 0.5s ease;
        }
        img {
          opacity: 1;
        }
      }
      .icon-com {
        position: absolute;
        top: 0;
        left: 0;
        width: 23%;
        z-index: 3;
        @include g.mq(lg-x) {
          display: none;
        }
      }
    }
    &__block {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100vh - 185px);
      text-align: center;
      max-height: 862px;
      @include g.mq(lg-x) {
        display: none;
      }
      &__sp {
        display: none;
        @include g.mq(lg-x) {
          display: block;
        }
      }
    }
    &__imglist {
      display: flex;
      justify-content: center;
      // background-image: url(../img/top/fv-seminer01.jpg);
      // background-repeat: no-repeat;
      // background-position: bottom center;
      // background-size: cover;
      background-color: #0d6ecf;
      height: 100%;
    }
    &__img {
      height: 100%;
      img {
        width: auto;
        height: 100%;
        // max-height: 700px;
        // min-height: 500px;
      }
    }
    &__bnr {
      bottom: 0;
      padding: 25px 0;
      background-color: #f26259;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      transition: all 0.5s ease;
    }
    &__yl {
      padding: 10px 70px;
      font-size: 30px;
      background-color: #212121;
      border-radius: 42px;
      color: #fff;
    }
    &__txt {
      margin: 0 1.56vw;
      font-size: 40px;
      color: #fff;
    }
    .icon-tr {
      width: 57px;
    }
  }
}

.top_sec {
  margin-top: 150px;

  @include g.mq {
    margin-top: 80px;
  }
}

.top_reason {
  margin-top: 80px;

  @include g.mq {
    margin-top: 40px;
  }
}

.top_sec02 {
  //文字がはみ出すとき
  margin-top: 240px;

  @include g.mq {
    margin-top: 100px;
  }
}

.bg_ttl {
  font-size: 200px;
  font-weight: 700;
  z-index: 10;
  opacity: 0.1;

  @include g.mq(lg) {
    font-size: 140px;
  }

  @include g.mq {
    font-size: 77px;
  }
}

.top_intro {
  font-size: 18px;

  @include g.mq {
    font-size: 14px;
  }
}

.top_bnr {
  margin-top: 80px;
  @include g.mq {
    margin-top: 40px;
  }
  a {
    display: block;
    width: 80%;
    margin: 0 auto;
    @include g.mq {
      width: 100%;
    }

    & + a {
      margin-top: 20px;

      @include g.mq {
        margin-top: 10px;
      }
    }
  }
}

.top_reason {
  @include g.mq {
    margin-top: 60px;
  }

  .sec_top {
    @include g.flexbox;
    @include g.flex-align-items(center);

    @include g.mq {
      @include g.flex-flow(wrap);
    }
  }

  .sec_ttl {
    max-width: 460px;
    width: 100%;

    @include g.mq {
      max-width: none;
    }
  }

  .top_intro {
    margin-top: 60px;
    width: calc(100% - 460px);

    @include g.mq {
      margin-top: 10px;
      width: 100%;
    }
  }

  .sec_bot {
    margin-top: 90px;
    position: relative;

    @include g.mq {
      margin-top: 30px;
    }

    .bg_ttl {
      position: absolute;
      top: -130px;

      @include g.mq {
        top: -30px;
      }
    }
  }

  .list {
    padding-top: 120px;

    @include g.mq {
      padding-top: 90px;
    }

    ul {
      counter-reset: number 0;
      width: 50%;

      @include g.mq(lg) {
        width: 100%;
      }
    }

    li {
      border-bottom: solid 1px g.$gray;

      &:nth-child(n + 2) {
        margin-top: 30px;
      }

      @include g.mq(lg) {
        border-image: linear-gradient(to right, g.$ac_color 0%, g.$ac_color 30%, g.$gray 30%, g.$gray 100%);
        border-image-slice: 1;
      }

      .top_reason_photo {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        width: calc(50% - 80px);
        height: 1000px;
        text-align: center;
        background: center center no-repeat;
        background-size: cover;
        border-radius: 100px 0 100px 0;
        transition: g.$link_time;
      }

      .top_reason_photo {
        &.active {
          visibility: visible;
          opacity: 1;

          @include g.mq(lg) {
            display: none;
          }
        }
      }

      &:nth-child(1) {
        .top_reason_photo {
          background-image: url(../img/top/reason01.png);
        }
      }

      &:nth-child(2) {
        .top_reason_photo {
          background-image: url(../img/top/reason02.png);
        }
      }

      &:nth-child(3) {
        .top_reason_photo {
          background-image: url(../img/top/reason03.png);
        }
      }

      &:nth-child(4) {
        .top_reason_photo {
          background-image: url(../img/top/reason04.jpg);
        }
      }

      &:nth-child(5) {
        .top_reason_photo {
          background-image: url(../img/top/reason05.jpg);
        }
      }
    }

    a {
      padding-right: 70px;

      @include g.mq {
        padding-right: 50px;
      }
    }

    .u_line {
      &::after {
        bottom: -1px;
      }
    }

    .num {
      font-size: 14px;
      font-weight: 700;
      color: g.$ac_color;

      &::before {
        counter-increment: number 1;
        content: "Reason" counter(number, decimal-leading-zero);
      }
    }

    h3 {
      margin-top: 10px;
      font-size: 32px;

      @include g.mq {
        margin-top: 5px;
        font-size: 28px;
      }
    }

    p {
      padding: 25px 0;
      font-size: 16px;

      @include g.mq {
        padding: 20px 0;
      }
    }
  }
}

.top_service {
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    display: block;
    width: 100%;
    height: 750px;
    background: url(../img/top/service_bg.png) center center no-repeat;
    background-size: cover;
    border-radius: 100px 0 100px 0;
    z-index: -1;

    @include g.mq(xl) {
      height: 100%;
      background-image: none;
      background-color: rgba($color: g.$bg_blue, $alpha: 0.9);
    }
  }

  .bg_ttl {
    position: absolute;
    top: -160px;
    left: 50%;
    transform: translateX(-50%);

    @include g.mq(lg) {
      top: -110px;
    }

    @include g.mq {
      top: -55px;
    }
  }

  .sec_top,
  .sec_bot {
    color: #fff;
  }

  .sec_ttl {
    padding-top: 100px;
    text-align: center;

    @include g.mq {
      padding-top: 60px;
    }

    .tag {
      background-color: #fff;
      color: g.$ac_color;
    }
  }

  .top_intro {
    margin: 25px auto 0;
    max-width: 800px;
  }

  .service_list {
    margin-top: 40px;

    @include g.mq {
      margin-top: 30px;
    }

    ul {
      @include g.flexbox;
      @include g.flex-justyfy-content(space-between);
      @include g.flex-flow(wrap);

      @include g.mq(xl) {
        max-width: 800px;
        margin: 0 auto;
      }
    }

    li {
      width: calc((100% - 30px) / 3);
      max-width: 390px;
      position: relative;

      @include g.mq(xl) {
        width: calc((100% - 15px) / 2);
      }

      @include g.mq {
        width: 100%;
        margin: 0 auto;
      }

      &:nth-child(n + 4) {
        margin-top: 15px;
      }

      &:nth-child(n + 3) {
        @include g.mq(xl) {
          margin-top: 15px;
        }
      }

      &:nth-child(n + 2) {
        @include g.mq {
          margin-top: 20px;
        }
      }

      a {
        &:hover {
          opacity: 0.8;

          img {
            opacity: 1;
          }
        }
      }

      .point {
        position: absolute;
        top: 0;
        left: 0;
        background-color: g.$base_color;
        border-radius: 0 0 20px 0;
        padding: 5px 30px 5px 10px;
      }

      h3 {
        position: absolute;
        width: 100%;
        text-align: center;
        top: 50%;
        left: 50%;
        font-size: 32px;
        text-shadow: 0 0 6px g.$base_color;
        transform: translateX(-50%) translateY(-50%);

        @include g.mq {
          font-size: 28px;
        }
      }
    }

    .link {
      margin-top: 60px;
      text-align: center;

      @include g.mq(xl) {
        margin-top: 40px;
        padding-bottom: 40px;
      }
    }
  }
}

.top_solution {
  position: relative;

  .bg_ttl {
    position: absolute;
    top: -35px;
    right: 0;

    @include g.mq {
      top: 180px;
      right: auto;
      left: 20px;
      z-index: -1;
    }
  }

  .top_intro {
    margin-top: 40px;

    @include g.mq {
      margin-top: 20px;
    }
  }

  .sec_bot {
    position: relative;
  }

  .solution_slider {
    margin-right: calc(50% - 50vw);
    padding-top: 40px;
    padding-bottom: 110px;
    overflow: hidden;
    counter-reset: number 0;

    @include g.mq {
      padding-top: 90px;
      padding-bottom: 0px;
    }

    .slick-list {
      overflow: visible;
      padding: 0 calc(100% - 1100px) 0 0 !important;

      @include g.mq(xl) {
        padding: 0 10% 0 0 !important;
      }
    }

    .slide {
      margin-right: 30px;
      max-width: 540px;
      padding: 0 10px;

      @include g.mq {
        margin-right: 10px;
      }
    }

    .slick-prev,
    .slick-next {
      top: auto;
      bottom: 0;
      width: 50px;
      height: 50px;

      @include g.mq {
        top: 50px;
        bottom: auto;
        width: 40px;
        height: 40px;
      }

      &::before {
        display: block;
        background: g.$gray;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        font-family: "font awesome 6 free";
        font-weight: 900;
        font-size: 20px;
        text-align: center;
        line-height: 50px;
        color: #fff;
        filter: none;
        transition: g.$link_time;

        @include g.mq {
          width: 40px;
          height: 40px;
          line-height: 40px;
        }
      }

      &:hover {
        &::before {
          background-color: g.$base_color;
        }
      }
    }

    .slick-prev {
      left: 75%;

      @include g.mq(xl) {
        left: auto;
        right: 110px;
      }

      @include g.mq {
        right: 70px;
      }

      &::before {
        content: "\f060";
      }
    }

    .slick-next {
      left: calc(75% + 60px);

      @include g.mq(xl) {
        left: auto;
        right: 50px;
      }

      @include g.mq {
        right: 20px;
      }

      &::before {
        content: "\f061";
      }
    }

    .num {
      margin-top: 10px;
      font-size: 14px;
      font-weight: 700;
      color: g.$gray;

      @include g.mq {
        margin-top: 5px;
      }

      &::before {
        counter-increment: number 1;
        //content: "Achievements" counter(number, decimal-leading-zero);
        content: "Achievements";
      }
    }

    dl {
      margin-top: 10px;
      @include g.flexbox;
      @include g.flex-flow(wrap);

      @include g.mq {
        margin-top: 5px;
      }
    }

    dt,
    dd {
      font-size: 18px;

      @include g.mq {
        font-size: 16px;
      }
    }

    dt {
      color: g.$btn_color;
      width: 105px;
      font-weight: 700;

      @include g.mq {
        width: 95px;
      }
    }

    dd {
      width: calc(100% - 105px);
    }
  }

  .link {
    position: absolute;
    right: 0;
    top: -100px;
    z-index: 20;

    @include g.mq(lg) {
      position: relative;
      top: 0px;
      margin-top: 00px;
      text-align: right;
    }

    @include g.mq() {
      margin-top: 60px;
    }
  }
}

.top_problem {
  .problem_box {
    @include g.flexbox;
    @include g.flex-flow(wrap);
    background-color: g.$li-gray;
    border-radius: 100px 0 100px 0;
    padding: 100px 80px;
    position: relative;

    @include g.mq {
      padding: 60px 20px;
    }
  }

  .bg_ttl {
    position: absolute;
    top: -150px;
    left: 0;
    color: #d2e0e5;
    opacity: 1;

    @include g.mq {
      top: -55px;
    }
  }

  .sec_top,
  .sec_bot {
    width: 50%;

    @include g.mq(lg) {
      width: 100%;
    }
  }

  .sec_top {
    padding-right: 40px;

    @include g.mq {
      padding-right: 0;
    }
  }

  .top_intro {
    margin-top: 30px;

    @include g.mq {
      margin-top: 20px;
    }
  }

  .link {
    margin-top: 20px;

    @include g.mq(lg) {
      margin-top: 30px;
    }

    &.pc {
      @include g.mq(lg) {
        display: none;
      }
    }

    &.sp {
      display: none;

      @include g.mq(lg) {
        display: block;
      }
    }
  }

  .problem_list {
    position: relative;
    z-index: 20;

    @include g.mq(lg) {
      margin-top: 20px;
    }

    li {
      &:nth-child(n + 2) {
        margin-top: 30px;

        @include g.mq {
          margin-top: 20px;
        }
      }

      &:nth-child(n + 4) {
        @include g.mq {
          display: none;
        }
      }
    }

    a {
      display: block;
      background-color: #fff;
      padding: 20px 30px;
      border-radius: 70px;
      position: relative;
      box-shadow: 0 0 10px rgba($color: #010f1f, $alpha: 0.1);

      @include g.mq {
        padding: 15px 20px;
      }

      &:hover {
        opacity: 0.5;
      }

      &::after {
        content: "";
        position: absolute;
        border-bottom: 14px solid transparent;
        border-left: 13px solid #fff;
        bottom: -14px;
        right: 50px;
        filter: drop-shadow(0 0 10px rgba($color: #010f1f, $alpha: 0.1));
        transform: skew(-12deg);

        @include g.mq {
          border-bottom: 10px solid transparent;
          border-left: 7px solid #fff;
          bottom: -10px;
        }
      }

      p {
        position: relative;
        font-size: 20px;
        padding-left: 45px;

        @include g.mq {
          font-size: 15px;
          padding-left: 30px;
        }

        &::before {
          content: "?";
          display: block;
          background-color: g.$ac_color;
          color: #fff;
          font-size: 16px;
          width: 30px;
          height: 30px;
          text-align: center;
          line-height: 30px;
          border-radius: 50%;
          position: absolute;
          top: 0;
          left: 0;

          @include g.mq {
            width: 20px;
            height: 20px;
            line-height: 20px;
            font-size: 10px;
          }
        }
      }

      span {
        color: g.$ac_color;
      }
    }
  }
}

.top_strength {
  background: url(../img/top/strength_bg.png) center center no-repeat;
  background-size: cover;
  border-radius: 0 100px 0 0;
  padding: 100px 0 60px;
  position: relative;

  @include g.mq {
    padding: 60px 0 40px;
  }

  .bg_ttl {
    position: absolute;
    top: -135px;
    left: 50%;
    transform: translateX(-50%);

    @include g.mq(lg) {
      top: -95px;
    }

    @include g.mq {
      top: -65px;
    }
  }

  .sec_top {
    color: #fff;
  }

  .sec_ttl {
    text-align: center;
  }

  .tag {
    background-color: #fff;
    color: g.$ac_color;
  }

  h2 {
    text-shadow: 0 0 10px g.$btn_color;

    span {
      color: #fff;
      background: linear-gradient(transparent 70%, g.$yellow 70%, g.$yellow 90%, transparent 90%);

      @include g.mq {
        background: linear-gradient(transparent 65%, g.$yellow 65%);
      }
    }
  }

  .top_intro {
    margin: 40px auto 0;
    max-width: 800px;

    @include g.mq {
      margin-top: 20px;
    }
  }

  .strength_list {
    margin-top: 40px;

    @include g.mq {
      margin-top: 35px;
    }

    ul {
      @include g.flexbox;
      @include g.flex-flow(wrap);
      @include g.flex-justyfy-content(space-between);
      counter-reset: number 0;
    }

    li {
      width: calc((100% - 40px) / 2);
      background-color: #fff;
      border-radius: 50px 0 50px 0;
      position: relative;
      padding: 40px 70px 5px;
      min-height: 314px;

      @include g.mq {
        width: 100%;
        padding: 40px 20px 20px;
        min-height: auto;
      }

      &:nth-child(n + 3) {
        margin-top: 40px;
      }

      &:nth-child(n + 2) {
        @include g.mq {
          margin-top: 20px;
        }
      }

      &:last-child {
        padding-top: 20px;
      }

      &::before,
      &::after {
        content: "";
        display: block;
        position: absolute;
        color: #fff;
        top: 0;
        right: 0;
      }

      &::after {
        counter-increment: number 1;
        content: counter(number, decimal-leading-zero) "";
        font-size: 30px;
        font-weight: 700;
        top: 5px;
        right: 5px;
      }

      &::before {
        border-top: 80px solid g.$btn_color;
        border-left: 80px solid transparent;
      }

      .ttl {
        text-align: center;

        p {
          font-size: 24px;
          font-weight: 700;

          @include g.mq {
            font-size: 18px;
          }
        }

        h3 {
          color: g.$btn_color;
          font-size: 80px;
          line-height: 1;
          @include g.flexbox;
          @include g.flex-align-items(flex-end);
          @include g.flex-justyfy-content(center);
          @include g.flex-flow(wrap);

          @include g.mq {
            font-size: 60px;
          }

          .fs50 {
            font-size: 50px;
            font-weight: 900;
            line-height: 1.2;

            @include g.mq {
              font-size: 30px;
            }
          }

          .fs26 {
            font-size: 26px;
            font-weight: 900;
            line-height: 1.2;
            padding-bottom: 5px;

            @include g.mq {
              font-size: 16px;
            }
          }
        }
      }

      .desc {
        margin-top: 20px;
        font-size: 16px;
      }
    }
  }

  .link {
    margin-top: 60px;
    text-align: center;

    @include g.mq {
      margin-top: 40px;
    }
  }
}

.top_support {
  .sec_ttl {
    text-align: center;
  }

  .top_intro {
    max-width: 800px;
    margin: 40px auto 0;

    @include g.mq {
      margin-top: 20px;
    }
  }

  .sec_bot {
    position: relative;
  }

  .bg_ttl {
    position: absolute;
    bottom: -30px;
    right: 0;

    @include g.mq {
      bottom: 185px;
    }
  }

  .support_box {
    margin-top: 65px;
    @include g.flexbox;
    @include g.flex-align-items(center);
    @include g.flex-justyfy-content(center);
    @include g.flex-flow(wrap);

    @include g.mq {
      margin-top: 20px;
    }

    .photo,
    .text {
      @include g.mq {
        width: 100%;
      }
    }

    .photo {
      max-width: calc(100% - 290px);

      @include g.mq {
        max-width: none;
      }
    }

    .text {
      width: 290px;

      @include g.mq {
        margin-top: 65px;
      }

      h3 {
        font-size: 24px;
        text-align: center;

        span {
          color: g.$ac_color;
          background: linear-gradient(transparent 70%, g.$yellow 70%);
        }
      }

      .link {
        margin-top: 20px;
        position: relative;
        z-index: 10;
      }
    }
  }

  .support_bg {
    margin-top: 80px;
    height: 500px;
    width: 100%;
    border-radius: 50px 0 50px 0;
    background: url(../img/top/support02.png) top center no-repeat;
    background-size: cover;

    @include g.mq(xxxl) {
      height: 380px;
    }

    @include g.mq {
      height: 250px;
      background-image: url(../img/top/support02_sp.png);
    }
  }
}

.top_dispatch {
  .sec_top {
    .box {
      @include g.flexbox;
      @include g.flex-align-items(center);

      @include g.mq {
        @include g.flex-flow(wrap);
      }

      h2 {
        width: 460px;
        @include g.mq {
          width: 100%;
        }
      }
      .top_intro {
        width: calc(100% - 460px);
        @include g.mq {
          width: 100%;
          margin-top: 20px;
        }
      }
    }
  }

  .sec_bot {
    padding-top: 150px;
    position: relative;

    @include g.mq {
      padding-top: 0;
    }

    .bg_ttl {
      position: absolute;
      top: -60px;

      @include g.mq {
        position: relative;
        top: 0;
      }
    }
    .dispatch_box {
      position: relative;
      padding: 70px 0 90px;

      @include g.mq {
        padding: 0;
      }

      .left {
        z-index: 1;
        position: relative;
        @include g.mq {
          margin: 0 calc(50% - 50vw);
          width: 100vw;
        }
      }
      .right {
        position: absolute;
        top: 0;
        right: 0;
        overflow: hidden;
        border-radius: 100px 0 100px 0;

        @include g.mq {
          position: relative;
          top: -20px;
          border-radius: 50px 0 50px 0;
        }
      }

      .link {
        position: absolute;
        bottom: 0;
        left: 0;

        @include g.mq {
          position: relative;
          margin-top: 20px;
          text-align: center;
        }
      }
    }
  }
}

.top_Company {
  padding-top: 100px;
  @include g.mq {
    padding-top: 50px;
  }

  .sec_top {
    background-color: g.$li-blue;
    border-radius: 100px 0 100px 0;
    padding: 110px 50px 80px;
    text-align: center;
    position: relative;

    @include g.mq {
      border-radius: 0 100px 0 0;
      margin: 0 calc(50% - 50vw);
      padding: 60px 20px 90px;
    }

    .bg_ttl {
      position: absolute;
      top: -170px;
      left: 50%;
      transform: translateX(-50%);

      @include g.mq {
        top: -60px;
      }
    }

    .top_intro {
      margin: 20px auto 0;
      max-width: 800px;
      text-align: left;
    }

    .link {
      margin-top: 60px;

      @include g.mq {
        margin-top: 30px;
      }
    }
  }

  .sec_bot {
    position: relative;
    z-index: 1;
    margin-top: -30px;
    .compliance_box {
      @include g.flexbox;
      @include g.flex-align-items(center);
      @include g.flex-flow(wrap);

      .left {
        width: calc(50% - 40px);
        margin-left: 40px;
        border-radius: 100px 0 100px 0;
        overflow: hidden;

        @include g.mq(lg) {
          width: 100%;
          max-width: 560px;
          margin: 0 auto;
        }
      }

      .right {
        width: 50%;
        padding-left: 70px;
        padding-right: 50px;

        @include g.mq(lg) {
          width: 100%;
          margin-top: 25px;
          padding-left: 0px;
          padding-right: 0px;
        }
      }
      .top_intro {
        margin-top: 20px;
      }

      .link {
        margin-top: 60px;

        @include g.mq {
          margin-top: 30px;
        }
      }
    }
  }
}

.top_webinar {
  .webinar_post {
    .top_intro {
      margin-top: 40px;
    }
    .blog_slider {
      margin-top: 40px;
    }
    .blog_slider {
      @include g.flexbox;
      // @include g.flex-justyfy-content(space-between);
      margin-top: 40px;

      @include g.mq(lg) {
        margin-top: 20px;
      }

      .slick-list {
        @include g.mq(lg) {
          padding-right: 10% !important;
        }
      }

      .slide {
        width: calc((100% - 30px) / 3);
        &:nth-child(2) {
          margin: 0 15px;
        }

        @include g.mq(lg) {
          width: calc(100% - 10px) !important;
          margin-right: 10px;
        }

        > a {
          &:hover {
            opacity: 0.8;
          }
        }
      }

      .tag {
        @include g.flexbox;

        .name {
          margin-top: 10px;
          background-color: g.$btn_color;
          color: #fff;
          font-size: 12px;
          font-weight: 700;
          padding: 3px 10px;
          border-radius: 10px 0 10px 0;

          @include g.mq(lg) {
            font-size: 10px;
          }
        }
      }

      h3 {
        margin-top: 10px;
        font-size: 18px;
      }

      .date {
        margin-top: 10px;
        font-weight: 700;
        color: g.$gray;

        @include g.mq(lg) {
          margin-top: 0px;
        }
      }

      .photo {
        img {
          @include g.mq(lg) {
            width: 100%;
          }
        }
      }
    }
  }
  .webinar_box {
    width: calc(1000 / 1200 * 100%);
    margin: 0 auto;
    background: url(../img/top/webinar_bg.jpg) center center no-repeat;
    background-size: cover;
    padding: 40px;
    position: relative;
    text-align: center;
    @include g.mq {
      width: 100%;
      padding: 40px 12px;
      background: url(../img/top/webinar_bg_sp.jpg) center center no-repeat;
    }
    .sec_ttl {
      margin-bottom: 17px;
      .tag {
        background-color: #fff;
        color: #00a5e3;
      }
      h2 {
        span {
          color: #fff;
        }
      }
    }
    .link {
      margin-top: 17px;
    }
    .ico-left {
      position: absolute;
      left: -40px;
      bottom: -45px;
      width: 26.4%;
      @include g.mq {
        left: -10px;
        width: 122px;
      }
    }
    .ico-right {
      position: absolute;
      right: 2%;
      top: 20px;
      width: 11.7%;
      @include g.mq {
        right: 10px;
        top: 10px;
        width: 73px;
      }
    }
  }
  .bg_ttl {
    text-align: center;
    margin-top: -130px;
    position: relative;
    z-index: -1;
    @include g.mq(lg) {
      margin-top: -100px;
    }
    @include g.mq {
      margin-top: -50px;
    }
  }
}

.top_download {
  .download_box {
    background-color: g.$ac_color;
    border-radius: 100px 0 0 0;
    margin-right: calc(50% - 50vw);
    margin-left: 95px;

    @include g.mq(lg) {
      margin: 0 calc(50% - 50vw);
      background: none;
    }

    //@include g.mq(lg) {
    //  background: none;
    //}
  }

  .download_inner {
    padding: 100px 40px 60px 80px;
    max-width: 1105px;
    @include g.flexbox;
    @include g.flex-flow(wrap);
    position: relative;

    //@include g.mq(lg) {
    //  padding-left: 50px;
    //  padding-right: 50px;
    //}

    @include g.mq(lg) {
      padding: 0;
    }
  }

  .bg_ttl {
    position: absolute;
    bottom: -155px;
    right: 0;

    @include g.mq(lg) {
      bottom: -10px;
      right: auto;
      left: 50%;
      transform: translateX(-50%);
    }

    @include g.mq {
      bottom: 48px;
    }
  }

  .sec_top {
    width: 315px;
    padding-right: 60px;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;

    @include g.mq(lg) {
      padding: 0 20px;
      width: 100%;
      -ms-writing-mode: inherit;
      writing-mode: inherit;
    }
  }

  .tag {
    background-color: #fff;
    color: g.$ac_color;
    padding: 25px 2px;
    border-radius: 0 20px 0 20px;

    @include g.mq(lg) {
      background-color: g.$ac_color;
      color: #fff;
      padding: 2px 20px;
    }
  }

  h2 {
    padding: 15px 15px 0 0;
    line-height: 1.2;

    @include g.mq(lg) {
      padding: 0;
      line-height: 1.5;
    }

    span {
      color: #fff;

      @include g.mq(lg) {
        color: g.$ac_color;
      }
    }
  }

  .top_intro {
    padding-right: 5px;

    @include g.mq(lg) {
      padding: 20px 0 0;
    }
  }

  .sec_bot {
    width: calc(100% - 315px);

    @include g.mq(lg) {
      width: 100%;
    }
  }

  .download_list {
    @include g.mq(lg) {
      background-color: g.$ac_color;
      margin: 20px calc(50% - 50vw) 0;
      width: 100vw;
      padding: 60px 0 20px;
      border-radius: 100px 0 0 0;
    }

    .download_slider {
      @include g.flexbox;
      @include g.flex-justyfy-content(space-between);
      @include g.flex-flow(wrap);

      .slick-track {
        @include g.flexbox;
      }

      .slick-slide {
        height: auto !important;

        > div {
          height: 100%;
        }
      }
    }

    .slide {
      width: calc(calc(100% - 30px) / 2);

      @include g.mq(lg) {
        width: calc(100% - 10px) !important;
        height: 100%;
        margin-right: 10px;
      }

      &:nth-child(n + 3) {
        margin-top: 30px;
      }

      &:nth-child(n + 2) {
        @include g.mq(lg) {
          margin-top: 0;
        }
      }

      .photo {
        text-align: center;
        img {
          width: auto;
          height: 198px;
        }
      }
    }

    .slick-dots {
      z-index: 15;
      bottom: -30px;

      li {
        margin: 0 5px;

        button {
          &::before {
            opacity: 1;
            color: transparent;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: g.$base_color;
          }
        }

        &.slick-active {
          button {
            &::before {
              background-color: #fff;
            }
          }
        }
      }
    }

    a {
      display: block;
      background-color: #fff;
      padding: 20px;
      height: 100%;

      &:hover {
        opacity: 0.8;
      }
    }

    h3 {
      padding-top: 10px;
      font-size: 20px;
    }

    p {
      margin-top: 5px;
    }

    .list_link {
      display: inline-block;
      color: g.$ac_color;
      position: relative;
      padding-right: 28px;

      &::after {
        content: "";
        display: inline-block;
        width: 22px;
        height: 21px;
        background: url(../img/top/icon_download.png) center center no-repeat;
        background-size: cover;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
    }
  }

  .link {
    margin-top: 60px;
    text-align: right;
    z-index: 20;
    position: relative;

    @include g.mq(lg) {
      margin-top: 40px;
      text-align: center;
    }
  }
}

.top_blog {
  position: relative;

  .bg_ttl {
    position: absolute;
    top: 0;
    right: 0;
  }

  .top_intro {
    margin-top: 40px;

    @include g.mq(lg) {
      margin-top: 20px;
    }
  }

  .blog_slider {
    @include g.flexbox;
    // @include g.flex-justyfy-content(space-between);
    margin-top: 40px;

    @include g.mq(lg) {
      margin-top: 20px;
    }

    .slick-list {
      @include g.mq(lg) {
        padding-right: 10% !important;
      }
    }

    .slide {
      width: calc((100% - 30px) / 3);
      &:nth-child(2) {
        margin: 0 15px;
      }

      @include g.mq(lg) {
        width: calc(100% - 10px) !important;
        margin-right: 10px;
      }

      > a {
        &:hover {
          opacity: 0.8;
        }
      }
    }

    .tag {
      @include g.flexbox;

      .name {
        margin-top: 10px;
        background-color: g.$btn_color;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        padding: 3px 10px;
        border-radius: 10px 0 10px 0;

        @include g.mq(lg) {
          font-size: 10px;
        }
      }
    }

    h3 {
      margin-top: 10px;
      font-size: 18px;
    }

    .date {
      margin-top: 10px;
      font-weight: 700;
      color: g.$gray;

      @include g.mq(lg) {
        margin-top: 0px;
      }
    }

    .photo {
      img {
        @include g.mq(lg) {
          width: 100%;
        }
      }
    }
  }

  .link {
    margin-top: 60px;
    text-align: center;

    @include g.mq(lg) {
      margin-top: 40px;
    }
  }
}

.top_news {
  .news_box {
    background-color: g.$li-blue;
    border-radius: 0 100px 0 0;
    margin-left: calc(50% - 50vw);
    padding: 100px 0;

    @include g.mq {
      margin: 0 calc(50% - 50vw);
      width: 100vw;
      padding: 50px 0 40px;
    }
  }

  .news_inner {
    @include g.flexbox;
    @include g.flex-flow(wrap);
    padding-right: 80px;
    position: relative;

    @include g.mq {
      padding-right: 0;
    }
  }

  .sec_top {
    width: 280px;

    @include g.mq {
      width: 100%;
    }
  }

  .sec_bot {
    width: calc(100% - 280px);

    @include g.mq {
      width: 100%;
      margin-top: 40px;
    }
  }

  .news_list {
    li {
      &:first-child {
        a {
          padding-top: 0;
        }
      }
    }

    a {
      @include g.flexbox;
      border-bottom: 1px solid g.$gray;
      border-image: linear-gradient(to right, g.$ac_color 100px, g.$gray 100px);
      border-image-slice: 1;
      padding: 20px 0;
      font-size: 16px;

      &:hover {
        opacity: 0.8;
      }
    }

    .u_line {
      &::after {
        bottom: -1px;
      }
    }

    .date {
      width: 100px;
      font-weight: 700;
      color: g.$gray;
    }

    .ttl {
      width: calc(100% - 100px);
      font-weight: 400;
    }
  }

  .link {
    position: absolute;
    left: 0;
    top: 165px;

    @include g.mq {
      position: relative;
      left: 0;
      top: 0;
      margin: 40px auto 0;
    }
  }
}

.top_recruit {
  margin-bottom: 150px;

  @include g.mq {
    margin-bottom: 0;
  }

  .page_width {
    @include g.mq {
      padding: 0;
    }
  }

  .recruit_inner {
    @include g.flexbox;

    @include g.mq(lg) {
      @include g.flex-flow(column-reverse wrap);
    }
  }

  .sec_top {
    width: 500px;
    background-color: g.$bg_blue;
    color: #fff;
    border-radius: 100px 0 0 0;
    text-align: center;
    padding: 60px 30px;

    @include g.mq(lg) {
      width: 100%;
      border-radius: 0;
      padding: 40px 20px;
    }
  }

  .ja_ttl {
    color: #fff;
  }

  .catch {
    font-size: 32px;
    margin-top: 30px;
    font-weight: 700;

    @include g.mq {
      font-size: 28px;
      margin-top: 25px;
    }
  }

  .top_intro {
    margin-top: 20px;
    font-size: 15px;
    line-height: 2;
    text-shadow: 0px 0px 4px g.$btn_color;

    @include g.mq {
      margin-top: 15px;
    }
  }

  .link {
    margin-top: 30px;

    .bk_btn {
      transition: g.$link_time;
    }
  }

  .sec_bot {
    width: calc(100% - 500px);

    @include g.mq(lg) {
      width: 100%;
    }
  }

  .photo {
    border-radius: 0 0 100px 0;
    background: url(../img/top/recruit01.png) center center no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    overflow: hidden;

    @include g.mq(lg) {
      background: none;
      border-radius: 100px 0 0 0;
    }

    img {
      display: none;

      @include g.mq(lg) {
        display: block;
        width: 100%;
      }
    }
  }
}
