@charset "utf-8";

@use "global" as g;

.download_cont {
  margin-top: 60px;
  background-color: g.$li-blue02;

  .box {
    padding: 100px 0;
    @include g.flexbox;
    @include g.flex-justyfy-content(space-between);
    @include g.flex-flow(wrap);

    @include g.mq {
      padding: 70px 0 80px;
    }
  }

  .left,
  .right {
    width: calc((100% - 80px) / 2);

    @include g.mq {
      width: 100%;
    }
  }

  .left {
    text-align: center;

    .tag {
      display: inline-block;
      font-size: 14px;
      background-color: #fff;
      color: g.$ac_color;
      border: solid 2px g.$ac_color;
      padding: 2px 20px;
      border-radius: 29px;
    }

    .ttl {
      color: g.$ac_color;
      margin-top: 20px;
      font-size: 32px;

      @include g.mq {
        font-size: 24px;
      }
    }

    .photo {
      margin-top: 40px;

      @include g.mq {
        margin-top: 20px;
        padding: 0 30px;
      }
    }

    .topic {
      margin-top: 30px;
      background-color: #fff;
      border-radius: 50px 0 50px 0;
      padding: 30px 20px;
    }

    .topic_ttl {
      font-size: 24px;
      text-align: center;
      margin-bottom: 15px;

      @include g.mq {
        font-size: 18px;
        margin-bottom: 10px;
      }
    }

    .topic_text {
      text-align: left;
      font-size: 18px;
      @include g.mq {
        font-size: 16px;
      }
    }

    .topic_list {
      margin-top: 20px;
      text-align: left;

      > li {
        position: relative;
        padding-left: 40px;
        font-size: 18px;

        @include g.mq {
          font-size: 16px;
        }

        &::before {
          content: "\f058";
          display: block;
          font-family: "font awesome 6 free";
          font-weight: 900;
          color: g.$ac_color;
          font-size: 30px;
          line-height: 1;
          position: absolute;
          top: -2px;
          left: 0;
        }

        &:nth-child(n + 2) {
          margin-top: 15px;
        }
      }
    }
  }

  .right {
    background-color: #fff;
    border-radius: 25px;
    box-shadow: 0 0 10px rgba($color: g.$blue, $alpha: 0.1);
    padding: 30px 20px;
    text-align: center;

    @include g.mq {
      margin-top: 60px;
    }

    .tag {
      display: inline-block;
      background-color: g.$ac_color;
      color: #fff;
      border-radius: 20px 0 20px 0;
      padding: 3px 20px;
      line-height: 1;
      font-size: 13px;
    }

    .ttl {
      margin-top: 10px;
      font-size: 24px;

      @include g.mq {
        font-size: 18px;
      }
    }

    .text {
      margin-top: 20px;
      text-align: left;
      font-size: 16px;
    }

    .form_block {
      margin-top: 45px;
      text-align: left;
    }

    .form_list {
      > li {
        font-size: 16px;

        &:nth-child(n + 2) {
          margin-top: 10px;
        }
      }
    }

    label {
      font-weight: 700;

      &.required {
        position: relative;
        @include g.flexbox;
        @include g.flex-align-items(center);

        &::after {
          margin-left: 5px;
          display: inline-block;
          content: "必須";
          background-color: g.$form_red;
          color: #fff;
          font-size: 10px;
          font-weight: 700;
          padding: 1px 10px;
          border-radius: 3px;
        }
      }
    }

    .form_select {
      position: relative;

      &::after {
        content: "";
        display: block;
        position: absolute;
        border-top: 10px solid g.$base_color;
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;
        width: 12px;
        height: 10px;
        right: 40px;
        top: 50%;
        transform: translateY(-50%);

        @include g.mq {
          right: 18px;
        }
      }
    }

    input[type="text"],
    input[type="number"],
    input[type="tel"],
    input[type="email"],
    select {
      margin-top: 5px;
      width: 100%;
      border: solid 1px g.$gray;
      background-color: g.$li-blue02;
      border-radius: 5px;
      padding: 15px 10px;

      &::placeholder {
        color: g.$gray04;
      }
    }

    select {
      position: relative;

      &::after {
        content: "";
        display: block;
        position: absolute;
        border-top: 10px solid g.$base_color;
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;
        width: 12px;
        height: 10px;
        right: 40px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .wpcf7-list-item {
      margin: 0;
    }

    .note {
      margin-top: 20px;
      font-size: 16px;
    }

    .policy {
      font-weight: 700;

      a {
        color: g.$btn_color;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .policy_agree {
      margin-top: 10px;

      label {
        padding-left: 5px;
        font-weight: 500;
      }
    }

    .btn {
      text-align: center;
      margin-top: 40px;
      position: relative;

      &::after {
        content: "";
        display: block;
        position: absolute;
        background: url(../img/download/icon_download.png) center center no-repeat;
        background-size: cover;
        width: 22px;
        height: 21px;
        top: 18px;
        left: calc(50% + 70px);
        transform: translateX(-50%);
      }
    }

    .wpcf7-spinner {
      display: none;
    }

    button {
      &::after {
        display: none;
      }

      picture {
        margin-left: 20px;
      }
    }

    .message {
      margin-top: 40px;
      text-align: center;
      color: g.$ac_color;
      font-size: 18px;
    }
  }
}
