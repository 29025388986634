@charset "utf-8";

@use "global" as g;

.solution_prof {
  margin: 35px auto 0;
  max-width: 1000px;

  @include g.mq {
    margin-top: 40px;
  }

  .date {
    color: g.$gray;
    font-size: 20px;
  }

  .box {
    margin-top: 20px;
    @include g.flexbox;
    @include g.flex-flow(wrap);
  }

  .photo,
  .text {
    width: 50%;

    @include g.mq {
      width: 100%;
    }
  }

  .photo {
    padding-right: 20px;

    @include g.mq {
      padding-right: 0;
    }
  }

  .text {
    @include g.mq {
      margin-top: 15px;
    }

    .ttl {
      color: g.$btn_color;
      font-size: 24px;
      font-weight: 700;
      padding-left: 20px;
      padding-bottom: 10px;
      border-bottom: solid 1px g.$gray;
      position: relative;

      @include g.mq {
        font-size: 18px;
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        background-color: g.$ac_color;
        width: 168px;
        height: 1px;
        bottom: -1px;
        left: 0;
      }
    }

    .prof {
      padding-left: 20px;
      @include g.flexbox;
      @include g.flex-flow(wrap);
      font-size: 18px;

      @include g.mq {
        margin-top: 5px;
        font-size: 16px;
      }

      dt,
      dd {
        margin-top: 15px;
      }

      dt {
        width: 150px;
        font-weight: 700;
        padding-left: 18px;
        position: relative;

        @include g.mq {
          width: 125px;
        }

        &::before {
          content: "";
          display: block;
          width: 7px;
          height: 7px;
          border-radius: 50%;
          background-color: g.$btn_color;
          position: absolute;
          top: 7px;
          left: 0;
        }
      }

      dd {
        width: calc(100% - 150px);

        @include g.mq {
          width: calc(100% - 125px);
        }
      }
    }
  }
}

.solution_cont {
  margin-top: 60px;

  .inner {
    background-color: g.$li-blue02;
    border-radius: 100px 0 100px 0;
    padding: 100px;

    @include g.mq {
      border-radius: 50px 0 50px 0;
      padding: 30px 20px;
    }
  }

  .tag {
    display: inline-block;
    background-color: g.$ac_color;
    color: #fff;
    border-radius: 20px 0 20px;
    padding: 3px 30px;
    line-height: 1;
    font-size: 20px;

    @include g.mq {
      font-size: 13px;
    }
  }

  .box {
    margin-top: 40px;

    @include g.mq {
      margin-top: 30px;
    }
  }

  .ttl {
    font-size: 32px;
    font-weight: 700;
    // color: g.$ac_color;

    @include g.mq {
      font-size: 24px;
    }
  }

  .text {
    margin-top: 20px;
    font-size: 16px;

    @include g.mq {
      margin-top: 10px;
    }

    li {
      position: relative;
      padding-left: 1em;

      &::before {
        content: "・";
        position: absolute;
        top: 0;
        left: 0;
      }

      &:nth-child(n + 2) {
        @include g.mq {
          margin-top: 5px;
        }
      }
    }

    .lead-txt {
      color: g.$ac_color;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 10px;

      @include g.mq {
        font-size: 18px;
      }
    }
  }
}

.solution_relate {
  margin: 100px 0 150px;

  @include g.mq {
    margin: 60px 0;
  }
}
